import { Routes } from '@angular/router';
import { UsersComponent } from './users.component';
import { AuthGuard } from '@guards/auth-guard/auth.guard';
import { CreateUserComponent } from './create-user/create-user.component';
import { ViewUsersComponent } from './view-users/view-users.component';

export const usersRoutes: Routes = [
    {
        path: '',
        component: UsersComponent
    },
    {
        path: 'create',
        component: CreateUserComponent
    },
    {
        path: ':id',
        component: ViewUsersComponent
    },
    {
        path: ':id/edit',
        component: CreateUserComponent
    }
];

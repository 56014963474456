import { LoginComponent } from '@pages/auth/login/login.component';
import { ForgotPasswordComponent } from '@pages/auth/forgot-password/forgot-password.component';
import { VerifyAccountComponent } from '@pages/auth/verify-account/verify-account.component';
import { CreatePasswordComponent } from '@pages/auth/create-password/create-password.component';

export const authRoutes = [
    { path: 'login', component: LoginComponent },
    // { path: "register", component: RegisterComponent },
    { path: 'forgotpassword', component: ForgotPasswordComponent },
    { path: 'verifyaccount', component: VerifyAccountComponent },
    { path: 'createpassword', component: CreatePasswordComponent }
];

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange, MatTabChangeEvent, MatTabGroup, MatTable } from '@angular/material';
import { PromotionTypes } from '@data/enums/promotion-types.enum';
import { Interaction } from '@data/interaction.model';
import { LastEvaluatedKey, Response } from '@data/response.model';
import { InteractionsService } from '@services/interactions.service';
import { SpinnerService } from '@services/spinner.service';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { PromotionInteractionsTabs } from './promotion-interactions-tabs.enum';

@Component({
    selector: 'promo-interaction',
    templateUrl: './promotion-interactions.component.html',
    styleUrls: ['./promotion-interactions.component.css']
})
export class PromotionInteractionsComponent implements OnInit, OnDestroy {
    @ViewChild(MatTable)
    public dataSource: Interaction[] = [];
    public dataSourcePromotions: Interaction[] = [];
    public dataSourceOrders: Interaction[] = [];
    public dataSourceBets: Interaction[] = [];
    public dataSourceInstants: Interaction[] = [];

    public promotions: Response<Interaction>;
    public orders: Response<Interaction>;
    public bets: Response<Interaction>;
    public instants: Response<Interaction>;

    public showResolved = true;
    private newInteractionSubscription: Subscription;
    private interactionsSubscription: Subscription;

    public lastEvaluatedKey: LastEvaluatedKey;
    private maxItemsPerPage = 50;

    @ViewChild(MatTabGroup)
    public tabGroup: MatTabGroup;

    public isLoading = true;

    constructor(public interactionsService: InteractionsService, private spinnerService: SpinnerService) {}

    public ngOnInit(): void {
        this.spinnerService.show();
        this.interactionsService.currentTab = PromotionTypes.Promotion;
        this.interactionsService.unseenPromotions = 0;
        this.interactionsSubscription = this.interactionsService.promotionsResponse$
            .pipe(
                switchMap(promotions => {
                    this.promotions = promotions;
                    this.lastEvaluatedKey = this.promotions.LastEvaluatedKey;
                    this.dataSourcePromotions = [...promotions.Items];
                    return this.interactionsService.ordersResponse$;
                }),
                switchMap(orders => {
                    this.orders = orders;
                    this.dataSourceOrders = [...orders.Items];
                    return this.interactionsService.betsResponse$;
                }),
                switchMap(bets => {
                    this.bets = bets;
                    this.dataSourceBets = [...bets.Items];
                    return this.interactionsService.instantsResponse$;
                }),
                tap(instants => {
                    this.instants = instants;
                    this.dataSourceInstants = [...instants.Items];
                    this.isLoading = false;
                    this.spinnerService.hide();
                })
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        if (this.interactionsSubscription) {
            this.interactionsSubscription.unsubscribe();
        }
        if (this.newInteractionSubscription) {
            this.newInteractionSubscription.unsubscribe();
        }
        this.interactionsService.currentTab = undefined;
        if (this.promotions.Items.length > this.maxItemsPerPage) {
            this.promotions.Items.length = this.maxItemsPerPage;
        }
        if (this.orders.Items.length > this.maxItemsPerPage) {
            this.orders.Items.length = this.maxItemsPerPage;
        }
        if (this.bets.Items.length > this.maxItemsPerPage) {
            this.bets.Items.length = this.maxItemsPerPage;
        }
        if (this.instants.Items.length > this.maxItemsPerPage) {
            this.instants.Items.length = this.maxItemsPerPage;
        }
    }

    public toggleResolved(event: MatCheckboxChange): void {
        this.spinnerService.show();
        if (!event.checked) {
            this.dataSourcePromotions = [...this.dataSourcePromotions.filter(x => !x.resolved)];
            this.dataSourceOrders = [...this.dataSourceOrders.filter(x => !x.resolved)];
            this.dataSourceBets = [...this.dataSourceBets.filter(x => !x.resolved)];
            this.dataSourceInstants = [...this.dataSourceInstants.filter(x => !x.resolved)];
        } else {
            this.dataSourcePromotions = [...this.promotions.Items];
            this.dataSourceOrders = [...this.orders.Items];
            this.dataSourceBets = [...this.bets.Items];
            this.dataSourceInstants = [...this.instants.Items];
        }
        this.spinnerService.hide();
    }

    public showMore(): void {
        this.spinnerService.show();
        this.interactionsService
            .getInteractions(this.lastEvaluatedKey, this.interactionsService.currentTab)
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(moreInteractions => {
                if (this.lastEvaluatedKey) {
                    if (this.interactionsService.currentTab === PromotionTypes.Promotion) {
                        for (const interaction of moreInteractions.Items) {
                            this.promotions.Items.push(interaction);
                        }
                        this.dataSourcePromotions = [...this.promotions.Items];
                    }
                    if (this.interactionsService.currentTab === PromotionTypes.Order) {
                        for (const interaction of moreInteractions.Items) {
                            this.orders.Items.push(interaction);
                        }
                        this.dataSourceOrders = [...this.orders.Items];
                    }
                    if (this.interactionsService.currentTab === PromotionTypes.Bet) {
                        for (const interaction of moreInteractions.Items) {
                            this.bets.Items.push(interaction);
                        }
                        this.dataSourceBets = [...this.bets.Items];
                    }
                    if (this.interactionsService.currentTab === PromotionTypes.Instant) {
                        for (const interaction of moreInteractions.Items) {
                            this.instants.Items.push(interaction);
                        }
                        this.dataSourceInstants = [...this.instants.Items];
                    }
                }
                this.lastEvaluatedKey = moreInteractions.LastEvaluatedKey;
                this.spinnerService.hide();
            });
    }

    public handleTabChange(event: MatTabChangeEvent): void {
        if (event.index === PromotionInteractionsTabs.Promotion) {
            this.lastEvaluatedKey = this.promotions.LastEvaluatedKey;
            this.interactionsService.unseenPromotions = 0;
            this.interactionsService.currentTab = PromotionTypes.Promotion;
        }
        if (event.index === PromotionInteractionsTabs.Order) {
            this.lastEvaluatedKey = this.orders.LastEvaluatedKey;
            this.interactionsService.unseenOrders = 0;
            this.interactionsService.currentTab = PromotionTypes.Order;
        }
        if (event.index === PromotionInteractionsTabs.Bet) {
            this.lastEvaluatedKey = this.bets.LastEvaluatedKey;
            this.interactionsService.unseenBets = 0;
            this.interactionsService.currentTab = PromotionTypes.Bet;
        }
        if (event.index === PromotionInteractionsTabs.Instant) {
            this.lastEvaluatedKey = this.instants.LastEvaluatedKey;
            this.interactionsService.unseenInstants = 0;
            this.interactionsService.currentTab = PromotionTypes.Instant;
        }
    }
}

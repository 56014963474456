import { IAuthenticationDetailsData, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AttributeListType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { AppConfigService } from '../services/app-config.service';

export class CognitoUtils {
    private poolData: { UserPoolId: string; ClientId: string };

    constructor(private appConfigService: AppConfigService) {
        this.appConfigService.poolData.subscribe(x => (this.poolData = x));
    }

    public static getAuthDetails(email: string, password: string): IAuthenticationDetailsData {
        return {
            Username: email,
            Password: password
        };
    }

    public getUserPool(): CognitoUserPool {
        return new CognitoUserPool(this.poolData);
    }

    public getAttribute(attrs: CognitoUserAttribute[], name: string): CognitoUserAttribute {
        return attrs.find(atr => atr.getName() === name);
    }

    // tslint:disable-next-line:no-any
    public getAttributeValue(attrs: AttributeListType, name: string, defValue: any): string {
        const attr = attrs.find(atr => atr.Name === name);
        return attr ? attr.Value : defValue;
    }

    public getActiveAttribute(attrs: AttributeListType): boolean {
        return this.getAttributeValue(attrs, 'custom:active', '1') === '1';
    }

    // tslint:disable-next-line:no-any
    public createNewUserAttributes(request: any): CognitoUserAttribute[] {
        const emailAttribute = new CognitoUserAttribute({
            Name: 'email',
            Value: request.email
        });
        const activeAttribute = new CognitoUserAttribute({
            Name: 'custom:active',
            Value: (request.active ? 1 : 0).toString()
        });
        return [emailAttribute, activeAttribute];
    }

    // tslint:disable-next-line:no-any
    public createUpdatableUserAttributesData(request: any): AttributeListType {
        const preferredUsername = {
            Name: 'preferred_username',
            Value: request.username
        };
        const emailAttribute = { Name: 'email', Value: request.email };
        const emailVerifiedAttribute = { Name: 'email_verified', Value: 'true' };
        const activeAttribute = {
            Name: 'custom:active',
            Value: (request.active ? 1 : 0).toString()
        };
        return [preferredUsername, emailAttribute, emailVerifiedAttribute, activeAttribute];
    }
}

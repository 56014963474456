import { Injectable } from '@angular/core';
import { Integration } from '@data/integration.model';
import { PromokioEntityCollectionServiceBase } from '@stores/services/promokio-entity-collection-service-base';
import { Observable, combineLatest } from 'rxjs';
import { EntityCollectionServiceElementsFactory, EntityActionOptions } from '@ngrx/data';
import { CompanyService } from '@services/company/company.service';
import { take, switchMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IntegrationService extends PromokioEntityCollectionServiceBase<Integration> {
    public integrationsForCompany$: Observable<Integration[]>;

    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private companyService: CompanyService) {
        super('Integration', serviceElementsFactory);
        this.integrationsForCompany$ = combineLatest([this.entities$, this.companyService.selectedCompanyId$]).pipe(
            map(([integrations, selectedCompanyId]) => {
                return integrations
                    .filter(integration => integration.companyId === selectedCompanyId)
                    .map(integration => {
                        const newIntegration = {
                            ...integration
                        };
                        newIntegration.id = newIntegration.id.split('$')[1];
                        return newIntegration;
                    });
            })
        );
    }

    public getAll(): Observable<Integration[]> {
        const return$ = this.companyService.selectedCompanyId$.pipe(
            take(1),
            switchMap(companyId => {
                return super.getAll({ correlationId: companyId });
            })
        );
        return$.subscribe();
        return return$;
    }

    public delete(entity: Integration | number | string): Observable<string | number> {
        const return$ = this.companyService.selectedCompanyId$.pipe(
            take(1),
            switchMap(companyId => {
                return super.delete(entity as Integration, { correlationId: companyId });
            })
        );
        return$.subscribe();
        return return$;
    }
}

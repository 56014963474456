import { BlockScrollStrategy, RepositionScrollStrategy, NoopScrollStrategy, CloseScrollStrategy } from '@angular/cdk/overlay';

export enum OverlayScrollStrategies {
    Block,
    Close,
    Noop,
    Reposition
}

export type ScrollStrategies = BlockScrollStrategy | NoopScrollStrategy | RepositionScrollStrategy | CloseScrollStrategy;

import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from '@data/device.model';
import { Group } from '@data/group.model';
import { TableColumn } from '@data/table-column.model';
import { CompanyService } from '@services/company/company.service';
import { DevicesService } from '@services/devices.service';
import { GroupsService } from '@services/groups.service';
import { SpinnerService } from '@services/spinner.service';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { TagsService } from '@services/tags.service';
import { Tag } from '@data/tag.model';

@Component({
    selector: 'promo-create-group',
    templateUrl: './create-group.component.html',
    styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent implements OnInit {
    public companyHasSwipingEnabled = false;
    public group: Group;
    public groupForm = new FormGroup({
        name: new FormControl('', Validators.required),
        tags: new FormControl('', Validators.required),
        devices: new FormControl(''),
        swipingEnabled: new FormControl()
    });
    public emptyGroup: Group = {
        id: undefined,
        name: undefined,
        tags: undefined,
        devices: [],
        swipingEnabled: true
    };
    public playlists = [];
    public dataSource = [];
    public selectedDevices = [];

    public displayedColumns: TableColumn[] = [
        {
            id: 'id',
            label: 'ID',
            type: 'string'
        },
        {
            id: 'name',
            label: 'Name',
            type: 'string'
        },
        {
            id: 'orientation',
            label: 'Orientation',
            type: 'string'
        },
        {
            id: 'groupName',
            label: 'Group',
            type: 'string'
        }
    ];
    public selection = new SelectionModel<string>(true, []);
    public playlistNames = [];
    @ViewChild(MatTable) public table: MatTable<string>;
    public edit = false;

    private tags$: Observable<Tag[]>;
    public tagsToShow$: Observable<Tag[]>;

    constructor(
        private groupService: GroupsService,
        private router: Router,
        private devicesService: DevicesService,
        private spinnerService: SpinnerService,
        private companyService: CompanyService,
        private route: ActivatedRoute,
        private tagService: TagsService
    ) {
        this.tags$ = this.tagService.tags$;
        this.tagsToShow$ = combineLatest([this.groupForm.get('tags').valueChanges.pipe(startWith(undefined)), this.tags$]).pipe(
            map(([valueChanged, tags]) => {
                const tag = this.groupForm.get('tags');
                if (valueChanged === undefined || !tag || !tag.value) {
                    return tags;
                }
                return tags.filter(tagObject => tagObject.tagName.toLowerCase().includes(tag.value.toLowerCase()));
            })
        );
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.tagService.getAll();
        // TODO: Refactor chain
        this.devicesService
            .getDevices()
            .pipe(
                switchMap(response => {
                    this.dataSource = response.Items;
                    return this.route.paramMap;
                }),
                switchMap(params => {
                    let groupId: string;
                    if (params.get('id')) {
                        this.edit = true;
                        groupId = params.get('id');
                    }
                    return of(groupId);
                }),
                switchMap(groupId => {
                    if (groupId) {
                        return this.groupService.getGroup(groupId).pipe(
                            map(group => {
                                return group.Items[0];
                            })
                        );
                    }
                    return of(this.emptyGroup);
                }),
                switchMap((group: Group) => {
                    this.group = group;
                    this.selectedDevices = this.group.devices;
                    return this.companyService.selectedCompany$;
                }),
                map(company => {
                    if (!company) {
                        this.companyService.getAll();
                    } else {
                        const { swipingEnabled } = company;
                        this.companyHasSwipingEnabled = swipingEnabled;
                        if (!swipingEnabled) {
                            this.group.swipingEnabled = false;
                        }
                    }
                    this.populateForm();
                }),
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.spinnerService.hide();
            });
    }

    private populateForm(): void {
        this.groupForm.get('name').setValue(this.group.name);
        this.groupForm.get('tags').setValue(this.group.tags);
        this.groupForm.get('devices').setValue(this.group.devices);
        this.groupForm.get('swipingEnabled').setValue(this.group.swipingEnabled);
    }

    public onSubmit(): void {
        if (this.groupForm.valid) {
            this.spinnerService.show();
            this.group = {
                id: this.group.id,
                name: this.groupForm.get('name').value,
                tags: this.groupForm.get('tags').value,
                devices: this.groupForm.get('devices').value.map((device): string => device.id || device),
                swipingEnabled: this.groupForm.get('swipingEnabled').value
            };
            this.groupService
                .createGroup(this.group)
                .pipe(
                    catchError(() => {
                        this.spinnerService.hide();
                        return EMPTY;
                    })
                )
                .subscribe(() => {
                    this.spinnerService.hide();
                    const navigationPromise = this.router.navigate(['/groups']);
                });
        }
    }

    public getDisplayedColumns(): string[] {
        return this.displayedColumns.map(x => x.id);
    }

    public deviceSelectionChange(event: Device[]): void {
        this.groupForm.get('devices').setValue(event.map(x => x.id));
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from '@data/device.model';
import { CompanyTypes } from '@data/enums/company-types.enum';
import { Group } from '@data/group.model';
import { CompanyService } from '@services/company/company.service';
import { EMPTY, of, Subject, Observable } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { DevicesService } from 'src/app/services/devices.service';
import { GroupsService } from 'src/app/services/groups.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import Swal from 'sweetalert2';
import { Company } from '@data/company.model';

@Component({
    selector: 'promo-create-device',
    templateUrl: './create-device.component.html',
    styleUrls: ['./create-device.component.scss']
})
export class CreateDeviceComponent implements OnInit, OnDestroy {
    public deviceForm = new FormGroup({
        id: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        orientation: new FormControl('', Validators.required),
        group: new FormControl(''),
        disableInteraction: new FormControl(),
        disableJIRAPromotions: new FormControl(false)
    });
    public groups: Group[];
    public device: Device;
    public edit = false;
    private emptyDevice: Device = {
        id: undefined,
        name: undefined,
        orientation: undefined,
        group: undefined,
        interactionEnabled: true,
        jiraPromotions: true
    };

    public companyTypes = CompanyTypes;
    private onDestroy$: Subject<void> = new Subject<void>();

    public company$: Observable<Company>;

    constructor(
        private groupService: GroupsService,
        private spinnerService: SpinnerService,
        private devicesService: DevicesService,
        private router: Router,
        private route: ActivatedRoute,
        private companyService: CompanyService
    ) {}

    public ngOnInit(): void {
        this.spinnerService.show();

        this.company$ = this.companyService.selectedCompany$;

        this.companyService.selectedCompany$.pipe(takeUntil(this.onDestroy$)).subscribe(company => {
            if (company.type !== CompanyTypes.CoWorkingSpace) {
                this.deviceForm.get('disableJIRAPromotions').setValue(true);
            }
        });

        this.groupService
            .getGroups()
            .pipe(
                switchMap(response => {
                    this.groups = response.Items;
                    return this.route.paramMap;
                }),
                map(params => {
                    let deviceId;
                    if (params.get('id')) {
                        deviceId = params.get('id');
                        this.edit = true;
                        this.disabledId();
                    }
                    return deviceId;
                }),
                switchMap((deviceId: string) => {
                    if (deviceId) {
                        return this.devicesService.getDevice(deviceId).pipe(
                            map(device => {
                                return device.Items[0];
                            })
                        );
                    }
                    return of(this.emptyDevice);
                }),
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(device => {
                this.device = device;
                this.populateForm();
                this.spinnerService.hide();
            });

        this.deviceForm.get('orientation').setValue('portrait');
    }

    public onSubmit(): void {
        this.spinnerService.show();
        this.device = {
            ...this.device,
            id: this.deviceForm.get('id').value,
            name: this.deviceForm.get('name').value,
            orientation: this.deviceForm.get('orientation').value,
            group: this.deviceForm.get('group').value,
            interactionEnabled: !this.deviceForm.get('disableInteraction').value,
            jiraPromotions: !this.deviceForm.get('disableJIRAPromotions').value
        };
        this.devicesService
            .createDevice(this.device)
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.spinnerService.hide();
                return this.navigateBack();
            });
    }

    private populateForm(): void {
        this.deviceForm.get('id').setValue(this.device.id);
        this.deviceForm.get('name').setValue(this.device.name);
        this.deviceForm.get('orientation').setValue(this.device.orientation);
        this.deviceForm.get('group').setValue(this.device.group);
        this.deviceForm.get('disableInteraction').setValue(!this.device.interactionEnabled);
        this.deviceForm.get('disableJIRAPromotions').setValue(!this.device.jiraPromotions);
    }

    private disabledId(): void {
        this.deviceForm.get('id').disable();
    }

    public archive(): void {
        const swalPromise = Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to undo this change',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'No, cancel',
            imageUrl: '../../assets/swal2-warn.PNG',
            imageWidth: 100,
            imageHeight: 100
        }).then(result => {
            if (result.value) {
                this.spinnerService.show();
                this.devicesService
                    .deleteDevice(this.device.id)
                    .pipe(
                        catchError(() => {
                            this.spinnerService.hide();
                            return EMPTY;
                        })
                    )
                    .subscribe(() => {
                        this.spinnerService.hide();
                        return this.navigateBack();
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log('cancelled deletion');
            }
        });
    }

    private navigateBack(): Promise<boolean> {
        const navigationRoute = this.device.isDesktop ? '/desktop-apps' : '/devices';
        return this.router.navigate([navigationRoute]);
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

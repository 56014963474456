import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from '@services/app-config.service';
import { CompanyService } from '@services/company/company.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyMedia } from '@data/company-media.model';

interface MediaResponse {
    mediaKeys: string[];
}

@Injectable({
    providedIn: 'root'
})
export class CompanyMediaDataService extends DefaultDataService<CompanyMedia> {
    private selectedCompany: string;

    constructor(
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private appConfigService: AppConfigService,
        private companyService: CompanyService
    ) {
        super('Media', http, httpUrlGenerator);
        this.companyService.selectedCompanyId$.subscribe(companyId => {
            this.selectedCompany = companyId;
            this.appConfigService.APIGateway.subscribe(APIGateway => {
                this.entityUrl = `${APIGateway}/companies/${this.selectedCompany}/media`;
                this.entitiesUrl = this.entityUrl;
            });
        });
    }

    public getById(): Observable<CompanyMedia> {
        return this.http.get<MediaResponse>(this.entitiesUrl).pipe(
            map(mediaResponse => {
                return {
                    id: this.selectedCompany,
                    media: mediaResponse.mediaKeys
                };
            })
        );
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatSlideToggleChange, MatSort } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '@data/company.model';
import { Group } from '@data/group.model';
import { Playlist } from '@data/playlist.model';
import { Promotion } from '@data/promotion.model';
import { Response } from '@data/response.model';
import { Tag } from '@data/tag.model';
import { TranslateService } from '@ngx-translate/core';
import { PlaylistPreviewComponent } from '@pages/promotion-playlists/playlist-preview/playlist-preview.component';
import { CompanyService } from '@services/company/company.service';
import { GroupsService } from '@services/groups.service';
import { OverlayLoaderService } from '@services/overlay-loader/overlay-loader.service';
import { PlaylistsService } from '@services/playlists.service';
import { PromotionsService } from '@services/promotions.service';
import { SpinnerService } from '@services/spinner.service';
import { TagsService } from '@services/tags.service';
import { PromoPreviewDialogComponent } from '@shared/promo-preview-dialog/promo-preview-dialog.component';
import moment from 'moment';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: ['Do MMMM YYYY', 'D/MM/YYYY', 'x', 'D/MM']
    },
    display: {
        dateInput: 'Do MMMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@Component({
    selector: 'promo-create-playlist',
    templateUrl: './create-playlist.component.html',
    styleUrls: ['./create-playlist.component.scss', '../promotion-playlists.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
    ]
})
export class CreatePlaylistComponent implements OnInit {
    public companyHasSwipingEnabled = false;
    public promotions: Response<Promotion>;
    public playlistPromotions: string[];
    public dataSource: MatTableDataSource<Promotion>;
    public displayedColumns = ['add', 'preview', 'name', 'date', 'advertText', 'tag', 'type'];
    public edit = false;
    // tslint:disable-next-line: no-any
    public playlist: any;
    private emptyPlaylist: Playlist = {
        id: undefined,
        name: undefined,
        groups: [],
        tag: undefined,
        promotions: [],
        isInfinite: undefined,
        loops: undefined,
        startDate: undefined,
        endDate: undefined,
        swipingEnabled: true
    };

    public playlistForm = new FormGroup({
        name: new FormControl('', Validators.required),
        tag: new FormControl('', Validators.required),
        groups: new FormControl(''),
        isInfinite: new FormControl(false),
        numberOfLoops: new FormControl('', Validators.required),
        startDate: new FormControl(Date.now()),
        endDate: new FormControl(Date.now()),
        swipingEnabled: new FormControl()
    });

    private isInfinite = this.playlistForm.get('isInfinite').value;
    public groups: Group[];

    private onDestroy$: Subject<void> = new Subject<void>();

    @ViewChild(MatSort) private sort: MatSort;

    private tags$: Observable<Tag[]>;
    public tagsToShow$: Observable<Tag[]>;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private spinnerService: SpinnerService,
        private companyService: CompanyService,
        private promotionService: PromotionsService,
        private playlistService: PlaylistsService,
        private groupService: GroupsService,
        private translateService: TranslateService,
        private tagsService: TagsService,
        private overlayLoader: OverlayLoaderService<PlaylistPreviewComponent>
    ) {
        this.tags$ = this.tagsService.tags$;
        this.tagsToShow$ = combineLatest([this.playlistForm.get('tag').valueChanges.pipe(startWith(undefined)), this.tags$]).pipe(
            map(([valueChanged, tags]) => {
                const tag = this.playlistForm.get('tag');
                if (valueChanged === undefined || !tag || !tag.value) {
                    return tags;
                }
                return tags.filter(tagObject => tagObject.tagName.toLowerCase().includes(tag.value.toLowerCase()));
            })
        );
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.playlistForm.get('groups').setValue([]);
        // TODO: This chain should be refactored at some point
        this.promotionService
            .getPromotions()
            .pipe(
                switchMap(promotions => {
                    this.promotions = promotions;
                    this.dataSource = new MatTableDataSource(promotions.Items);
                    this.dataSource.sort = this.sort;
                    this.dataSource.sortingDataAccessor = (data, header): string => String(data[header]).toLocaleLowerCase();
                    return this.route.paramMap;
                }),
                map(params => {
                    let playlistId: string;
                    if (params.get('id')) {
                        playlistId = params.get('id');
                        this.edit = true;
                    }
                    return playlistId;
                }),
                switchMap((playlistId: string) => {
                    if (playlistId) {
                        return this.playlistService.getPlaylist(playlistId).pipe(
                            map(group => {
                                return group.Items[0];
                            })
                        );
                    }
                    return of(this.emptyPlaylist);
                }),
                switchMap(playlist => {
                    this.playlist = playlist;
                    this.playlistPromotions = playlist.promotions.map(promotion => promotion.id);
                    this.isInfinite = this.playlist.isInfinite;
                    this.numberOfLoopsDisabled();
                    return this.groupService.getGroups();
                }),
                switchMap(groups => {
                    this.groups = groups.Items;
                    return this.companyService.selectedCompany$;
                }),
                map(company => {
                    if (!company) {
                        this.companyService.getAll();
                    } else {
                        const { swipingEnabled } = company;
                        this.companyHasSwipingEnabled = swipingEnabled;
                        if (!swipingEnabled) {
                            this.playlist.swipingEnabled = false;
                        }
                    }
                }),
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.populateForm();
                this.spinnerService.hide();
            });
        this.tagsService.getAll();
    }

    private populateForm(): void {
        this.playlistForm.get('name').setValue(this.playlist.name);
        this.playlistForm.get('tag').setValue(this.playlist.tag);
        this.playlistForm.get('groups').setValue(this.playlist.groups.map(x => x.id));
        this.playlistForm.get('isInfinite').setValue(this.playlist.isInfinite);
        this.playlistForm.get('numberOfLoops').setValue(this.playlist.loops);
        this.playlistForm.get('startDate').setValue(moment(this.playlist.startDate));
        this.playlistForm.get('endDate').setValue(moment(this.playlist.endDate));
        this.playlistForm.get('swipingEnabled').setValue(this.playlist.swipingEnabled);
    }

    public onSubmit(): void {
        const translationLocations = {
            title: 'Playlists.AddEdit.InfiniteWarning.Title',
            text: 'Playlists.AddEdit.InfiniteWarning.Text',
            confirmButton: 'Playlists.AddEdit.InfiniteWarning.ConfirmButton',
            cancelButton: 'Playlists.AddEdit.InfiniteWarning.CancelButton'
        };
        this.translateService
            .get(Object.values(translationLocations))
            .pipe(take(1))
            .subscribe(translations => {
                if (this.playlistForm.valid) {
                    const playlistSwipingEnabled = this.playlistForm.get('swipingEnabled').value;
                    const confirmationDialogNeeded = this.playlist.promotions.some((promotion: Promotion) => {
                        return !promotion.duration && (!promotion.swipingEnabled || !playlistSwipingEnabled);
                    });

                    if (confirmationDialogNeeded && this.playlist.promotions.length > 1) {
                        const swalPromise = Swal.fire({
                            title: translations[translationLocations.title],
                            html: translations[translationLocations.text],
                            showCancelButton: true,
                            confirmButtonText: translations[translationLocations.confirmButton],
                            cancelButtonText: translations[translationLocations.cancelButton],
                            imageUrl: '../../assets/swal2-warn.PNG',
                            imageWidth: 100,
                            imageHeight: 100
                        }).then(result => {
                            if (result.value) {
                                this.submitPlaylist();
                            }
                        });
                    } else {
                        this.submitPlaylist();
                    }
                }
            });
    }

    public submitPlaylist(): void {
        this.spinnerService.show();
        this.playlist = {
            id: this.playlist.id,
            name: this.playlistForm.get('name').value,
            groups: this.playlistForm.get('groups').value,
            tag: this.playlistForm.get('tag').value,
            promotions: this.playlist.promotions.map(promotion => promotion.id),
            isInfinite: this.playlistForm.get('isInfinite').value,
            loops: this.playlistForm.get('numberOfLoops').value,
            startDate: this.playlistForm.get('startDate').value.valueOf(),
            endDate: this.playlistForm.get('endDate').value.valueOf(),
            swipingEnabled: this.playlistForm.get('swipingEnabled').value
        };
        this.playlistService
            .updatePlaylist(this.playlist)
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(response => {
                this.spinnerService.hide();
                const navigationPromise = this.router.navigate([`/playlists/${response.id}`]);
            });
    }

    public toggleIsInfinite(): void {
        this.isInfinite = !this.isInfinite;
        this.numberOfLoopsDisabled();
    }

    private numberOfLoopsDisabled(): void {
        const numberOfLoops = this.playlistForm.get('numberOfLoops');
        if (this.isInfinite) {
            numberOfLoops.disable();
            numberOfLoops.setValue('');
        } else {
            numberOfLoops.enable();
        }
        numberOfLoops.updateValueAndValidity();
    }

    public showMore(): void {
        this.spinnerService.show();
        this.promotionService
            .getPromotions(this.promotions.LastEvaluatedKey)
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(promotions => {
                for (const promotion of promotions.Items) {
                    this.promotions.Items.push(promotion);
                }
                this.dataSource = new MatTableDataSource(promotions.Items);
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = (data, header): string => String(data[header]).toLocaleLowerCase();
                this.promotions.LastEvaluatedKey = promotions.LastEvaluatedKey;
                this.spinnerService.hide();
            });
    }

    public applyFilter(filterValue: string): void {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    public showPreview(item: Promotion): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = item;
        this.dialog.open(PromoPreviewDialogComponent, dialogConfig);
    }

    public addPromotion(promotion: Promotion): void {
        this.playlist.promotions.push(promotion);
        this.playlistPromotions.push(promotion.id);
    }

    public removePromotion(promotion: Promotion): void {
        const lastIndex = this.playlistPromotions.lastIndexOf(promotion.id);
        this.playlist.promotions.splice(lastIndex, 1);
        this.playlistPromotions.splice(lastIndex, 1);
    }

    public playlistCount(idToCount: string): string {
        const count = this.playlistPromotions.filter(promoId => promoId === idToCount).length;
        return `x ${count}`;
    }

    public previewPlaylist(): void {
        const playlistPreview = this.overlayLoader.show(PlaylistPreviewComponent);
        playlistPreview.playlist = this.playlist;

        const previewClosedSub = playlistPreview.modalClosed
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.overlayLoader.hide());

        this.overlayLoader.overlayClosedEvents$
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => previewClosedSub.unsubscribe());
    }

    public updateSwipingEnabled(event: MatSlideToggleChange): void {
        this.playlist.swipingEnabled = event.checked;
    }
}

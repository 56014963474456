import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

import { CognitoService } from '@services/cognito.service';

const patternValidator = (regex: RegExp, error: ValidationErrors): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            return null;
        }
        const valid = regex.test(control.value);
        return valid ? null : error;
    };
};

const passwordMatchValidator = (control: AbstractControl) => {
    const password: string = control.get('newPassword').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmNewPassword').value; // get password from our confirmPassword form control
    if (password !== confirmPassword) {
        control.get('confirmNewPassword').setErrors({ NoPasswordMatch: true });
    }
};

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
    @Output() changedPassword = new EventEmitter<boolean>();

    public passwordForm: FormGroup;
    username: any;
    tempPassword: string;

    constructor(private fb: FormBuilder, private cognitoService: CognitoService) {
        this.passwordForm = this.createSignUpForm();
    }

    createSignUpForm(): FormGroup {
        return this.fb.group(
            {
                oldPassword: [null, Validators.compose([Validators.required])],
                newPassword: [
                    null,
                    Validators.compose([
                        Validators.required,
                        // check whether the entered password has a number
                        patternValidator(/\d/, {
                            hasNumber: true
                        }),
                        // check whether the entered password has upper case letter
                        patternValidator(/[A-Z]/, {
                            hasCapitalCase: true
                        }),
                        // check whether the entered password has a lower case letter
                        patternValidator(/[a-z]/, {
                            hasSmallCase: true
                        }),
                        Validators.minLength(8)
                    ])
                ],
                confirmNewPassword: [null, Validators.compose([Validators.required])]
            },
            {
                // check whether our password and confirm password match
                validator: passwordMatchValidator
            }
        );
    }

    ngOnInit() {}

    changePassword(): void {
        this.cognitoService.changePassword(this.passwordForm.value.oldPassword, this.passwordForm.value.newPassword);
        this.changedPassword.emit(true);
    }
}

import { Component, OnInit } from '@angular/core';
import { CognitoService } from '@services/cognito.service';
import { SpinnerService } from '@services/spinner.service';
import { AuthService } from '@services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'promo-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public username: string;
    public password: string;

    public versionNumber = environment.versionNumber;

    constructor(private cognitoService: CognitoService, private spinnerService: SpinnerService, private authService: AuthService) {}

    public ngOnInit(): void {
        this.spinnerService.hide();
        this.cognitoService.signOut();
    }

    public login(): void {
        this.cognitoService.login(this.username, this.password).subscribe(result => {
            if (result) {
                this.authService.setCurrentLoggedInUser();
            }
        });
    }
}

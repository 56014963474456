import { InstantsComponent } from './instants-library.component';
import { PromotionTemplateComponent } from '@pages/promotion-library/promotion-template/promotion-template.component';
import { EditInstantComponent } from './edit-instants/edit-instant.component';

export const instantsRoutes = [
    {
        path: '',
        component: InstantsComponent
    },
    {
        path: 'create',
        component: PromotionTemplateComponent
    },
    {
        path: ':id/edit',
        component: EditInstantComponent
    }
];

import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../../../services/cognito.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    username: string;

    constructor(private cognitoService: CognitoService) {}

    ngOnInit() {}

    forgotPassword() {
        this.cognitoService.forgotPassword(this.username);
    }
}

import { Component, OnInit } from '@angular/core';
import { CognitoService } from '@services/cognito.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    username: string;
    password: string;

    constructor(private cognitoService: CognitoService) {}

    ngOnInit() {}

    register() {
        this.cognitoService.signUp(this.username, this.password);
    }
}

import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

export const popupFooterAnimations = [
    trigger('footerToggled', [
        transition(
            'void => *',
            animate(
                '150ms ease-in',
                keyframes([
                    style({ overflow: 'hidden', height: '0px', 'min-height': '0px', 'padding-top': '0px', 'padding-bottom': '0px' }),
                    style({ overflow: 'hidden', height: '64px', 'min-height': '64px', 'padding-top': '8px', 'padding-bottom': '8px' })
                ])
            )
        )
    ])
];

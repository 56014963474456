import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AddEditCompanyTypeComponent } from './company-types/add-edit-company-type/add-edit-company-type.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { AdminTabComponent } from './admin-tab/admin-tab.component';
import { AddEditCompanyRoleComponent } from './company-roles/add-edit-company-role.component';

@NgModule({
    declarations: [AdminComponent, AddEditCompanyTypeComponent, AdminTabComponent, AddEditCompanyRoleComponent],
    imports: [CommonModule, ReactiveFormsModule, SharedModule, TranslateModule, RouterModule]
})
export class AdminModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Company } from '@data/company.model';
import { SpinnerService } from '@services/spinner.service';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, map, take } from 'rxjs/operators';
import { InstantTemplate, InstantTemplateResponse } from '@data/instant-template.model';
import { InstantTemplatesService } from '@services/instant-templates.service';
import { EntityOp, ofEntityOp } from '@ngrx/data';

@Component({
    selector: 'promo-edit-instants',
    templateUrl: './edit-instant.component.html',
    styleUrls: ['./edit-instant.component.css']
})
export class EditInstantComponent implements OnInit, OnDestroy {
    private onDestroy$: Subject<void> = new Subject<void>();
    public instant$: Observable<InstantTemplateResponse>;
    private instantId: string;
    public companyId: Observable<Company>;
    public companyColour: string;
    public companyHasSwipingEnabled: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private spinnerService: SpinnerService,
        private instantTemplatesService: InstantTemplatesService
    ) {}

    public ngOnInit(): void {
        this.instant$ = combineLatest([this.instantTemplatesService.instantTemplates$, this.route.paramMap]).pipe(
            map(([templates, params]: [InstantTemplateResponse[], ParamMap]) => {
                this.instantId = params.get('id');
                return templates.find(template => template.id === this.instantId);
            })
        );
        this.instantTemplatesService.loading$.subscribe(loading => {
            loading ? this.spinnerService.show() : this.spinnerService.hide();
        });
        this.instant$.pipe(take(1)).subscribe(instantTemplate => {
            if (instantTemplate === undefined) {
                this.instantTemplatesService.getAll();
            }
        });
        this.instantTemplatesService.entityActions$
            .pipe(
                ofEntityOp([EntityOp.SAVE_DELETE_ONE_SUCCESS, EntityOp.SAVE_UPDATE_ONE_SUCCESS]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.router.navigate(['/instants']));
        this.instantTemplatesService.entityActions$
            .pipe(
                ofEntityOp([EntityOp.SAVE_DELETE_ONE_ERROR, EntityOp.SAVE_UPDATE_ONE_ERROR]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.spinnerService.hide());
    }

    public updateInstantPromotion(instant: InstantTemplate): void {
        this.spinnerService.show();
        this.instantTemplatesService.update({
            id: this.instantId,
            template: instant
        });
    }

    public deleteInstant(): void {
        this.instantTemplatesService.delete(this.instantId);
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

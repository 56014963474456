import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from '@services/app-config.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetAllPermissionsResponse } from './permissions-responses.model';
import { Permissions } from '@data/permissions/permissions.model';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    private APIGateway = '';
    private endpoint = '/permissions';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
    }

    public getAllAvailablePermissions(companyId?: string): Observable<string[]> {
        let params = new HttpParams();
        if (companyId) {
            params = params.set('companyId', companyId);
        }
        const url = `${this.APIGateway}${this.endpoint}`;
        return this.http.get<GetAllPermissionsResponse>(url, { params: params }).pipe(
            map(permissionsResponse => {
                return permissionsResponse.permissions;
            })
        );
    }

    public getPermissionsProfile(id: string, profileType: string): Observable<Permissions> {
        const url = `${this.APIGateway}${this.endpoint}/${profileType}/${id}`;
        return this.http.get<Permissions>(url).pipe(
            map(companyTypePermissions => {
                return new Permissions(companyTypePermissions.id, companyTypePermissions.permissions, companyTypePermissions.name);
            })
        );
    }

    public savePermissionsProfile(companyTypePermissions: Permissions, profileType: string): Observable<Permissions> {
        let url = `${this.APIGateway}${this.endpoint}/${profileType}`;
        let response: Observable<Permissions>;
        if (companyTypePermissions.id) {
            url += `/${companyTypePermissions.id}`;
            response = this.http.put<Permissions>(url, companyTypePermissions);
        } else {
            response = this.http.post<Permissions>(url, companyTypePermissions);
        }
        return response.pipe(
            map(companyTypePermissionsResponse => {
                return new Permissions(
                    companyTypePermissionsResponse.id,
                    companyTypePermissionsResponse.permissions,
                    companyTypePermissionsResponse.name
                );
            })
        );
    }
}

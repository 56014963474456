import { EntityDataModuleConfig, EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
    Company: {
        additionalCollectionState: {
            selectedCompany: localStorage.getItem('selectedCompany') || undefined
        }
    },
    CompanyRoles: {},
    CompanyTypes: {},
    InstantTemplate: {},
    Integration: {},
    Media: {},
    Tag: {}
};

export const entityConfig: EntityDataModuleConfig = {
    entityMetadata
};

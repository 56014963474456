import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment';
import { Subscription, empty, Observable, Subject } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { InteractionsService } from '../../services/interactions.service';
import { PromotionsService } from '../../services/promotions.service';
import { ReportingService } from '../../services/reporting.service';
import { SpinnerService } from '../../services/spinner.service';

import * as RolesEnum from '../../data/enums/roles.enum';
import { PromotionIcons } from '../../data/enums/promotion-types.enum';

import { PromotionStatistics } from '../../data/promotion-statistics.model';
import { catchError, takeUntil, take } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material';
import { CompanyService } from '@services/company/company.service';

export const MY_FORMATS = {
    parse: {
        dateInput: ['Do MMMM YYYY', 'D/MM/YYYY']
    },
    display: {
        dateInput: 'Do MMMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@Component({
    selector: 'promo-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class DashboardComponent implements OnInit, OnDestroy {
    public promotionIcons = PromotionIcons;
    public showPromotionBreakdown = false;
    public totalInteractions: number;
    public totalEmailsSubmitted: number;
    public totalEmailsDismissed: number;
    public promotionsStats: PromotionStatistics[];
    public startDate: number = moment()
        .startOf('month')
        .valueOf();
    public endDate: number = moment()
        .endOf('month')
        .valueOf();
    public initialStartDate = new FormControl(moment().startOf('month'));
    public initialEndDate = new FormControl(moment().endOf('month'));

    public currentUserRoles: string[];
    public roles = RolesEnum.Roles;
    public callToActionMessage: string;

    private currentUserRoleSubscription: Subscription;

    private currentCompanyId$: Observable<string>;
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private promotionsService: PromotionsService,
        private spinnerService: SpinnerService,
        private reportingService: ReportingService,
        private interactionsService: InteractionsService,
        private authService: AuthService,
        private companyService: CompanyService
    ) {
        this.currentCompanyId$ = this.companyService.selectedCompanyId$.pipe(takeUntil(this.onDestroy$));
    }

    public ngOnInit(): void {
        this.currentCompanyId$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.updateDashboard(this.startDate, this.endDate);
        });
        this.currentUserRoleSubscription = this.authService.currentUserRoles$.subscribe(currentUserRoles => {
            this.currentUserRoles = currentUserRoles;
        });
        this.updateDashboard(this.startDate, this.endDate);
    }

    public ngOnDestroy(): void {
        this.currentUserRoleSubscription.unsubscribe();
        this.onDestroy$.next();
    }

    public updateDashboard(startDate: number, endDate: number): void {
        this.currentCompanyId$.pipe(take(1)).subscribe(id => {
            if (id) {
                this.spinnerService.show();
                this.reportingService
                    .getReporting(startDate, endDate, true, true)
                    .pipe(
                        catchError(err => {
                            this.spinnerService.hide();
                            return empty();
                        })
                    )
                    .subscribe(
                        ({
                            totalInteractions,
                            interactionsWithEmailsEnabled,
                            interactionsWithEmailsSubmitted,
                            totalPromotions,
                            promotionsStats
                        }) => {
                            this.promotionsStats = promotionsStats;
                            this.callToActionMessage = `You currently have ${totalPromotions || 'no'} active promotions`;
                            this.totalInteractions = totalInteractions;
                            this.totalEmailsSubmitted = interactionsWithEmailsSubmitted;
                            this.totalEmailsDismissed = interactionsWithEmailsEnabled - interactionsWithEmailsSubmitted;
                            this.spinnerService.hide();
                        }
                    );
            }
        });
    }

    public setStartDate(event: MatDatepickerInputEvent<number>): void {
        this.startDate = event.value ? event.value.valueOf() : undefined;
        this.updateDashboard(this.startDate, this.endDate);
    }

    public setEndDate(event: MatDatepickerInputEvent<number>): void {
        this.endDate = event.value ? event.value.valueOf() : undefined;
        this.updateDashboard(this.startDate, this.endDate);
    }

    public exportEmails(): void {
        this.interactionsService.getEmailExportUrl(this.startDate, this.endDate).subscribe(url => {
            window.open(String(url), '_blank');
        });
    }
}

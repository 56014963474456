import { NgModule } from '@angular/core';
import { CompaniesComponent } from './companies.component';
import { CreateCompanyComponent } from './create-company/create-company.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [CompaniesComponent, CreateCompanyComponent],
    imports: [TranslateModule, SharedModule, CommonModule, ReactiveFormsModule, ColorPickerModule, RouterModule]
})
export class CompaniesModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfigService } from './app-config.service';

import { Response } from '../data/response.model';

@Injectable({
    providedIn: 'root'
})
export class AuditingService {
    private APIGateway: string;
    private endpoint = '/audit';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
    }

    // tslint:disable-next-line:no-any
    public getAuditing(): Observable<Response<any>> {
        // tslint:disable-next-line:no-any
        return this.http.get<Response<any>>(this.APIGateway + this.endpoint);
    }
}

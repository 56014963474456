export class ColourUtils {
    public static convertFromRGBAtoARGB(color: string): string {
        const hashPosition = {
            start: 0,
            length: 1
        };
        const alphaPosition = {
            start: 7,
            length: 2
        };
        const colourPosition = {
            start: 1,
            length: 6
        };
        return (
            color.substr(hashPosition.start, hashPosition.length) +
            color.substr(alphaPosition.start, alphaPosition.length) +
            color.substr(colourPosition.start, colourPosition.length)
        );
    }

    public static convertFromARGBtoRGBA(color: string): string {
        const hashPosition = {
            start: 0,
            length: 1
        };
        const alphaPosition = {
            start: 1,
            length: 2
        };
        const colourPosition = {
            start: 3,
            length: 6
        };
        return (
            color.substr(hashPosition.start, hashPosition.length) +
            color.substr(colourPosition.start, colourPosition.length) +
            color.substr(alphaPosition.start, alphaPosition.length)
        );
    }
}

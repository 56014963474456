import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaComponent } from './media.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [MediaComponent],
    imports: [CommonModule, SharedModule, TranslateModule]
})
export class MediaModule {}

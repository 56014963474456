import { Component, isDevMode, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Subject, Subscription, Observable } from 'rxjs';
import { takeUntil, take, tap } from 'rxjs/operators';
import { PromotionTypes } from './data/enums/promotion-types.enum';
import { Interaction } from './data/interaction.model';
import { AppConfigService } from './services/app-config.service';
import { AuthService } from './services/auth.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { WebsocketsService } from './services/websockets.service';
import { CognitoService } from '@services/cognito.service';
import { OverlayContainerComponent } from '@shared/overlay-container/overlay-container.component';
import { OverlayLoaderService } from '@services/overlay-loader/overlay-loader.service';
import { Router } from '@angular/router';
import { NavbarService } from '@services/navbar/navbar.service';
import { IconService } from '@services/icon-service';

@Component({
    selector: 'promo-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    public title = 'Promokio';
    private websocketSubscription: Subscription;
    public updateAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public isLoggedIn$: Observable<boolean>;
    public hasLoadedConfig$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private onDestroy$ = new Subject<void>();

    @ViewChild(OverlayContainerComponent)
    // tslint:disable-next-line:no-any
    private overlayContainer: OverlayContainerComponent<any>;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private googleAnalyticsService: GoogleAnalyticsService,
        private websocketsService: WebsocketsService,
        private snackBar: MatSnackBar,
        translate: TranslateService,
        private swUpdate: SwUpdate,
        private cognitoService: CognitoService,
        // tslint:disable-next-line:no-any
        private overlayLoader: OverlayLoaderService<any>,
        private router: Router,
        private iconService: IconService
    ) {
        // Set up translate module to use english translation
        // These can be changed later
        translate.setDefaultLang('en');
        translate.use('en');
        this.isLoggedIn$ = this.cognitoService.isLoggedIn$;
        this.appConfigService.APIGateway.pipe(take(1)).subscribe(() => this.hasLoadedConfig$.next(true));
        this.iconService.registerIcons();
    }

    public async ngOnInit(): Promise<void> {
        const sessionValidityPromise = this.cognitoService.checkSessionValidity();
        this.websocketSubscription = this.websocketsService.alert$.subscribe((response: Interaction) => {
            this.openSnackBar(1, response.type as PromotionTypes);
        });
        if (!isDevMode()) {
            this.googleAnalyticsService.init();
        }
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.updateAvailable$.next(true));
        }

        this.overlayLoader.registerRootOverlay(this.overlayContainer);

        this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
            this.overlayLoader.hide();
        });
    }

    public ngOnDestroy(): void {
        this.websocketSubscription.unsubscribe();
        this.onDestroy$.next();
    }

    public openSnackBar(numberOfNewInteractions: number, type: PromotionTypes): void {
        const plural = numberOfNewInteractions > 1 ? 's' : '';
        const threeSeconds = 3000;
        this.snackBar.open(`${numberOfNewInteractions} New ${type}${plural}`, 'Ok', {
            duration: threeSeconds
        });
    }

    public closedUpdateAvailable(): void {
        this.updateAvailable$.next(false);
    }

    public reloadSite(): void {
        window.location.reload();
    }
}

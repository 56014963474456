export enum StatusCodes {
    Offline = 0,
    Ok = 200,
    PartialContent = 206,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    Unexpected = 500,
    BadGateway = 502,
    GatewayTimeout = 504
}

import { PromokioEntityCollectionReducerMethods } from './promokio-entity-collection-reducer-methods';
import { Company } from '@data/company.model';
import {
    EntityDefinition,
    EntityChangeTracker,
    EntityAction,
    EntityDefinitionService,
    EntityCollectionReducerMethodMap,
    EntityCollectionReducer,
    EntityCollection
} from '@ngrx/data';
import { CompanyEntityOperations } from '../operations/company-entity-operations.enum';
import { CompanyEntityCollection } from '../collections/company/company-entity-collection';
import { Injectable } from '@angular/core';

export class CompanyReducers extends PromokioEntityCollectionReducerMethods<Company> {
    constructor(
        public entityName: string,
        public definition: EntityDefinition<Company>,
        entityChangeTracker?: EntityChangeTracker<Company>
    ) {
        super(entityName, definition, entityChangeTracker);
        this.methods[CompanyEntityOperations.UPDATE_SELECTED_COMPANY] = this.updateSelectedCompany.bind(this);
    }

    protected updateSelectedCompany(collection: CompanyEntityCollection, action: EntityAction<string>): CompanyEntityCollection {
        const data = this.extractData(action);
        localStorage.setItem('selectedCompany', data);
        return {
            ...collection,
            selectedCompany: data
        };
    }
}

@Injectable()
export class CompanyCollectionReducerMethodsFactory {
    constructor(private entityDefinitionService: EntityDefinitionService) {}

    /** Create the  {EntityCollectionReducerMethods} for the named entity type */
    public create(): EntityCollectionReducerMethodMap<Company> {
        const definition = this.entityDefinitionService.getDefinition<Company>('Company');
        const methodsClass = new CompanyReducers('Company', definition);

        return methodsClass.methods;
    }
}

@Injectable()
export class CompanyCollectionReducerFactory {
    constructor(private methodsFactory: CompanyCollectionReducerMethodsFactory) {}

    public create(): EntityCollectionReducer<Company> {
        const methods = this.methodsFactory.create();

        const companyCollectionReducer = (collection: EntityCollection<Company>, action: EntityAction): EntityCollection<Company> => {
            const reducerMethod = methods[action.payload.entityOp];
            return reducerMethod ? reducerMethod(collection, action) : collection;
        };

        return companyCollectionReducer;
    }
}

import { Injectable } from '@angular/core';
import { Device } from '../data/device.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { Observable } from 'rxjs';
import { Response } from '../data/response.model';
import { DesktopAppConfig } from '@data/desktop-app-config.model';
@Injectable({
    providedIn: 'root'
})
export class DevicesService {
    private APIGateway = '';
    private endpoint = '/devices';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
    }

    public getDevices(isDesktop?: boolean): Observable<Response<Device>> {
        let params = new HttpParams();
        if (isDesktop !== undefined) {
            params = params.set('isDesktop', isDesktop.toString());
        }
        return this.http.get<Response<Device>>(this.APIGateway + this.endpoint, { params: params });
    }

    public getDevice(id: string): Observable<Response<Device>> {
        return this.http.get<Response<Device>>(this.APIGateway + this.endpoint + '/' + id);
    }

    public createDevice(device: Device): Observable<Response<Device>> {
        let params = new HttpParams();
        if (device.isDesktop === true) {
            params = params.set('isDesktop', device.isDesktop.toString());
        }
        return this.http.post<Response<Device>>(this.APIGateway + this.endpoint, device, { params: params });
    }

    public deleteDevice(id: string): Observable<void> {
        return this.http.delete<void>(this.APIGateway + this.endpoint + '/' + id);
    }
}

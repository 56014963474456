import { DevicesComponent } from './devices.component';
import { ViewDeviceComponent } from './view-device/view-device.component';
import { CreateDesktopAppComponent } from './create-desktop-app/create-desktop-app.component';
import { CreateDeviceComponent } from './create-device/create-device.component';

export const desktopAppRoutes = [
    {
        path: '',
        component: DevicesComponent
    },
    {
        path: 'create',
        component: CreateDesktopAppComponent
    },
    {
        path: ':id',
        component: ViewDeviceComponent
    },
    {
        path: ':id/edit',
        component: CreateDeviceComponent
    }
];

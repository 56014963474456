import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TableColumn } from '../../data/table-column.model';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'app-select-table',
    templateUrl: './select-table.component.html',
    styleUrls: ['./select-table.component.css']
})
export class SelectTableComponent implements OnInit {
    @Input() columns: TableColumn[];
    @Output() selectedData: EventEmitter<any[]> = new EventEmitter<any[]>();

    // tslint:disable-next-line: variable-name
    _data: any[];
    get data(): any[] {
        return this._data;
    }

    @Input('data')
    set data(value: any[]) {
        if (value) {
            this._data = value;
        } else {
            this._data = [];
        }
    }

    // tslint:disable-next-line: variable-name
    private _displayedColumns: string[];
    get displayedColumns(): string[] {
        return this._displayedColumns;
    }

    @Input('displayedColumns')
    set displayedColumns(value: string[]) {
        setTimeout(() => {
            this._displayedColumns = value;
            if (!value.includes('select')) {
                this._displayedColumns.unshift('select');
            }
        });
    }

    // tslint:disable-next-line: variable-name
    private _preselectedData: any[];
    get preselectedData(): any[] {
        return this._preselectedData;
    }

    @Input('preselectedData')
    set preselectedData(value: any[]) {
        this._preselectedData = value;
        if (value) {
            this._preselectedData.forEach(selectedRow => {
                selectedRow = this._data.find(x => x.id === selectedRow.id);
                if (selectedRow) {
                    this.selection.select(selectedRow);
                }
            });
        }
    }

    selection = new SelectionModel<any>(true, []);

    constructor() {}

    ngOnInit() {
        this.selection.changed.subscribe(x => {
            if (this._data) {
                this.selectedData.emit(this._data.filter(row => this.selection.isSelected(row)));
            }
        });
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this._data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this._data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }
}

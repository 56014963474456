import { NgModule } from '@angular/core';
import { CreateDeviceComponent } from './create-device/create-device.component';
import { ViewDeviceComponent } from './view-device/view-device.component';
import { DevicesComponent } from './devices.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CreateDesktopAppComponent } from './create-desktop-app/create-desktop-app.component';

@NgModule({
    declarations: [CreateDeviceComponent, ViewDeviceComponent, DevicesComponent, CreateDesktopAppComponent],
    imports: [TranslateModule, CommonModule, SharedModule, ReactiveFormsModule, RouterModule]
})
export class DevicesModule {}

import {
    EntityChangeTracker,
    EntityAction,
    EntityDefinition,
    EntityCollectionReducerMethodMap,
    EntityDefinitionService,
    EntityCollection,
    EntityCollectionReducer,
    EntityActionDataServiceError
} from '@ngrx/data';
import { PromokioEntityCollection } from '@stores/collections/promokio-entity-collection';
import { PromokioEntityCollectionReducerMethods } from './promokio-entity-collection-reducer-methods';
import { Injectable } from '@angular/core';
import { Integration } from '@data/integration.model';

export class IntegrationReducers extends PromokioEntityCollectionReducerMethods<Integration> {
    constructor(
        public entityName: string,
        public definition: EntityDefinition<Integration>,
        entityChangeTracker?: EntityChangeTracker<Integration>
    ) {
        super(entityName, definition, entityChangeTracker);
    }

    protected queryAllSuccess(
        collection: PromokioEntityCollection<Integration>,
        action: EntityAction<Integration[]>
    ): PromokioEntityCollection<Integration> {
        const newCollection = super.queryAllSuccess(collection, action);

        const data = this.extractData(action);
        const companyId = action.payload.correlationId;

        const integrationIds = data.map(x => x.id);
        const missingKeys = Object.keys(newCollection.entities).filter(integrationId => {
            const integration = newCollection.entities[integrationId];
            if (integration.companyId !== companyId || (integration.companyId === companyId && integrationIds.includes(integrationId))) {
                return false;
            }
            return true;
        });

        const newEntities = newCollection.entities;
        missingKeys.forEach(key => {
            delete newEntities[key];
        });

        return {
            ...newCollection,
            entities: newEntities,
            ids: Object.keys(newEntities)
        };
    }

    protected saveDeleteOne(
        collection: PromokioEntityCollection<Integration>,
        action: EntityAction<string>
    ): PromokioEntityCollection<Integration> {
        const entityId = this.extractData(action);
        const companyId = action.payload.correlationId;

        const entity = collection.entities[`${companyId}$${entityId}`];

        entity.isEnabled = false;

        const mergeStrategy = this.extractMergeStrategy(action);
        return {
            ...this.entityChangeTracker.mergeQueryResults([entity], collection, mergeStrategy),
            loading: true
        };
    }

    protected saveDeleteOneError(
        collection: PromokioEntityCollection<Integration>,
        action: EntityAction<EntityActionDataServiceError>
    ): PromokioEntityCollection<Integration> {
        const data = this.extractData(action);

        const entityId = data.originalAction.payload.data;
        const companyId = data.originalAction.payload.correlationId;

        const entity = collection.entities[`${companyId}$${entityId}`];

        entity.isEnabled = true;

        const mergeStrategy = this.extractMergeStrategy(action);
        return {
            ...this.entityChangeTracker.mergeQueryResults([entity], collection, mergeStrategy),
            loading: false
        };
    }

    protected saveDeleteOneSuccess(
        collection: PromokioEntityCollection<Integration>,
        action: EntityAction<string | number>
    ): PromokioEntityCollection<Integration> {
        return this.setLoadingFalse(collection);
    }

    protected saveAddOneSuccess(
        collection: PromokioEntityCollection<Integration>,
        action: EntityAction<Integration>
    ): PromokioEntityCollection<Integration> {
        const data = this.extractData(action);
        const entityId = data.id;

        const entity = collection.entities[entityId];

        entity.isEnabled = true;

        const mergeStrategy = this.extractMergeStrategy(action);
        return {
            ...this.entityChangeTracker.mergeQueryResults([entity], collection, mergeStrategy),
            loading: false
        };
    }
}

@Injectable()
export class IntegrationCollectionReducerMethodsFactory {
    constructor(private entityDefinitionService: EntityDefinitionService) {}

    public create(): EntityCollectionReducerMethodMap<Integration> {
        const definition = this.entityDefinitionService.getDefinition<Integration>('Integration');
        const methodsClass = new IntegrationReducers('Integration', definition);

        return methodsClass.methods;
    }
}

@Injectable()
export class IntegrationCollectionReducerFactory {
    constructor(private methodsFactory: IntegrationCollectionReducerMethodsFactory) {}

    public create(): EntityCollectionReducer<Integration> {
        const methods = this.methodsFactory.create();

        const integrationCollectionReducer = (
            collection: EntityCollection<Integration>,
            action: EntityAction
        ): EntityCollection<Integration> => {
            const reducerMethod = methods[action.payload.entityOp];
            return reducerMethod ? reducerMethod(collection, action) : collection;
        };

        return integrationCollectionReducer;
    }
}

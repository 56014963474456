import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Playlist, PlaylistUpdate } from '../data/playlist.model';
import { AppConfigService } from './app-config.service';
import { Response, LastEvaluatedKey } from '../data/response.model';

@Injectable({
    providedIn: 'root'
})
export class PlaylistsService {
    private APIGateway = '';
    private endpoint = '/playlists';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
    }

    public getPlaylists(lastEvaluatedKey?: LastEvaluatedKey): Observable<Response<Playlist>> {
        let filters = '';
        if (lastEvaluatedKey) {
            filters = `?date=${lastEvaluatedKey.date}&id=${lastEvaluatedKey.id}&company=${lastEvaluatedKey.company}`;
        }
        return this.http.get<Response<Playlist>>(this.APIGateway + this.endpoint + filters);
    }

    public getPlaylist(id: string): Observable<Response<Playlist>> {
        return this.http.get<Response<Playlist>>(this.APIGateway + this.endpoint + '/' + id);
    }

    // tslint:disable-next-line:no-any
    public createPlaylist(playlist: Playlist): Observable<any> {
        return this.http.post(this.APIGateway + this.endpoint, playlist);
    }

    // tslint:disable-next-line:no-any
    public updatePlaylist(playlist: PlaylistUpdate): Observable<any> {
        return this.http.post(this.APIGateway + this.endpoint, playlist);
    }

    // tslint:disable-next-line:no-any
    public deletePlaylist(id: string): Observable<any> {
        return this.http.delete(`${this.APIGateway}${this.endpoint}/${id}`);
    }
}

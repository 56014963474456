import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MenuItem } from '@data/promotion.model';
import { OrderPreviewDialogComponent } from '@shared/order-preview-dialog/order-preview-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { TableColumn } from '../../data/table-column.model';

interface MenuEvent {
    menuSelection: MenuItem[];
    isValidMenu: boolean;
}

@Component({
    selector: 'promo-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
    @Input() public columns: TableColumn[];
    @Input() public companyColour: string;
    @Output() public menuOutput = new EventEmitter<MenuEvent>();

    public _data: MenuItem[];
    get data(): MenuItem[] {
        return this._data;
    }

    @Input('data')
    set data(value: MenuItem[]) {
        this._data = value || [];
    }

    public dataSource = new BehaviorSubject([]);

    private _displayedColumns: string[];
    get displayedColumns(): string[] {
        return this._displayedColumns;
    }

    @Input('displayedColumns')
    set displayedColumns(value: string[]) {
        setTimeout(() => {
            this._displayedColumns = value;
            if (!value.includes('select')) {
                this._displayedColumns.unshift('select');
            }
            if (!value.includes('clear')) {
                this._displayedColumns.push('clear');
            }
        });
    }

    public rows: FormArray = this.fb.array([]);
    public form: FormGroup = this.fb.group({ menuItems: this.rows });
    public selection = new SelectionModel<MenuItem>(true, []);

    constructor(private fb: FormBuilder, private dialog: MatDialog) {}

    public ngOnInit(): void {
        this.form.valueChanges.subscribe(() => {
            if (this._data) {
                const menuSelection = this.rows.value.map(({ description, ...row }) => ({
                    description: description || undefined,
                    ...row
                }));
                this.menuOutput.emit({ menuSelection, isValidMenu: this.form.valid });
            }
        });

        this.selection.changed.subscribe(() => {
            if (this._data) {
                this.menuOutput.emit({
                    menuSelection: this.rows.value,
                    isValidMenu: this.form.valid
                });
            }
        });

        this._data.forEach(d => {
            this.addItem(d, false);
        });

        this.updateView();
    }

    public masterToggle(): void {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.rows.value.forEach(row => (row.enabled = false));
        } else {
            this.rows.value.forEach(row => {
                row.enabled = true;
                this.selection.select(row);
            });
        }
    }

    public isAllSelected(): boolean {
        return this.rows.value.every(row => row.enabled);
    }
    public isSomeSelected(): boolean {
        return this.rows.value.some(row => row.enabled);
    }

    public clear(index: number): void {
        this.rows.removeAt(index);
        this.updateView();
    }

    public updateView(): void {
        this.dataSource.next(this.rows.value);
    }

    public addItem(d?: MenuItem, noUpdate?: boolean): void {
        const row = this.fb.group({
            name: [d && d.name ? d.name : undefined, [Validators.required]],
            price: [d && d.price ? d.price : undefined, [Validators.required, Validators.pattern(/^[0-9]+(\.[0-9]{0,2})?$/)]],
            enabled: [d ? d.enabled : true, []],
            description: [d && d.description ? d.description : undefined]
        });
        this.rows.push(row);
        if (row.value.enabled) {
            this.selection.select(row.value);
        }
        if (!noUpdate) {
            this.updateView();
        }
    }

    public handleCheck(row: MenuItem, i: number): void {
        this.selection.toggle(row);
        this.rows.value[i].enabled = !this.rows.value[i].enabled;
    }

    public showOrderPreview(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { companyColour: this.companyColour, rows: this.rows.value };
        this.dialog.open(OrderPreviewDialogComponent, dialogConfig);
    }
}

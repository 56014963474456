import { Injectable } from '@angular/core';
import { Integration } from '@data/integration.model';
import { HttpClient } from '@angular/common/http';
import { HttpUrlGenerator, DefaultDataService } from '@ngrx/data';
import { AppConfigService } from '@services/app-config.service';
import { CompanyService } from '@services/company/company.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IntegrationDataService extends DefaultDataService<Integration> {
    private selectedCompany: string;

    private idSplitCharacter = '$';

    constructor(
        http: HttpClient,
        httpURlGenerator: HttpUrlGenerator,
        private appConfigService: AppConfigService,
        private companyService: CompanyService
    ) {
        super('integration', http, httpURlGenerator);
        combineLatest([this.companyService.selectedCompanyId$, this.appConfigService.APIGateway]).subscribe(([companyId, apiGateway]) => {
            this.selectedCompany = companyId;
            this.entityUrl = `${apiGateway}/companies/${companyId}/integrations/`;
        });
    }

    public getAll(): Observable<Integration[]> {
        return this.http.get<Integration[]>(this.entityUrl).pipe(
            map(integrations => {
                return integrations.map(integration => {
                    integration.companyId = this.selectedCompany;
                    integration.id = `${integration.companyId}${this.idSplitCharacter}${integration.id}`;
                    return integration;
                });
            })
        );
    }

    public add(integration: Integration): Observable<Integration> {
        const id = `${this.selectedCompany}${this.idSplitCharacter}${integration.id}`;
        const requestBody = {
            username: integration.username,
            password: integration.password
        };
        delete integration.username;
        delete integration.password;
        return this.http.post<Integration>(`${this.entityUrl}${integration.id}`, requestBody).pipe(
            map(response => {
                return {
                    ...integration,
                    id: id
                };
            })
        );
    }

    public delete(key: string): Observable<string | number> {
        const id = `${this.selectedCompany}${this.idSplitCharacter}${key}`;
        return super.delete(key).pipe(
            map(x => {
                return id;
            })
        );
    }
}

import { Injectable } from '@angular/core';
import { Device } from '@data/device.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';
import { DesktopAppConfig } from '@data/desktop-app-config.model';
import { Platforms } from '@data/enums/platforms.enum';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class DesktopAppService {
    private APIGateway = '';
    private endpoint = '/devices/desktop';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
    }

    public createDesktopApp(desktopAppConfig: DesktopAppConfig): Observable<Device> {
        return this.http.post<Device>(this.APIGateway + this.endpoint, desktopAppConfig);
    }

    public getDesktopAppDownloadUrl(deviceId: string, platform?: Platforms): Observable<string> {
        let httpParams = new HttpParams();
        httpParams = httpParams.set('deviceId', deviceId);
        if (platform) {
            httpParams = httpParams.set('platform', platform);
        }
        return this.http.get<{ url: string }>(`${this.APIGateway}/desktopApps/download`, { params: httpParams }).pipe(map(x => x.url));
    }
}

import { NgModule } from '@angular/core';
import { EntityCollectionReducerMethodsFactory, EntityCollectionReducerRegistry, EntityDataModule, EntityDataService } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CompanyDataService } from '@services/APIServices/company-data/company-data.service';
import { TagDataService } from '@services/APIServices/tag-data/tag-data.service';
import { environment } from 'src/environments/environment';
import { InstantTemplateDataService } from './../services/APIServices/instant-data/instant-template-data.service';
import { entityConfig } from './entity-metadata';
import { CompanyCollectionReducerFactory, CompanyCollectionReducerMethodsFactory } from './reducers/company.reducers';
import { PromotionEntityCollectionReducerMethodsFactory } from './reducers/promokio-entity-collection-reducer-methods';
import { TagCollectionReducerFactory, TagCollectionReducerMethodsFactory } from './reducers/tag.reducers';
import { CompanyMediaDataService } from '@services/APIServices/company-media-data/company-media-data.service';
import { IntegrationCollectionReducerFactory, IntegrationCollectionReducerMethodsFactory } from './reducers/integration.reducers';
import { IntegrationDataService } from '@services/APIServices/integration-data/integration-data.service';
import { CompanyTypeDataService } from '@services/APIServices/admin-data/company-type-data.service';
import { CompanyRolesDataService } from '@services/APIServices/admin-data/company-roles-data.service';

@NgModule({
    imports: [
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        environment.production ? [] : StoreDevtoolsModule.instrument(),
        EntityDataModule.forRoot(entityConfig)
    ],
    providers: [
        {
            provide: EntityCollectionReducerMethodsFactory,
            useClass: PromotionEntityCollectionReducerMethodsFactory
        },
        CompanyCollectionReducerFactory,
        CompanyCollectionReducerMethodsFactory,
        TagCollectionReducerFactory,
        TagCollectionReducerMethodsFactory,
        IntegrationCollectionReducerFactory,
        IntegrationCollectionReducerMethodsFactory
    ]
})
export class AppStoreModule {
    constructor(
        entityDataService: EntityDataService,
        entityCollectionReducerRegistry: EntityCollectionReducerRegistry,
        companyDataService: CompanyDataService,
        tagDataService: TagDataService,
        instantTemplateDataService: InstantTemplateDataService,
        companyCollectionReducerFactory: CompanyCollectionReducerFactory,
        tagCollectionReducerFactory: TagCollectionReducerFactory,
        companyMediaDataService: CompanyMediaDataService,
        integrationDataService: IntegrationDataService,
        integrationCollectionReducerFactory: IntegrationCollectionReducerFactory,
        companyTypeDataService: CompanyTypeDataService,
        companyRolesDataService: CompanyRolesDataService
    ) {
        const companyReducers = companyCollectionReducerFactory.create();
        const integrationReducers = integrationCollectionReducerFactory.create();
        const tagReducers = tagCollectionReducerFactory.create();
        entityDataService.registerService('Company', companyDataService);
        entityDataService.registerService('InstantTemplate', instantTemplateDataService);
        entityDataService.registerService('Integration', integrationDataService);
        entityDataService.registerService('Media', companyMediaDataService);
        entityDataService.registerService('Tag', tagDataService);
        entityDataService.registerService('CompanyTypes', companyTypeDataService);
        entityDataService.registerService('CompanyRoles', companyRolesDataService);
        entityCollectionReducerRegistry.registerReducer('Company', companyReducers);
        entityCollectionReducerRegistry.registerReducer('Integration', integrationReducers);
        entityCollectionReducerRegistry.registerReducer('Tag', tagReducers);
    }
}

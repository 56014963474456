import { Injectable } from '@angular/core';
import { OverlayContainerComponent } from '@shared/overlay-container/overlay-container.component';
import { ComponentType } from '@angular/cdk/portal';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OverlayLoaderService<T> {
    private overlayContainer: OverlayContainerComponent<T>;

    constructor() {}

    public registerRootOverlay(overlayContainer: OverlayContainerComponent<T>): void {
        this.overlayContainer = overlayContainer;
    }

    public show(componentType: ComponentType<T>): T {
        return this.overlayContainer.show(componentType);
    }

    public hide(): void {
        return this.overlayContainer.hide();
    }

    get overlayClosedEvents$(): Observable<void> {
        return this.overlayContainer.overlayClosed.asObservable();
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    private spinning = new BehaviorSubject<boolean>(false);
    public spinning$ = this.spinning.asObservable();

    constructor() {}

    public show(): void {
        this.spinning.next(true);
    }

    public hide(): void {
        this.spinning.next(false);
    }
}

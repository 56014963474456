import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCaseToReadable'
})
export class CamelCaseToReadablePipe implements PipeTransform {
    public transform(value: string): string {
        return value.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

import { AuditRecord } from '../../data/audit.model';

@Component({
    selector: 'app-audit-details-dialog',
    templateUrl: './audit-details-dialog.component.html',
    styleUrls: ['./audit-details-dialog.component.css']
})
export class AuditDetailsDialogComponent implements OnInit {
    added: any[];
    updated: any[];
    deleted: any[];
    errorMessage: string;

    constructor(@Inject(MAT_DIALOG_DATA) data: AuditRecord) {
        const { diff, errorMessage } = data;
        const { added, deleted, updated } = diff ? diff : { added: {}, deleted: {}, updated: {} };
        this.added = Object.entries(added);
        this.deleted = Object.entries(deleted);
        this.updated = Object.entries(updated);

        this.errorMessage = errorMessage;
    }

    ngOnInit() {}
}

import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export class BaseComponent implements OnDestroy {
    protected onDestroy$ = new Subject<void>();

    constructor() {}

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

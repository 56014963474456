import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../../../services/cognito.service';
import { Router } from '@angular/router';
import { User } from '../../../data/user.model';

@Component({
    selector: 'app-verify-account',
    templateUrl: './verify-account.component.html',
    styleUrls: ['./verify-account.component.css']
})
export class VerifyAccountComponent implements OnInit {
    user: User;
    code: string;
    field1: string;
    field2: string;
    field3: string;
    field4: string;
    field5: string;
    field6: string;

    constructor(private cognitoService: CognitoService, private router: Router) {}

    ngOnInit() {
        if (!this.cognitoService.verifyMe) {
            this.router.navigate(['/register']);
        }
    }

    confirm() {
        this.code = this.field1 + this.field2 + this.field3 + this.field4 + this.field5 + this.field6;
        this.cognitoService.confirmAccount(this.code, this.user.username);
    }

    resendCode() {
        this.cognitoService.resendCode(this.user.username);
    }

    keypressHandler = (event, nextIdx) => {
        if (
            event.key === 1 ||
            event.key === 2 ||
            event.key === 3 ||
            event.key === 4 ||
            event.key === 5 ||
            event.key === 6 ||
            event.key === 7 ||
            event.key === 8 ||
            event.key === 9
        ) {
            document.getElementById('f_' + nextIdx).focus();
        }
    };
}

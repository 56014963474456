import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeGB from '@angular/common/locales/en-GB';
import { APP_INITIALIZER, ClassProvider, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AuthGuard } from '@guards/auth-guard/auth.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OrderPreviewDialogComponent } from '@shared/order-preview-dialog/order-preview-dialog.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ToastrModule } from 'ngx-toastr';
import { NgxUploaderModule } from 'ngx-uploader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthService } from './services/auth.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { LoggingInterceptorService } from './services/logging-interceptor.service';
import { WebsocketsService } from './services/websockets.service';
import { PromoPreviewDialogComponent } from './shared/promo-preview-dialog/promo-preview-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppStoreModule } from './stores/app-store.module';
import { AppConfigService } from '@services/app-config.service';
import { SharedModule } from '@shared/shared.module';
import { PagesModule } from '@pages/pages.module';
import { AdminModule } from '@pages/admin/admin.module';

registerLocaleData(localeGB, 'en-GB');

const LOGGING_INTERCEPTOR_PROVIDER: ClassProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: LoggingInterceptorService,
    multi: true
};

const AUTH_INTERCEPTOR_PROVIDER: ClassProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
};

// tslint:disable-next-line:no-any
const appInitializerFn = (appConfig: AppConfigService): any => {
    // tslint:disable-next-line:no-any
    return (): any => {
        return appConfig.activateObservable();
    };
};

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ColorPickerModule,
        CommonModule,
        CurrencyMaskModule,
        DragDropModule,
        FormsModule,
        HttpClientModule,
        Ng2ImgMaxModule,
        NgxDropzoneModule,
        NgxUploaderModule,
        PagesModule,
        ReactiveFormsModule,
        SharedModule,
        ToastrModule.forRoot({
            preventDuplicates: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AppStoreModule
    ],
    providers: [
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        },
        MatDatepickerModule,
        GoogleAnalyticsService,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        AuthGuard,
        AuthService,
        WebsocketsService,
        { provide: LOCALE_ID, useValue: 'en-GB' },
        AUTH_INTERCEPTOR_PROVIDER,
        LOGGING_INTERCEPTOR_PROVIDER
    ],
    bootstrap: [AppComponent],
    entryComponents: [OrderPreviewDialogComponent, PromoPreviewDialogComponent]
})
export class AppModule {}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '@data/company.model';
import { CompanyTypes } from '@data/enums/company-types.enum';
import { FileTypes } from '@data/enums/file-types.enum';
import { PromotionDescriptions, PromotionIcons, PromotionTypes } from '@data/enums/promotion-types.enum';
import { Group } from '@data/group.model';
import { MenuItem, Promotion } from '@data/promotion.model';
import { TableColumn } from '@data/table-column.model';
import { Tag } from '@data/tag.model';
import { AppConfigService } from '@services/app-config.service';
import { CompanyService } from '@services/company/company.service';
import { GroupsService } from '@services/groups.service';
import { MediaService } from '@services/media.service';
import { PromotionsService } from '@services/promotions.service';
import { SpinnerService } from '@services/spinner.service';
import { TagsService } from '@services/tags.service';
import { ColourUtils } from '@utils/colour.utils';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, EMPTY, Observable, of, Subject, merge, isObservable } from 'rxjs';
import { catchError, map, switchMap, startWith, filter, take, takeUntil, shareReplay } from 'rxjs/operators';
import { MediaEvent } from '@data/media-event.model';
import { Orientations } from '@data/enums/orientations.enum';
import { OverlayLoaderService } from '@services/overlay-loader/overlay-loader.service';
import { MediaSelectorComponent } from '@shared/media/media-selector/media-selector.component';
import { MediaUrls, Media } from '@data/media.model';
import { MediaDetails } from '@data/media-details.model';
import { Dimensions } from '@data/enums/dimensions.enum';
import { InstantTemplatesService } from '@services/instant-templates.service';
import { InstantTemplate, InstantTemplateResponse } from '@data/instant-template.model';
import { DataServiceError, EntityAction, EntityOp, ofEntityOp } from '@ngrx/data';
import { TranslateService } from '@ngx-translate/core';
import { Response } from '@data/response.model';

const ctaMaxLength = 30;
const defaultPromoDuration = 10;

const urlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
const withHttp = (url: string): string => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);

@Component({
    selector: 'promo-promotion-template',
    templateUrl: './promotion-template.component.html',
    styleUrls: ['./promotion-template.component.scss']
})
export class PromotionTemplateComponent implements OnInit, OnDestroy {
    public isInstantPromotion: boolean;

    public types = [
        {
            name: PromotionTypes.Image,
            desc: PromotionDescriptions.Image,
            icon: PromotionIcons.Image
        },
        {
            name: PromotionTypes.Video,
            desc: PromotionDescriptions.Video,
            icon: PromotionIcons.Video
        },
        {
            name: PromotionTypes.Order,
            desc: PromotionDescriptions.Order,
            icon: PromotionIcons.Order
        },
        {
            name: PromotionTypes.Bet,
            desc: PromotionDescriptions.Bet,
            icon: PromotionIcons.Bet
        },
        {
            name: PromotionTypes.URL,
            desc: PromotionDescriptions.URL,
            icon: PromotionIcons.URL
        },
        {
            name: PromotionTypes.JIRA,
            desc: PromotionDescriptions.JIRA,
            icon: PromotionIcons.JIRA,
            allowedCompanyTypes: [CompanyTypes.CoWorkingSpace]
        }
    ];
    public companyHasSwipingEnabled = false;
    public companyType: CompanyTypes;
    public companyTypes = CompanyTypes;
    public selectedType: string = undefined;
    public mediaType: string;
    public promotionalHeadlineTextColor = '#000000FF';
    public promotionalHeadlineBackgroundColor = '#FFFFFFFF';
    public callToActionTextColor = '#FFFFFFFF';
    public callToActionBackgroundColor = '#000000FF';
    public instantGroups: Group[];
    private selectedInstantGroups: string[];
    private portraitDuration = NaN;
    private landscapeDuration = NaN;
    public acceptedFileTypes: FileTypes[];

    public promotion: Promotion | InstantTemplate;

    public promotionForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        url: new FormControl(''),
        type: new FormControl('', [Validators.required]),
        portraitImage: new FormControl(''),
        landscapeImage: new FormControl(''),
        tag: new FormControl('', [Validators.required]),
        promotionHeadline: new FormControl(''),
        callToAction: new FormControl('', Validators.maxLength(ctaMaxLength)),
        startDate: new FormControl(''),
        endDate: new FormControl(''),
        lengthToStay: new FormControl(''),
        instantGroup: new FormControl(''),
        emailSubmissionEnabled: new FormControl(false),
        swipingEnabled: new FormControl(true),
        soundEnabled: new FormControl(false),
        companyFontEnabled: new FormControl(false),
        sendImmediately: new FormControl(false)
    });
    public name = this.promotionForm.get('name');
    public url = this.promotionForm.get('url');
    private type = this.promotionForm.get('type');
    public portraitImage = this.promotionForm.get('portraitImage');
    public landscapeImage = this.promotionForm.get('landscapeImage');
    public tag = this.promotionForm.get('tag');
    public promotionHeadline = this.promotionForm.get('promotionHeadline');
    public callToAction = this.promotionForm.get('callToAction');
    private startDate = this.promotionForm.get('startDate');
    private endDate = this.promotionForm.get('endDate');
    public lengthToStay = this.promotionForm.get('lengthToStay');
    public instantGroup = this.promotionForm.get('instantGroup');
    public emailSubmissionEnabled = this.promotionForm.get('emailSubmissionEnabled');
    public swipingEnabled = this.promotionForm.get('swipingEnabled');
    public soundEnabled = this.promotionForm.get('soundEnabled');
    public companyFontEnabled = this.promotionForm.get('companyFontEnabled');
    public sendImmediately = this.promotionForm.get('sendImmediately');
    private S3BucketImages = '';
    private fileType: string;
    private landscapeWidth: number;
    private landscapeHeight: number;
    private portraitWidth: number;
    private portraitHeight: number;
    public checkedType: string;

    public orderOptions: MenuItem[] = [];
    public menuAdded = false;
    public isInfinite = false;
    public displayedColumns: TableColumn[] = [
        { id: 'name', label: 'Menu Item', type: 'string' },
        { id: 'price', label: 'Price (£)', type: 'number' },
        { id: 'description', label: 'Description', type: 'string' }
    ];
    public selectedMenu: { menuSelection: MenuItem[]; isValidMenu: boolean };
    public companyColour: string;

    public isLoading = true;

    public company: Observable<Company>;

    private tags$: Observable<Tag[]>;
    public tagsToShow$: Observable<Tag[]>;

    private mediaIds$: Observable<string[]>;
    public mediaUrls$: Observable<MediaUrls>;

    private onDestroy$: Subject<void> = new Subject<void>();

    public orientations = Orientations;

    constructor(
        private promotionService: PromotionsService,
        private instantTemplatesService: InstantTemplatesService,
        private router: Router,
        private spinnerService: SpinnerService,
        private groupService: GroupsService,
        private appConfigService: AppConfigService,
        private mediaService: MediaService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private companyService: CompanyService,
        private tagsService: TagsService,
        private activatedRoute: ActivatedRoute,
        private overlayLoader: OverlayLoaderService<MediaSelectorComponent>
    ) {
        this.appConfigService.S3BucketImages.subscribe(x => (this.S3BucketImages = x));
        this.tags$ = this.tagsService.tags$;
        this.tagsToShow$ = combineLatest([this.tag.valueChanges.pipe(startWith(undefined)), this.tags$]).pipe(
            map(([valueChanged, tags]) => {
                if (valueChanged === undefined || !this.tag || !this.tag.value) {
                    return tags;
                }
                return tags.filter(tag => tag.tagName.toLowerCase().includes(this.tag.value.toLowerCase()));
            })
        );
        this.mediaIds$ = this.mediaService.mediaForSelectedCompany$;
    }

    public ngOnInit(): void {
        if (window.history.state.instantTemplate) {
            this.promotion = {} as InstantTemplate;
            this.resetForm();
            this.isInstantPromotion = window.history.state.instantTemplate;
            this.selectedType = PromotionTypes.Instant;
            this.setRequiredFields();
            this.promotionForm.get('type').setValue(this.selectedType);
            this.acceptedFileTypes = [FileTypes.JPEG, FileTypes.PNG];
        } else {
            this.promotion = {} as Promotion;
        }

        this.companyService.selectedCompany$.subscribe(company => {
            if (!company) {
                this.companyService.getAll();
            } else {
                const { swipingEnabled, type, colour } = company;
                this.companyHasSwipingEnabled = swipingEnabled;
                this.companyType = type;
                this.companyColour = colour;
                if (!swipingEnabled) {
                    this.swipingEnabled.setValue(false);
                }
            }
        });
        this.groupService
            .getGroups()
            .pipe(
                map(({ Items }) => {
                    this.instantGroups = Items;
                }),
                catchError(() => {
                    this.isLoading = false;
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.isLoading = false;
            });
        this.tagsService.getAll();
        this.mediaService.getByKey('media');
        this.mediaUrls$ = this.mediaIds$.pipe(
            filter(ids => !!(ids && ids.length)),
            switchMap(ids => {
                return this.mediaService.getUrlsForKeys(ids);
            })
        );
        this.activatedRoute.url.pipe(map(() => window.history.state)).subscribe(state => {
            if (state.type) {
                const typedState = state as MediaEvent;
                this.changeType({ checked: true } as MatCheckboxChange, typedState.type);
                this.checkedType = typedState.type;
                if (typedState.orientation === Orientations.Portrait || typedState.orientation === undefined) {
                    this.promotion.portraitImage = typedState.mediaUrl;
                    this.portraitImage.setValue(typedState.mediaUrl);
                }
                if (typedState.orientation === Orientations.Landscape || typedState.orientation === undefined) {
                    this.promotion.landscapeImage = typedState.mediaUrl;
                    this.landscapeImage.setValue(typedState.mediaUrl);
                }
            }
        });

        this.instantTemplatesService.entityActions$
            .pipe(
                ofEntityOp([EntityOp.SAVE_ADD_ONE_SUCCESS]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => {
                this.spinnerService.hide();
                const navigationPromise = this.router.navigate(['/instants']);
            });
        this.companyService.entityActions$
            .pipe(
                ofEntityOp([EntityOp.SAVE_ADD_ONE_ERROR]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(error => {
                this.spinnerService.hide();
                this.handleError(error);
            });
    }

    private handleError(error: EntityAction<DataServiceError>): void {
        const translationLocation = 'ErrorMessages.PromotionCreationFailed';
        this.translateService.get(translationLocation).subscribe(errorMessage => {
            this.toastrService.error(errorMessage);
        });
    }

    private resetDetails(): void {
        this.tag.reset();
        this.promotionHeadline.reset();
        this.callToAction.reset();
        this.startDate.reset();
        this.endDate.reset();
        this.lengthToStay.reset();
        this.landscapeImage.reset();
        this.portraitImage.reset();
        this.setDefaults();
    }

    private setRequiredFields(): void {
        if (this.selectedType === PromotionTypes.Image || this.selectedType === PromotionTypes.Video) {
            this.name.setValidators([Validators.required]);
            this.url.setValidators(undefined);
            this.tag.setValidators([Validators.required]);
            this.callToAction.setValidators([Validators.maxLength(ctaMaxLength)]);
            this.startDate.setValidators(undefined);
            this.endDate.setValidators(undefined);
            this.lengthToStay.setValidators([Validators.required, Validators.min(1)]);
            this.instantGroup.setValidators(undefined);
        } else if (this.selectedType === PromotionTypes.Order) {
            this.name.setValidators([Validators.required]);
            this.url.setValidators(undefined);
            this.portraitImage.setValidators(undefined);
            this.landscapeImage.setValidators(undefined);
            this.tag.setValidators([Validators.required]);
            this.callToAction.setValidators([Validators.required, Validators.maxLength(ctaMaxLength)]);
            this.startDate.setValidators(undefined);
            this.endDate.setValidators(undefined);
            this.lengthToStay.setValidators([Validators.required, Validators.min(1)]);
            this.instantGroup.setValidators(undefined);
        } else if (this.selectedType === PromotionTypes.Bet) {
            this.name.setValidators([Validators.required]);
            this.url.setValidators(undefined);
            this.portraitImage.setValidators(undefined);
            this.landscapeImage.setValidators(undefined);
            this.tag.setValidators([Validators.required]);
            this.callToAction.setValidators([Validators.required, Validators.maxLength(ctaMaxLength)]);
            this.startDate.setValidators(undefined);
            this.endDate.setValidators(undefined);
            this.lengthToStay.setValidators([Validators.required, Validators.min(1)]);
            this.instantGroup.setValidators(undefined);
        } else if (this.selectedType === PromotionTypes.Instant) {
            this.name.setValidators([Validators.required]);
            this.url.setValidators(undefined);
            this.portraitImage.setValidators(undefined);
            this.landscapeImage.setValidators(undefined);
            this.tag.setValidators([Validators.required]);
            this.promotionHeadline.setValidators([Validators.required]);
            this.callToAction.setValidators([Validators.maxLength(ctaMaxLength)]);
            this.startDate.setValidators([Validators.required]);
            this.endDate.setValidators([Validators.required]);
            this.lengthToStay.setValidators([Validators.required, Validators.min(1)]);
            this.instantGroup.setValidators([Validators.required]);
        } else if (this.selectedType === PromotionTypes.URL || this.selectedType === PromotionTypes.JIRA) {
            this.name.setValidators([Validators.required]);
            this.url.setValidators([Validators.required, Validators.pattern(urlRegex)]);
            this.portraitImage.setValidators(undefined);
            this.landscapeImage.setValidators(undefined);
            this.tag.setValidators([Validators.required]);
            this.promotionHeadline.setValidators(undefined);
            this.callToAction.setValidators(undefined);
            this.startDate.setValidators(undefined);
            this.endDate.setValidators([Validators.required]);
            this.lengthToStay.setValidators([Validators.required, Validators.min(1)]);
            this.instantGroup.setValidators(undefined);
        }
        this.instantGroup.updateValueAndValidity();
        this.name.updateValueAndValidity();
        this.portraitImage.updateValueAndValidity();
        this.url.updateValueAndValidity();
        this.landscapeImage.updateValueAndValidity();
        this.tag.updateValueAndValidity();
        this.promotionHeadline.updateValueAndValidity();
        this.callToAction.updateValueAndValidity();
        this.startDate.updateValueAndValidity();
        this.endDate.updateValueAndValidity();
        this.lengthToStay.updateValueAndValidity();
    }

    public changeType({ checked }: MatCheckboxChange, type: PromotionTypes): void {
        if (!this.selectedType) {
            this.resetForm();
        }
        this.selectedType = checked ? type : undefined;
        this.setRequiredFields();
        this.promotionForm.get('type').setValue(this.selectedType);
        this.acceptedFileTypes = type === PromotionTypes.Video ? [FileTypes.MP4] : [FileTypes.JPEG, FileTypes.PNG];
    }

    private resetForm(): void {
        this.resetDetails();
    }

    public onSubmit(): void {
        this.setPromotionValues();
        this.submitPromotion();
    }

    private submitPromotion(): void {
        if (
            this.checkFileTypes(this.portraitImage.value, this.landscapeImage.value) &&
            this.checkDurations(this.portraitDuration, this.landscapeDuration) &&
            this.checkAspectRatio()
        ) {
            this.spinnerService.show();
            const portraitUpload =
                !this.portraitImage.value || typeof this.portraitImage.value === 'string'
                    ? of(undefined)
                    : this.mediaService.uploadMedia(this.portraitImage.value);
            const landscapeUpload =
                !this.landscapeImage.value || typeof this.landscapeImage.value === 'string'
                    ? of(undefined)
                    : this.mediaService.uploadMedia(this.landscapeImage.value);

            portraitUpload
                .pipe(
                    switchMap(portraitId => {
                        if (portraitId) {
                            this.promotion.portraitImage = `https://${this.S3BucketImages}.s3-eu-west-1.amazonaws.com/${portraitId}`;
                        }
                        return landscapeUpload;
                    }),
                    switchMap(landscapeId => {
                        if (landscapeId) {
                            this.promotion.landscapeImage = `https://${this.S3BucketImages}.s3-eu-west-1.amazonaws.com/${landscapeId}`;
                        }
                        return (this.type.value === PromotionTypes.Instant
                            ? this.instantTemplatesService.createInstantTemplate(this.promotion as InstantTemplate)
                            : this.promotionService.createPromotion(this.promotion as Promotion)) as Observable<
                            InstantTemplateResponse | Response<Promotion>
                        >;
                    }),
                    catchError(() => {
                        this.spinnerService.hide();
                        return EMPTY;
                    })
                )
                .subscribe(() => {
                    if (!this.isInstantPromotion) {
                        this.spinnerService.hide();
                        const navigationPromise = this.router.navigate(['/promotions']);
                    }
                });
        }
    }

    private setDefaults(): void {
        this.lengthToStay.setValue(defaultPromoDuration);
        this.startDate.setValue(new Date());
        this.endDate.setValue(new Date());
        this.callToAction.setValue('');
        this.promotionHeadline.setValue('');
    }

    // tslint:disable-next-line: no-any
    public changeGroup(event: any): void {
        this.selectedInstantGroups = event.value.map((group: Group) => group.id);
    }

    private setPromotionValues(): void {
        this.promotion = {
            ...this.promotion,
            name: this.name.value,
            date: String(new Date(Date.now())),
            advertText: this.promotionHeadline.value ? this.promotionHeadline.value : ' ',
            backgroundColor: ColourUtils.convertFromRGBAtoARGB(this.promotionalHeadlineBackgroundColor),
            textColor: ColourUtils.convertFromRGBAtoARGB(this.promotionalHeadlineTextColor),
            ctaButtons: this.callToAction.value
                ? [
                      {
                          label: 'cta_order_now',
                          id: this.callToAction.value
                      }
                  ]
                : [],
            ctaTextColor: ColourUtils.convertFromRGBAtoARGB(this.callToActionTextColor),
            ctaButtonColor: ColourUtils.convertFromRGBAtoARGB(this.callToActionBackgroundColor),
            email: 'example@email.com',
            schedule: '1',
            userId: localStorage.getItem('userEmail'),
            duration: this.isInfinite ? undefined : this.lengthToStay.value || defaultPromoDuration,
            tag: this.tag.value,
            startDate: this.startDate.value.toString(),
            endDate: this.endDate.value.toString(),
            type: this.type.value,
            groups: this.type.value === PromotionTypes.Instant ? this.selectedInstantGroups : undefined,
            fileType: this.fileType,
            emailSubmissionEnabled: this.selectedMenu ? false : this.emailSubmissionEnabled.value,
            orderOptions:
                this.selectedType === PromotionTypes.Order ? (this.selectedMenu ? this.selectedMenu.menuSelection : undefined) : undefined,
            swipingEnabled: this.swipingEnabled.value,
            url:
                this.selectedType === PromotionTypes.URL || this.selectedType === PromotionTypes.JIRA
                    ? withHttp(this.url.value)
                    : undefined,
            soundEnabled: this.type.value === PromotionTypes.Instant ? this.soundEnabled.value : undefined,
            companyFontEnabled: this.type.value === PromotionTypes.Instant ? this.companyFontEnabled.value : undefined,
            sendImmediately: this.type.value === PromotionTypes.Instant ? this.sendImmediately.value : undefined
        };
    }

    public checkFileTypes(portraitFile: File, landscapeFile: File): boolean {
        if (portraitFile && landscapeFile) {
            if (portraitFile.type !== landscapeFile.type) {
                this.toastrService.error('Portrait and landscape media must be the same type', 'Oops!');
                return false;
            } else {
                this.fileType = portraitFile.type;
                this.promotion.fileType = this.fileType;
                return true;
            }
        } else {
            this.promotion.fileType = portraitFile ? portraitFile.type : landscapeFile ? landscapeFile.type : undefined;
            return true;
        }
    }

    public checkDurations(portraitDuration: number, landscapeDuration: number): boolean {
        if (!Number.isNaN(portraitDuration) && !Number.isNaN(landscapeDuration)) {
            if (portraitDuration !== landscapeDuration) {
                this.toastrService.error('Portrait and landscape media durations must match', 'Oops!');
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    public setDuration(duration: number, orientation: Orientations): void {
        const roundedDuration = Math.round(duration);
        this.lengthToStay.setValue(roundedDuration);
        if (orientation === Orientations.Portrait) {
            this.portraitDuration = roundedDuration;
        } else {
            this.landscapeDuration = roundedDuration;
        }
        this.lengthToStay.disable();
        if (
            (!this.landscapeDuration || Number.isNaN(this.landscapeDuration)) &&
            (!this.portraitDuration || Number.isNaN(this.portraitDuration))
        ) {
            this.lengthToStay.enable();
        }
        if (Number.isNaN(this.landscapeDuration) && !Number.isNaN(this.portraitDuration)) {
            this.lengthToStay.setValue(this.portraitDuration);
        }
        if (Number.isNaN(this.portraitDuration) && !Number.isNaN(this.landscapeDuration)) {
            this.lengthToStay.setValue(this.landscapeDuration);
        }
    }

    public setAspectRatio(size: number, orientation: Orientations, dimensions: Dimensions): void {
        if (orientation === Orientations.Portrait) {
            if (dimensions === Dimensions.Height) {
                this.portraitHeight = size;
            } else {
                this.portraitWidth = size;
            }
        } else {
            if (dimensions === Dimensions.Height) {
                this.landscapeHeight = size;
            } else {
                this.landscapeWidth = size;
            }
        }
    }

    public checkAspectRatio(): boolean {
        if (this.portraitWidth > this.portraitHeight || this.landscapeHeight > this.landscapeWidth) {
            this.toastrService.error('Portrait height must be greater than width and landscape width must be greater than height', 'Oops!');
            return false;
        } else {
            return true;
        }
    }

    public toggleMenu(): void {
        this.menuAdded = !this.menuAdded;
    }

    public toggleInfiniteDuration(): void {
        this.isInfinite = !this.isInfinite;
        this.isInfinite ? this.lengthToStay.disable() : this.lengthToStay.enable();
    }

    public getDisplayedColumns(): string[] {
        return this.displayedColumns.map(x => x.id);
    }

    public menuSelectionChange(event: { menuSelection: MenuItem[]; isValidMenu: boolean }): void {
        this.selectedMenu = event;
    }

    public isRequired(formControl: AbstractControl): boolean {
        if (!formControl.validator) {
            return false;
        }
        const validator = formControl.validator({} as AbstractControl);
        return validator && validator.required;
    }

    public removeMedia(orientation: string): void {
        if (orientation === 'portrait') {
            this.promotion.portraitImage = undefined;
        } else {
            this.promotion.landscapeImage = undefined;
        }
    }

    public openMediaSelectorModal(orientation: Orientations): void {
        const mediaSelector = this.overlayLoader.show(MediaSelectorComponent);
        mediaSelector.mediaUrls$ = this.mediaUrls$;
        mediaSelector.acceptedFileTypes = this.acceptedFileTypes;

        const mediaSelectorClosedSub = mediaSelector.modalClosed
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe((event: MediaDetails | undefined) => {
                if (event) {
                    if (orientation === Orientations.Portrait) {
                        this.promotion.portraitImage = event.url;
                        this.portraitImage.setValue(event.url);
                    } else {
                        this.promotion.landscapeImage = event.url;
                        this.landscapeImage.setValue(event.url);
                    }
                    this.setDuration(event.duration, orientation);
                    if (!event.duration) {
                        this.lengthToStay.setValue(defaultPromoDuration);
                    }
                    this.setAspectRatio(event.resolution.height, orientation, Dimensions.Height);
                    this.setAspectRatio(event.resolution.width, orientation, Dimensions.Width);
                }
                this.overlayLoader.hide();
            });

        this.overlayLoader.overlayClosedEvents$
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => mediaSelectorClosedSub.unsubscribe());
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';

@Component({
    selector: 'app-unauthorised',
    templateUrl: './unauthorised.component.html',
    styleUrls: ['./unauthorised.component.css']
})
export class UnauthorisedComponent implements OnInit {
    constructor(private spinnerService: SpinnerService) {}

    ngOnInit() {
        this.spinnerService.hide();
    }
}

import { NgModule } from '@angular/core';
import { UsersComponent } from './users.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ViewUsersComponent } from './view-users/view-users.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './create-user/change-password/change-password.component';

@NgModule({
    declarations: [UsersComponent, ViewUsersComponent, CreateUserComponent, ChangePasswordComponent],
    imports: [SharedModule, RouterModule, CommonModule, FormsModule, ReactiveFormsModule]
})
export class UsersModule {}

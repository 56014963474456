import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MediaUrls } from '@data/media.model';
import { ModalComponent } from '@data/modal/modal.component';
import { FileTypes } from '@data/enums/file-types.enum';
import { MediaService } from '@services/media.service';
import { take, takeUntil } from 'rxjs/operators';
import { SpinnerService } from '@services/spinner.service';
import { MediaDetails } from '@data/media-details.model';
import { Dimensions } from '@data/enums/dimensions.enum';

@Component({
    selector: 'promo-media-selector',
    templateUrl: './media-selector.component.html',
    styleUrls: ['./media-selector.component.scss']
})
export class MediaSelectorComponent extends ModalComponent<MediaDetails | undefined> implements OnInit, OnDestroy {
    @Input()
    public mediaUrls$: Observable<MediaUrls>;
    @Input()
    public acceptedFileTypes: FileTypes[];

    private onDestroy$: Subject<void> = new Subject<void>();

    public selectedMediaDetails: MediaDetails;
    public dimensions = Dimensions;

    constructor(private mediaService: MediaService, private spinnerService: SpinnerService) {
        super();
    }

    public ngOnInit(): void {
        this.selectedMediaDetails = {
            resolution: {
                height: 0,
                width: 0
            }
        };
        this.mediaService.loading$.pipe(takeUntil(this.onDestroy$)).subscribe(loading => {
            loading ? this.spinnerService.show() : this.spinnerService.hide();
        });
    }

    public fileSelected(file: File): void {
        this.mediaService.uploadMedia(file).subscribe(details => {
            this.selectedMediaDetails.url = details.url;
            this.selectedMediaDetails.name = details.name;
            this.mediaService.getByKey('media');
        });
    }

    public selectMedia(media: MediaDetails): void {
        this.selectedMediaDetails = media;
    }

    public useImageForPromotion(): void {
        this.mediaUrls$.pipe(take(1)).subscribe(urls => {
            this.closeModal({
                ...this.selectedMediaDetails,
                url: this.selectedMediaDetails.url.split('?')[0]
            });
        });
    }

    public setDimensions(size: number, dimension: Dimensions): void {
        if (dimension === Dimensions.Height) {
            this.selectedMediaDetails.resolution.height = size;
        } else {
            this.selectedMediaDetails.resolution.width = size;
        }
    }

    public setDuration(duration: number): void {
        console.log(duration);
        this.selectedMediaDetails.duration = duration;
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Company } from '@data/company.model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';
import { Response } from '@data/response.model';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';

@Injectable({
    providedIn: 'root'
})
export class CompanyDataService extends DefaultDataService<Company> {
    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private appConfigService: AppConfigService) {
        super('Company', http, httpUrlGenerator);
        this.appConfigService.APIGateway.subscribe(x => {
            this.entityUrl = `${x}/companies/`;
            this.entitiesUrl = this.entityUrl;
        });
    }

    public getById(id: string): Observable<Company> {
        const resultObservable = (super.getById(id) as unknown) as Observable<Response<Company>>;
        return resultObservable.pipe(
            map(companyResponse => {
                if (companyResponse.Items && companyResponse.Items[0]) {
                    return companyResponse.Items[0];
                } else {
                    return;
                }
            })
        );
    }

    public getAll(): Observable<Company[]> {
        const resultObservable = (super.getAll() as unknown) as Observable<Response<Company>>;
        return resultObservable.pipe(map(companyResponse => companyResponse.Items));
    }

    public update(company: Update<Company>): Observable<Company> {
        const body = { ...company.changes };
        delete body.id;
        return this.http.put<Company>(`${this.entityUrl}${company.id}`, body);
    }
}

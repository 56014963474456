import { DeviceGroupsComponent } from './device-groups.component';
import { CreateGroupComponent } from './create-group/create-group.component';
import { ViewGroupComponent } from './view-group/view-group.component';

export const groupsRoutes = [
    {
        path: '',
        component: DeviceGroupsComponent
    },
    {
        path: 'create',
        component: CreateGroupComponent
    },
    {
        path: ':id',
        component: ViewGroupComponent
    },
    {
        path: ':id/edit',
        component: CreateGroupComponent
    }
];

import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityAction, OP_ERROR } from '@ngrx/data';
import { filter } from 'rxjs/operators';

export class PromokioEntityCollectionServiceBase<T> extends EntityCollectionServiceBase<T> {
    constructor(public readonly entityName: string, serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super(entityName, serviceElementsFactory);

        this.errors$ = this.entityActions$.pipe(
            filter((ea: EntityAction) => ea.payload && ea.payload.entityOp && ea.payload.entityOp.endsWith(OP_ERROR))
        );
    }
}

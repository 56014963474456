export class Permissions {
    public id?: string;
    public permissions: string[];
    public name: string;

    constructor(id?: string, permissions?: string[], name?: string) {
        this.id = id || undefined;
        this.permissions = permissions || [];
        this.name = name || '';
    }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

import { Promotion } from '@data/promotion.model';

@Component({
    selector: 'app-promo-preview-dialog',
    templateUrl: './promo-preview-dialog.component.html',
    styleUrls: ['./promo-preview-dialog.component.css']
})
export class PromoPreviewDialogComponent {
    promotion: Promotion;
    constructor(@Inject(MAT_DIALOG_DATA) data) {
        this.promotion = data;
    }

    convertFromAndroidColour(color: string): string {
        return color.substr(0, 1) + color.substr(3, 6) + color.substr(1, 2);
    }
}

import { Component, OnInit } from '@angular/core';
import { DevicesService } from 'src/app/services/devices.service';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Device } from '@data/device.model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { GroupsService } from 'src/app/services/groups.service';
import { empty } from 'rxjs';

@Component({
    selector: 'promo-view-device',
    templateUrl: './view-device.component.html',
    styleUrls: ['./view-device.component.css']
})
export class ViewDeviceComponent implements OnInit {
    private deviceId: string;
    public device: Device;

    constructor(
        private devicesService: DevicesService,
        private router: ActivatedRoute,
        private spinnerService: SpinnerService,
        private groupsService: GroupsService
    ) {}

    public ngOnInit(): void {
        this.spinnerService.show();
        this.router.paramMap
            .pipe(
                map(params => {
                    return params.get('id');
                }),
                switchMap(deviceId => {
                    return this.devicesService.getDevice(deviceId);
                }),
                catchError(err => {
                    this.spinnerService.hide();
                    return empty();
                })
            )
            .subscribe(response => {
                const device = response.Items[0];
                if (device) {
                    this.groupsService.getGroups().subscribe(groups => {
                        const matchingGroup = groups.Items.filter(group => group.id === device.group);
                        if (matchingGroup.length) {
                            device.group = matchingGroup[0].name;
                        }
                        this.device = device;
                    });
                }

                this.spinnerService.hide();
            });
    }
}

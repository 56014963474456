import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Media } from '../data/media.model';
import { Observable, of, combineLatest, forkJoin } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';
import { PromokioEntityCollectionServiceBase } from '@stores/services/promokio-entity-collection-service-base';
import { EntityCollectionServiceElementsFactory, ofEntityOp, EntityOp, EntityAction } from '@ngrx/data';
import { PromokioEntityOperations } from '@stores/operations/promokio-entity-operations.enum';
import { CompanyMedia } from '@data/company-media.model';
import { CompanyService } from './company/company.service';

@Injectable({
    providedIn: 'root'
})
export class MediaService extends PromokioEntityCollectionServiceBase<Media> {
    private APIGateway = '';
    private endpoint = '/media';

    public mediaForSelectedCompany$: Observable<string[]>;

    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient,
        private appConfigService: AppConfigService,
        private companyService: CompanyService
    ) {
        super('Media', serviceElementsFactory);
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
        this.mediaForSelectedCompany$ = combineLatest([this.entities$, this.companyService.selectedCompanyId$]).pipe(
            map(([companyMedia, selectedCompanyId]: [CompanyMedia[], string]) => {
                const mediaObject = companyMedia.find(x => x.id === selectedCompanyId);
                if (mediaObject) {
                    return mediaObject.media;
                } else {
                    return undefined;
                }
            })
        );
    }

    private getMediaUploadURL(type: string): Observable<Media> {
        return this.http.get<Media>(this.APIGateway + this.endpoint, {
            params: { fileType: type }
        });
    }

    // tslint:disable-next-line:no-any
    public uploadMedia(media: any, fileType?: string): Observable<Media> {
        const headers = new HttpHeaders({
            'Content-Type': fileType || media.type
        });
        return this.getMediaUploadURL(fileType || media.type).pipe(
            switchMap((mediaUrl: Media) => {
                return this.http
                    .put<void>(mediaUrl.url, media, {
                        headers,
                        reportProgress: true
                    })
                    .pipe(map(x => mediaUrl));
            }),
            switchMap((uploadDetails: Media) => {
                uploadDetails.url = uploadDetails.url.split('?', 1)[0];
                return of(uploadDetails);
            })
        );
    }

    public getUrlsForKeys(keys: string[]): Observable<{ [key: string]: string }> {
        const chunkSize = 20;
        const listOfListsOfKeys = [];

        const url = `${this.APIGateway}/media/files`;

        for (let i = 0; i < keys.length; i += chunkSize) {
            const chunk = keys.slice(i, chunkSize + i);
            listOfListsOfKeys.push(chunk);
        }

        const listOfRequests = listOfListsOfKeys.map(list =>
            this.http
                .get<{ urls: { [key: string]: string } }>(url, { params: new HttpParams().set('keys', JSON.stringify(list)) })
                .pipe(map(response => response.urls))
        );

        return forkJoin(listOfRequests).pipe(
            map(item =>
                item.reduce((resultSoFar, requestResult) => {
                    return Object.assign(resultSoFar, requestResult);
                }, {})
            )
        );
    }
}

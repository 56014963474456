import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SelectedPermissionsService {
    private permissions: string[] = [];
    private originalSelectedPermissions: string[] = [];

    private selectedPermissionsSubject = new BehaviorSubject<{ [permissionId: string]: boolean }>({});
    public selectedPermissions$ = this.selectedPermissionsSubject.asObservable();
    public selectedPermissionIds$: Observable<string[]>;
    public permissionsHasChanged$: Observable<boolean>;

    constructor() {
        this.selectedPermissionIds$ = this.selectedPermissions$.pipe(
            map(selectedPermissions => {
                return Object.keys(selectedPermissions).filter(permissionId => {
                    return selectedPermissions[permissionId] === true;
                });
            })
        );

        this.permissionsHasChanged$ = this.selectedPermissionIds$.pipe(
            map(selectedPermissionsIds => {
                if (this.originalSelectedPermissions.length !== selectedPermissionsIds.length) {
                    return true;
                }
                const sortedOriginal = this.originalSelectedPermissions.sort();
                const sortedSelected = selectedPermissionsIds.sort();
                return !sortedOriginal.every((x, index) => {
                    return x === sortedSelected[index];
                });
            })
        );
    }

    public setAllPermissions(permissions: string[]): void {
        this.permissions = permissions;
    }

    public setPermission(id: string, value: boolean): void {
        const selectedPermissions = this.selectedPermissionsSubject.getValue();
        const newSelectedPermissions = {
            ...selectedPermissions,
            [id]: value
        };
        this.selectedPermissionsSubject.next(newSelectedPermissions);
    }

    public setMultiplePermissions(ids: string[], value: boolean): void {
        const selectedPermissions = this.selectedPermissionsSubject.getValue();
        const changedPermissions = ids.reduce((permissions, id) => {
            return {
                ...permissions,
                [id]: value
            };
        }, {});
        const newSelectedPermissions = {
            ...selectedPermissions,
            ...changedPermissions
        };
        this.selectedPermissionsSubject.next(newSelectedPermissions);
    }

    public resetPermissions(ids: string[]): void {
        this.originalSelectedPermissions = ids;
        const newSelectedPermissions = ids.reduce((permissions, id) => {
            return {
                ...permissions,
                [id]: true
            };
        }, {});
        this.selectedPermissionsSubject.next(newSelectedPermissions);
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth-guard/auth.guard';
import { authRoutes } from '@pages/auth/auth.routing';
import { companiesRoutes } from '@pages/companies/companies.routing';
import { groupsRoutes } from '@pages/device-groups/groups.routing';
import { devicesRoutes } from '@pages/devices/devices.routing';
import { instantsRoutes } from '@pages/instants/instants-library.routing';
import { promotionRoutes } from '@pages/promotion-library/promotion.routing';
import { playlistsRoutes } from '@pages/promotion-playlists/playlists.routing';
import { usersRoutes } from '@pages/users/users.routing';
import { AuditingComponent } from './pages/auditing/auditing.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { PromotionInteractionsComponent } from './pages/promotion-interactions/promotion-interactions.component';
import { UnauthorisedComponent } from './shared/unauthorised/unauthorised.component';
import { mediaRoutes } from '@pages/media/media.routing';
import { integrationRoutes } from '@pages/integrations/integrations.routing';
import { desktopAppRoutes } from '@pages/devices/desktop-app.routing';
import { downloadRoutes } from '@pages/download/download.routing';
import { adminRoutes } from '@pages/admin/admin.routing';

const routes: Routes = [
    {
        path: 'unauthorised',
        component: UnauthorisedComponent
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    {
        path: '',
        children: authRoutes
    },
    {
        path: 'interactions',
        component: PromotionInteractionsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'auditing',
        component: AuditingComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'companies',
        canActivate: [AuthGuard],
        children: companiesRoutes
    },
    {
        path: 'devices',
        canActivate: [AuthGuard],
        children: devicesRoutes,
        data: {
            isDesktop: false
        }
    },
    {
        path: 'groups',
        canActivate: [AuthGuard],
        children: groupsRoutes
    },
    {
        path: 'playlists',
        canActivate: [AuthGuard],
        children: playlistsRoutes
    },
    {
        path: 'instants',
        canActivate: [AuthGuard],
        children: instantsRoutes
    },
    {
        path: 'promotions',
        canActivate: [AuthGuard],
        children: promotionRoutes
    },
    {
        path: 'users',
        canActivate: [AuthGuard],
        children: usersRoutes
    },
    {
        path: 'media',
        canActivate: [AuthGuard],
        children: mediaRoutes
    },
    {
        path: 'integrations',
        canActivate: [AuthGuard],
        children: integrationRoutes
    },
    {
        path: 'desktop-apps',
        canActivate: [AuthGuard],
        children: desktopAppRoutes,
        data: {
            isDesktop: true
        }
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        children: adminRoutes
    },
    {
        path: 'download',
        children: downloadRoutes
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { SpinnerService } from '@services/spinner.service';

@Component({
    selector: 'promo-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
    public spinning$: Observable<boolean>;

    constructor(private spinnerService: SpinnerService) {}

    public ngOnInit(): void {
        this.spinning$ = this.spinnerService.spinning$;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface AppConfig {
    APIGateway: string;
    WebSocket: string;
    S3BucketImages: string;
    environment: string;
    PoolData: PoolData;
    interactionWaitTimerRefreshRate: number;
    defaultInteractionWaitExpireTime: number;
}

interface PoolData {
    UserPoolId: string;
    ClientId: string;
}

@Injectable()
export class AppConfigService {
    // tslint:disable-next-line:no-any
    private appConfig: Observable<AppConfig>;

    constructor(private http: HttpClient) {
        const loadPromise = this.loadAppConfig();
    }

    public activateObservable(): void {
        this.appConfig.subscribe();
    }

    public async loadAppConfig(): Promise<void> {
        this.appConfig = this.http.get<AppConfig>('../../assets/variables.json').pipe(shareReplay(1));
    }

    get APIGateway(): Observable<string> {
        return this.appConfig.pipe(map(config => config.APIGateway));
    }

    get WebSocket(): Observable<string> {
        return this.appConfig.pipe(map(config => config.WebSocket));
    }

    get S3BucketImages(): Observable<string> {
        return this.appConfig.pipe(map(config => config.S3BucketImages));
    }

    get environment(): Observable<string> {
        return this.appConfig.pipe(map(config => config.environment));
    }

    get poolData(): Observable<PoolData> {
        return this.appConfig.pipe(map(config => config.PoolData));
    }

    get InteractionWaitTimerRefreshRate(): Observable<number> {
        return this.appConfig.pipe(map(config => config.interactionWaitTimerRefreshRate));
    }

    get DefaultInteractionWaitExpireTime(): Observable<number> {
        return this.appConfig.pipe(map(config => config.defaultInteractionWaitExpireTime));
    }
}

import { Component } from '@angular/core';
import { ModalComponent } from '@data/modal/modal.component';

@Component({
    selector: 'promo-media-upload-dialog',
    templateUrl: './media-upload-dialog.component.html',
    styleUrls: ['./media-upload-dialog.component.scss']
})
export class MediaUploadDialogComponent extends ModalComponent<File | void> {
    public media: File;

    constructor() {
        super();
    }

    public onFileUpload(media: File): void {
        this.media = media;
    }

    public upload(): void {
        this.modalClosed.emit(this.media);
    }
}

import { DevicesComponent } from './devices.component';
import { CreateDeviceComponent } from './create-device/create-device.component';
import { ViewDeviceComponent } from './view-device/view-device.component';

export const devicesRoutes = [
    {
        path: '',
        component: DevicesComponent
    },
    {
        path: 'create',
        component: CreateDeviceComponent
    },
    {
        path: ':id',
        component: ViewDeviceComponent
    },
    {
        path: ':id/edit',
        component: CreateDeviceComponent
    }
];

import { Component, OnInit } from '@angular/core';
import { UsersService } from '@services/users.service';
import { SpinnerService } from '@services/spinner.service';
import { User } from '@data/user.model';
import { Response } from '@data/response.model';
import { catchError } from 'rxjs/operators';
import { empty } from 'rxjs';

@Component({
    selector: 'promo-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
    public users: Response<User[]>;
    public displayedColumns: string[] = ['name', 'email', 'roles', 'view'];

    public isLoading = true;

    constructor(private usersService: UsersService, private spinnerService: SpinnerService) {}

    public ngOnInit(): void {
        this.spinnerService.show();
        this.usersService
            .getUsers()
            .pipe(
                catchError(err => {
                    this.spinnerService.hide();
                    return empty();
                })
            )
            .subscribe(users => {
                this.users = users as Response<User[]>;
                this.isLoading = false;
                this.spinnerService.hide();
            });
    }
}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FileTypes } from '../../data/enums/file-types.enum';

@Component({
    selector: 'promo-drag-drop-upload',
    templateUrl: './drag-drop-upload.component.html',
    styleUrls: ['./drag-drop-upload.component.css']
})
export class DragDropUploadComponent {
    @Input() public acceptedFileTypes = [FileTypes.JPEG, FileTypes.PNG, FileTypes.MP4];
    @Input() public showRemoveButton = true;
    @Output() public selectedFile = new EventEmitter<File>();
    @Output() public selectedFileDuration = new EventEmitter<number>();
    @Output() public selectedFileHeight = new EventEmitter<number>();
    @Output() public selectedFileWidth = new EventEmitter<number>();
    @ViewChild('video') public video;
    @ViewChild('dropzone') public dropzone;
    public previewSource = '';
    public showPreviewPane = false;
    public maxFileSize = 104_857_600; // Maximum file size in bytes. Equal to 100MB
    public maxDimension = 1920; // Maximum height/width in pixels.
    public fileSelected = false;

    constructor(private toastrService: ToastrService) {}

    public onFilesRejected(file: File[]): void {
        let errorMessage = 'Something went wrong';

        if (file[0].size > this.maxFileSize) {
            const bytesInOneMb = 1048_576;
            errorMessage = `File size exceeds the maximum (${this.maxFileSize / bytesInOneMb}MB)`;
        }

        if (!this.acceptedFileTypes.includes(file[0].type as FileTypes)) {
            errorMessage = 'File type not accepted';
        }

        this.toastrService.error(errorMessage, 'Oops!');
    }

    public onFilesAdded(files: File[]): void {
        if (files.length > 0) {
            this.fileSelected = true;
            if (files[0].type === FileTypes.MP4) {
                const objectUrl = URL.createObjectURL(files[0]);
                this.video.nativeElement.setAttribute('src', objectUrl);
                this.showPreviewPane = true;
                this.video.nativeElement.play();
                const oneSecondInMs = 1000;
                setTimeout(() => {
                    const { videoHeight, videoWidth, duration } = this.video.nativeElement;
                    this.selectedFileHeight.emit(videoHeight);
                    this.selectedFileWidth.emit(videoWidth);
                    this.selectedFileDuration.emit(duration);
                    const videoMaxDimension = Math.max(videoHeight, videoWidth);
                    if (videoMaxDimension > this.maxDimension) {
                        this.toastrService.error('Max resolution is 1920 x 1080', 'Oops!');
                        this.reset();
                    }
                }, oneSecondInMs);
            }
            this.selectedFile.emit(files[0]);
        }
    }

    public reset(): void {
        this.fileSelected = false;
        this.dropzone.reset();
        this.video.nativeElement.setAttribute('src', '');
        this.showPreviewPane = false;
        this.selectedFile.emit(undefined);
        this.selectedFileHeight.emit(undefined);
        this.selectedFileWidth.emit(undefined);
        this.selectedFileDuration.emit(NaN);
    }
}

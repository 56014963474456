import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Playlist } from '@data/playlist.model';
import { Promotion } from '@data/promotion.model';
import { PromoPreviewDialogComponent } from '@shared/promo-preview-dialog/promo-preview-dialog.component';
import { EMPTY, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { PlaylistsService } from 'src/app/services/playlists.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import Swal from 'sweetalert2';
import { PlaylistPreviewComponent } from '../playlist-preview/playlist-preview.component';
import { OverlayLoaderService } from '@services/overlay-loader/overlay-loader.service';

@Component({
    selector: 'promo-view-playlist',
    templateUrl: './view-playlist.component.html',
    styleUrls: ['./view-playlist.component.scss']
})
export class ViewPlaylistComponent implements OnInit, OnDestroy {
    public playlist: Playlist;
    public playlistId: string;
    public displayedColumns: string[] = ['handle', 'name', 'mediaType', 'Date', 'userId', 'advertText', 'preview', 'archive'];
    @ViewChild(MatTable)
    private table: MatTable<string>;

    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private dialog: MatDialog,
        private playlistService: PlaylistsService,
        private route: ActivatedRoute,
        private router: Router,
        private spinnerService: SpinnerService,
        private overlayLoader: OverlayLoaderService<PlaylistPreviewComponent>
    ) {}

    public async ngOnInit(): Promise<void> {
        this.spinnerService.show();
        this.route.paramMap
            .pipe(
                map(params => {
                    this.playlistId = params.get('id');
                    return this.playlistId;
                }),
                switchMap(playlistId => {
                    return this.playlistService.getPlaylist(playlistId);
                }),
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(response => {
                this.playlist = response.Items[0];
                this.spinnerService.hide();
            });
    }

    public dropTable(event: CdkDragDrop<string[]>): void {
        this.spinnerService.show();
        const prevIndex = this.playlist.promotions.findIndex(d => d === event.item.data);
        moveItemInArray(this.playlist.promotions, prevIndex, event.currentIndex);
        this.table.renderRows();
        this.playlistService
            .updatePlaylist({
                id: this.playlist.id,
                name: this.playlist.name,
                groups: this.playlist.groups.map(x => x.id),
                tag: this.playlist.tag,
                promotions: this.playlist.promotions.map(x => x.id),
                isInfinite: this.playlist.isInfinite,
                loops: this.playlist.loops,
                startDate: this.playlist.startDate,
                endDate: this.playlist.endDate,
                swipingEnabled: this.playlist.swipingEnabled
            })
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.spinnerService.hide();
            });
    }

    public archive(index: number): void {
        const swalPromise = Swal.fire({
            title: 'Are you sure?',
            text: 'This will remove the promotion from the playlist',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove',
            cancelButtonText: 'No, cancel',
            imageUrl: '../../assets/swal2-warn.PNG',
            imageWidth: 100,
            imageHeight: 100
        }).then(result => {
            if (result.value) {
                const removedPromotion = this.playlist.promotions.splice(index, 1);
                this.playlist.promotions = [...this.playlist.promotions];
                this.playlistService
                    .updatePlaylist({
                        id: this.playlist.id,
                        name: this.playlist.name,
                        groups: this.playlist.groups.map(x => x.id),
                        tag: this.playlist.tag,
                        promotions: this.playlist.promotions.map(x => x.id),
                        isInfinite: this.playlist.isInfinite,
                        loops: this.playlist.loops,
                        startDate: this.playlist.startDate,
                        endDate: this.playlist.endDate,
                        swipingEnabled: this.playlist.swipingEnabled
                    })
                    .pipe(
                        catchError(() => {
                            this.spinnerService.hide();
                            this.playlist.promotions.splice(index, 0, removedPromotion[0]);
                            this.playlist.promotions = [...this.playlist.promotions];
                            return EMPTY;
                        })
                    )
                    .subscribe(() => {});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log('cancelled deletion');
            }
        });
    }

    public showPreview(item: Promotion): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = item;
        this.dialog.open(PromoPreviewDialogComponent, dialogConfig);
    }

    public deletePlaylist(): void {
        const swalPromise = Swal.fire({
            title: 'Are you sure you want to delete this playlist?',
            text: 'This action can’t be undone',
            showCancelButton: true,
            confirmButtonText: "<div id='playlist-delete-button-confirm'>Yes, delete</div>",
            cancelButtonText: "<div id='playlist-delete-button-cancel'>No, cancel</div>",
            imageUrl: '../../assets/swal2-warn.PNG',
            imageWidth: 100,
            imageHeight: 100
        }).then(({ value }) => {
            if (value) {
                this.playlistService
                    .deletePlaylist(this.playlistId)
                    .pipe(
                        catchError(() => {
                            this.spinnerService.hide();
                            return EMPTY;
                        })
                    )
                    .subscribe(() => {
                        const navigationPromise = this.router.navigate([`/playlists`]);
                    });
            } else {
                console.log('cancelled deletion');
            }
        });
    }

    public previewPlaylist(): void {
        const playlistPreview = this.overlayLoader.show(PlaylistPreviewComponent);
        playlistPreview.playlist = this.playlist;

        const previewClosedSub = playlistPreview.modalClosed
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.overlayLoader.hide());

        this.overlayLoader.overlayClosedEvents$
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => previewClosedSub.unsubscribe());
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

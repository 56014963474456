import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegrationsComponent } from './integrations.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [IntegrationsComponent],
    imports: [CommonModule, SharedModule, TranslateModule, FormsModule, ReactiveFormsModule]
})
export class IntegrationsModule {}

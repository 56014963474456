import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { EditPromotionComponent } from './edit-promotion/edit-promotion.component';
import { PromotionLibraryComponent } from './promotion-library.component';

@NgModule({
    declarations: [EditPromotionComponent, PromotionLibraryComponent],
    imports: [TranslateModule, SharedModule, ReactiveFormsModule, CommonModule, ColorPickerModule, RouterModule]
})
export class PromotionsModule {}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DesktopAppService } from '@services/APIServices/desktop-app/desktop-app.service';
import { Platforms } from '@data/enums/platforms.enum';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NavbarService } from '@services/navbar/navbar.service';

@Component({
    selector: 'promo-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit, OnDestroy {
    public platforms = Platforms;
    public deviceId: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private desktopAppService: DesktopAppService,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private navbarService: NavbarService
    ) {
        this.navbarService.hideNavbar();
    }

    public ngOnInit(): void {
        this.route.queryParamMap.subscribe(queryParams => {
            const deviceId = queryParams.get('deviceId');
            if (!deviceId) {
                return this.router.navigate(['/']);
            }
            this.deviceId = deviceId;
            this.getDesktopAppUrlAndOpen(deviceId);
        });
    }

    public getDesktopAppUrlAndOpen(deviceId: string, platform?: Platforms): void {
        this.desktopAppService
            .getDesktopAppDownloadUrl(deviceId, platform)
            .pipe(
                catchError(err => {
                    this.translateService.get('Download.Error').subscribe(translation => {
                        this.toastrService.error(translation);
                    });
                    return EMPTY;
                })
            )
            .subscribe(url => {
                window.open(url, '_blank');
            });
    }

    public ngOnDestroy(): void {
        this.navbarService.showNavbar();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Tag } from '@data/tag.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { AppConfigService } from '@services/app-config.service';
import { CompanyService } from '@services/company/company.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TagDataService extends DefaultDataService<Tag> {
    private selectedCompany: string;

    constructor(
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private appConfigService: AppConfigService,
        private companyService: CompanyService
    ) {
        super('Tag', http, httpUrlGenerator);
        this.companyService.selectedCompanyId$.subscribe(companyId => {
            this.selectedCompany = companyId;
            this.appConfigService.APIGateway.subscribe(APIGateway => {
                this.entityUrl = `${APIGateway}/companies/${this.selectedCompany}/tags`;
                this.entitiesUrl = this.entityUrl;
            });
        });
    }

    public getAll(): Observable<Tag[]> {
        return this.http.get<Tag[]>(this.entityUrl).pipe(
            map(tagArray => {
                return tagArray
                    .map(({ tagName, ...rest }) => ({ id: `${this.selectedCompany}$${tagName}`, tagName, ...rest }))
                    .sort((a, b) => {
                        return b.tagName.toLowerCase() < a.tagName.toLowerCase() ? 1 : -1;
                    });
            })
        );
    }
}

import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'promo-promotion-preview-panel',
    templateUrl: './preview-panel.component.html',
    styleUrls: ['./preview-panel.component.css']
})
export class PreviewPanelComponent implements OnInit {
    public portraitMediaURL: string;
    public landscapeMediaURL: string;
    @Input() public ctaText: string;
    @Input() public ctaTextColour: string;
    @Input() public ctaColour: string;
    @Input() public promotionHeadline: string;
    @Input() public promotionColour: string;
    @Input() public promotionTextColour: string;
    @Input()
    // tslint:disable-next-line:no-any
    set portraitMedia(portraitMedia: any) {
        if (portraitMedia) {
            try {
                this.portraitMediaURL = URL.createObjectURL(portraitMedia);
            } catch {
                this.portraitMediaURL = portraitMedia;
            }
        } else {
            this.portraitMediaURL = undefined;
        }
        this.resetMediaURL(0);
    }
    @Input()
    // tslint:disable-next-line:no-any
    set landscapeMedia(landscapeMedia: any) {
        if (landscapeMedia) {
            try {
                this.landscapeMediaURL = URL.createObjectURL(landscapeMedia);
            } catch {
                this.landscapeMediaURL = landscapeMedia;
            }
        } else {
            this.landscapeMediaURL = undefined;
        }
        this.resetMediaURL(1);
    }
    @ViewChild('portraitVideo') public portraitVideo;
    @ViewChild('landscapeVideo') public landscapeVideo;

    public mediaURL = this.portraitMediaURL;

    constructor() {}

    public ngOnInit(): void {
        this.resetMediaURL(0);
    }

    public resetMediaURL(index: number): void {
        let videoElement: HTMLVideoElement;
        if (!index) {
            this.mediaURL = this.portraitMediaURL;
            videoElement = this.portraitVideo.nativeElement;
        } else {
            this.mediaURL = this.landscapeMediaURL;
            videoElement = this.landscapeVideo.nativeElement;
        }
        videoElement.src = this.mediaURL;
        videoElement.muted = true;
        const playPromise = videoElement.play();
        if (playPromise !== undefined) {
            playPromise.then(() => {}).catch(() => {});
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DevicesService } from '@services/devices.service';
import { SpinnerService } from '@services/spinner.service';
import { Device } from '@data/device.model';
import { catchError, take, pluck, switchMap } from 'rxjs/operators';
import { empty, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'promo-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {
    public dataSource: MatTableDataSource<Device>;
    public devices: Device[];
    public displayedColumns: string[] = ['id', 'name', 'orientation', 'group', 'view'];

    public isLoading = true;

    public isDesktop$: Observable<boolean>;

    constructor(private devicesService: DevicesService, private spinnerService: SpinnerService, private route: ActivatedRoute) {}

    public ngOnInit(): void {
        this.spinnerService.show();
        this.isDesktop$ = this.route.data.pipe(
            take(1),
            pluck('isDesktop')
        );
        this.isDesktop$
            .pipe(
                switchMap((isDesktop: boolean) => {
                    return this.devicesService.getDevices(isDesktop).pipe(
                        catchError(err => {
                            this.spinnerService.hide();
                            this.isLoading = false;
                            return empty();
                        })
                    );
                })
            )
            .subscribe(({ Items }) => {
                this.devices = Items;
                this.dataSource = new MatTableDataSource(this.devices);
                this.isLoading = false;
                this.spinnerService.hide();
            });
    }

    public applyFilter(filterValue: string): void {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { CamelCaseToReadablePipe } from './camel-case-to-readable.pipe';

@NgModule({
    declarations: [SafePipe, CamelCaseToReadablePipe],
    imports: [CommonModule],
    exports: [SafePipe, CamelCaseToReadablePipe]
})
export class PipesModule {}

import { EventEmitter, Input } from '@angular/core';

export abstract class ModalComponent<OutputEvent> {
    @Input()
    public modalClosed = new EventEmitter<OutputEvent>();

    public closeModal(message?: OutputEvent): void {
        this.modalClosed.emit(message || undefined);
    }
}

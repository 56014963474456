import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { IdAndName } from '@data/id-and-name.model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CompanyRolesDataService extends DefaultDataService<IdAndName> {
    constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private appConfigService: AppConfigService) {
        super('CompanyRoles', http, httpUrlGenerator);
        this.appConfigService.APIGateway.subscribe(x => {
            this.entityUrl = `${x}/permissions/companyRoles/`;
            this.entitiesUrl = this.entityUrl;
        });
    }

    public getAll(): Observable<IdAndName[]> {
        const resultObservable = (super.getAll() as unknown) as Observable<IdAndName[]>;
        return resultObservable.pipe(map(response => response));
    }
}

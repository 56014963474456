import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { InstantTemplateResponse } from '@data/instant-template.model';
import { SpinnerService } from '@services/spinner.service';
import { Router } from '@angular/router';
import { Subject, EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InstantTemplateDataService } from '@services/APIServices/instant-data/instant-template-data.service';
import { MatTableDataSource, MatSort, MatTable } from '@angular/material';
import { InstantTemplatesService } from '@services/instant-templates.service';

@Component({
    selector: 'promo-instants',
    templateUrl: './instants-library.component.html',
    styleUrls: ['./instants-library.component.scss']
})
export class InstantsComponent implements OnInit, OnDestroy {
    public items: InstantTemplateResponse[];
    public displayedColumns: string[] = ['name', 'date', 'userId', 'advertText', 'edit', 'send'];
    private onDestroy$: Subject<void> = new Subject<void>();
    @ViewChild(MatSort)
    private sort: MatSort;
    public dataSource: MatTableDataSource<InstantTemplateResponse>;
    @ViewChild(MatTable)
    private table: MatTable<string>;

    public instants$: Observable<InstantTemplateResponse[]>;

    constructor(private spinnerService: SpinnerService, private router: Router, private instantTemplateService: InstantTemplatesService) {
        this.instants$ = this.instantTemplateService.instantTemplates$;
    }

    public async ngOnInit(): Promise<void> {
        this.instantTemplateService.loading$.subscribe(loading => {
            loading ? this.spinnerService.show() : this.spinnerService.hide();
        });
        this.instantTemplateService.getAll();
        this.instants$.subscribe(instants => {
            this.items = instants;
            this.dataSource = new MatTableDataSource(instants);
            this.dataSource.sort = this.sort;
            this.dataSource.sortingDataAccessor = (data, header): string => String(data[header]).toLocaleLowerCase();
            this.spinnerService.hide();
        });
    }

    public create(): void {
        const navPromise = this.router.navigate([`/instants/create`], { state: { instantTemplate: true } });
    }

    public edit(instant: InstantTemplateResponse): void {
        this.instantTemplateService.addOneToCache(this.items.find(ins => ins.id === instant.id));
        const navPromise = this.router.navigate([`/instants/${instant.id}/edit`]);
    }

    public send(instant: InstantTemplateResponse): void {
        this.spinnerService.show();
        this.instantTemplateService
            .sendInstantTemplate(instant)
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => this.spinnerService.hide());
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InstantTemplate, InstantTemplateResponse } from '@data/instant-template.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { AppConfigService } from '@services/app-config.service';
import { CompanyService } from '@services/company/company.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Update } from '@ngrx/entity';
import { Response } from '@data/response.model';
import { Promotion } from '@data/promotion.model';

@Injectable({
    providedIn: 'root'
})
export class InstantTemplateDataService extends DefaultDataService<InstantTemplateResponse> {
    private companyQueryParams: { [param: string]: string };
    private companyId: string;

    constructor(
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private appConfigService: AppConfigService,
        private companyService: CompanyService
    ) {
        super('InstantTemplate', http, httpUrlGenerator);
        this.companyService.selectedCompanyId$.subscribe(companyId => {
            this.companyQueryParams = { companyId };
            this.companyId = companyId;
            this.appConfigService.APIGateway.subscribe(APIGateway => {
                this.entityUrl = `${APIGateway}/promotions/instants/templates/`;
                this.entitiesUrl = this.entityUrl;
            });
        });
    }

    public getById(id: string): Observable<InstantTemplateResponse> {
        const url = `${this.entityUrl}${id}`;
        return this.http.get<InstantTemplateResponse>(url, { params: this.companyQueryParams }).pipe(
            map(response => {
                return {
                    ...response,
                    companyId: this.companyId
                };
            })
        );
    }

    public getAll(): Observable<InstantTemplateResponse[]> {
        const resultObservable = (super.getWithQuery(this.companyQueryParams) as unknown) as Observable<InstantTemplateResponse[]>;
        return resultObservable.pipe(
            map(response =>
                response.map(x => {
                    return {
                        ...x,
                        companyId: this.companyId
                    };
                })
            )
        );
    }

    public update(instant: Update<InstantTemplateResponse>): Observable<InstantTemplateResponse> {
        const changes = { ...instant.changes.template };
        const url = `${this.entityUrl}${instant.id}`;
        return this.http.put<InstantTemplateResponse>(url, changes, {
            params: {
                companyId: this.companyQueryParams.companyId
            }
        });
    }

    public add(entity: InstantTemplateResponse): Observable<InstantTemplateResponse> {
        const { sendImmediately, ...requestBody } = entity.template;
        return this.http.post<InstantTemplateResponse>(this.entityUrl, requestBody, {
            params: { send: (!!sendImmediately).toString(), companyId: this.companyQueryParams.companyId }
        });
    }

    // tslint:disable-next-line:no-any
    public delete(key: number | string): Observable<any> {
        const url = `${this.entityUrl}${key}`;
        return this.http.delete(url, { params: { companyId: this.companyQueryParams.companyId } });
    }
}

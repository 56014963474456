import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppConfigService } from './app-config.service';
import { combineLatest } from 'rxjs';

declare var gtag: (command: string, commandName: string, params: {}) => void;

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    constructor(private router: Router, private appConfigService: AppConfigService) {}

    public event(eventName: string, params: {}): void {
        gtag('event', eventName, params);
    }

    public init(): void {
        this.listenForRouteChanges();

        try {
            const script1 = document.createElement('script');
            script1.async = true;
            script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
            document.head.appendChild(script1);

            const script2 = document.createElement('script');
            script2.innerHTML =
                `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` +
                environment.googleAnalyticsKey +
                `', {'send_page_view': false, 'anonymize_ip': true });
      `;
            document.head.appendChild(script2);
        } catch (ex) {
            console.error('Error appending google analytics');
            console.error(ex);
        }
    }

    private listenForRouteChanges(): void {
        combineLatest([this.router.events, this.appConfigService.environment]).subscribe(([event, env]) => {
            if (event instanceof NavigationEnd) {
                gtag('config', environment.googleAnalyticsKey, {
                    page_path: event.urlAfterRedirects,
                    environment: env
                });
                console.log('Sending Google Analytics hit for route', event.urlAfterRedirects);
                console.log('Property ID', environment.googleAnalyticsKey);
            }
        });
    }
}

import { Component, Output, EventEmitter } from '@angular/core';
import { popupFooterAnimations } from './popup-footer.animations';

@Component({
    selector: 'promo-popup-footer',
    templateUrl: './popup-footer.component.html',
    styleUrls: ['./popup-footer.component.scss'],
    animations: popupFooterAnimations
})
export class PopupFooterComponent {
    @Output()
    private footerClosed = new EventEmitter<void>();

    public closeFooter(): void {
        this.footerClosed.emit();
    }
}

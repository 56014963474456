import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'promo-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
    @Input()
    public placeholder: string;
    @Input()
    public searchTerm = '';
    @Output()
    public searchTermChanged = new EventEmitter<string>();

    private onDestroy$: Subject<void> = new Subject<void>();
    public debouncer: Subject<string> = new Subject<string>();
    private debounceTime = 200;

    constructor() {
        this.debouncer
            .pipe(
                takeUntil(this.onDestroy$),
                debounceTime(this.debounceTime)
            )
            .subscribe(val => this.searchTermChanged.emit(val));
    }

    public ngOnInit(): void {}

    public emitSearchTerm(searchTerm: string): void {
        this.debouncer.next(searchTerm);
    }
}

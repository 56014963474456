import { Component, Input, OnInit } from '@angular/core';
import { IdAndName } from '@data/id-and-name.model';
import { Observable } from 'rxjs';
import { PromokioEntityCollectionServiceBase } from '@stores/services/promokio-entity-collection-service-base';
import { BasePermissionsService } from '@services/admin/base-permissions.service';

@Component({
    selector: 'promo-admin-tab',
    templateUrl: './admin-tab.component.html',
    styleUrls: ['./admin-tab.component.scss']
})
export class AdminTabComponent implements OnInit {
    @Input()
    public collectionService: PromokioEntityCollectionServiceBase<IdAndName> & BasePermissionsService;

    @Input()
    public tabKey: string;

    public data$: Observable<IdAndName[]>;

    constructor() {}

    public ngOnInit(): void {
        this.collectionService.load();
        this.data$ = this.collectionService.entities$;
    }

    public deleteRow(id: string): void {
        this.collectionService.delete(id);
    }

    public handleSearchTermChanges(searchTerm: string): void {
        this.collectionService.setSearchTerm(searchTerm);
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ColorPickerModule } from 'ngx-color-picker';
import { InstantsComponent } from './instants-library.component';
import { EditInstantComponent } from './edit-instants/edit-instant.component';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [InstantsComponent, EditInstantComponent],
    imports: [ReactiveFormsModule, CommonModule, ColorPickerModule, RouterModule, SharedModule, TranslateModule]
})
export class InstantsModule {}

import { NgModule } from '@angular/core';
import { CreatePlaylistComponent } from './create-playlist/create-playlist.component';
import { ViewPlaylistComponent } from './view-playlist/view-playlist.component';
import { PromotionPlaylistsComponent } from './promotion-playlists.component';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MaintainAspectRatioDirective } from '@directives/maintain-aspect-ratio.directive';
import { PlaylistPreviewComponent } from './playlist-preview/playlist-preview.component';
import { PreviewPanelComponent } from './playlist-preview/preview-panel/preview-panel.component';

@NgModule({
    declarations: [
        CreatePlaylistComponent,
        ViewPlaylistComponent,
        PromotionPlaylistsComponent,
        PlaylistPreviewComponent,
        PreviewPanelComponent,
        MaintainAspectRatioDirective
    ],
    imports: [SharedModule, CommonModule, RouterModule, ReactiveFormsModule, DragDropModule, TranslateModule, PipesModule],
    entryComponents: [PlaylistPreviewComponent]
})
export class PlaylistsModule {}

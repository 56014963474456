import { Injectable } from '@angular/core';
import { InstantTemplate, InstantTemplateResponse } from '@data/instant-template.model';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { PromokioEntityCollectionServiceBase } from '@stores/services/promokio-entity-collection-service-base';
import { Observable, combineLatest } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { CompanyService } from './company/company.service';
import { Response } from '@data/response.model';
import { Promotion } from '@data/promotion.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InstantTemplatesService extends PromokioEntityCollectionServiceBase<InstantTemplateResponse> {
    private endpoint = '/promotions/instants/templates/';
    private companyQueryParams: { [param: string]: string };

    public instantTemplates$: Observable<InstantTemplateResponse[]>;

    constructor(
        serviceElementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient,
        private appConfigService: AppConfigService,
        private companyService: CompanyService
    ) {
        super('InstantTemplate', serviceElementsFactory);
        this.companyService.selectedCompanyId$.subscribe(companyId => {
            this.companyQueryParams = { companyId };
            this.appConfigService.APIGateway.subscribe(APIGateway => {
                this.endpoint = `${APIGateway}/promotions/instants/templates/`;
            });
        });

        this.instantTemplates$ = combineLatest([this.entities$, this.companyService.selectedCompanyId$]).pipe(
            map(([instantTemplates, selectedCompanyId]) => {
                return instantTemplates.filter(template => template.companyId === selectedCompanyId);
            })
        );
    }

    public createInstantTemplate(instant: InstantTemplate): Observable<InstantTemplateResponse> {
        return super.add({ id: undefined, template: instant } as InstantTemplateResponse);
    }

    public sendInstantTemplate(instant: InstantTemplateResponse): Observable<Response<Promotion>> {
        const url = `${this.endpoint}${instant.id}/send`;
        return this.http.post<Response<Promotion>>(
            url,
            {},
            {
                params: {
                    companyId: this.companyQueryParams.companyId
                }
            }
        );
    }
}

import { Injectable } from '@angular/core';
import { PromokioEntityCollectionServiceBase } from '@stores/services/promokio-entity-collection-service-base';
import { IdAndName } from '@data/id-and-name.model';
import { EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable, BehaviorSubject } from 'rxjs';
import { BasePermissionsService } from './base-permissions.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyTypeService extends PromokioEntityCollectionServiceBase<IdAndName> implements BasePermissionsService {
    public searchTerm$ = new BehaviorSubject<string>('');

    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('CompanyTypes', serviceElementsFactory);
    }

    public setSearchTerm(searchTerm: string): void {
        this.searchTerm$.next(searchTerm);
    }

    public getSearchTerm(): Observable<string> {
        return this.searchTerm$.asObservable();
    }
}

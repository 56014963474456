import { NgModule } from '@angular/core';
import { AuthModule } from './auth/auth.module';
import { UsersModule } from './users/users.module';
import { PlaylistsModule } from './promotion-playlists/playlists.module';
import { PromotionsModule } from './promotion-library/promotions.module';
import { InteractionsModule } from './promotion-interactions/interactions.module';
import { DevicesModule } from './devices/devices.module';
import { GroupsModule } from './device-groups/groups.module';
import { CompaniesModule } from './companies/companies.module';
import { AuditDetailsDialogComponent } from './audit-details-dialog/audit-details-dialog.component';
import { HomeComponent } from './home/home.component';
import { AuditingComponent } from './auditing/auditing.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MediaModule } from './media/media.module';
import { IntegrationsModule } from './integrations/integrations.module';
import { InstantsModule } from './instants/instants.module';
import { DownloadModule } from './download/download.module';
import { AdminModule } from './admin/admin.module';

@NgModule({
    imports: [
        AuthModule,
        UsersModule,
        PlaylistsModule,
        PromotionsModule,
        InteractionsModule,
        DevicesModule,
        GroupsModule,
        CompaniesModule,
        CommonModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        MediaModule,
        IntegrationsModule,
        InstantsModule,
        DownloadModule,
        AdminModule
    ],
    declarations: [AuditDetailsDialogComponent, AuditingComponent, DashboardComponent, HomeComponent],
    entryComponents: [AuditDetailsDialogComponent]
})
export class PagesModule {}

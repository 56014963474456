import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IdAndName } from '@data/id-and-name.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'promo-admin-permissions-table',
    templateUrl: './admin-permissions-table.component.html',
    styleUrls: ['./admin-permissions-table.component.scss']
})
export class AdminPermissionsTableComponent {
    @Input()
    public tableHeader: string;

    @Input()
    public data: IdAndName[];

    @Input()
    public tabKey: string;

    @Output()
    public deleteAction = new EventEmitter<string>();

    public columnsToDisplay = ['entries', 'actions'];

    constructor(private router: Router) {}

    public emitDeleteAction(id: string): void {
        const swalPromise = Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to undo this change',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'No, cancel',
            imageUrl: '../../assets/swal2-warn.PNG',
            imageWidth: 100,
            imageHeight: 100
        }).then(result => {
            if (result.value) {
                this.deleteAction.emit(id);
            }
        });
    }

    public onEditClick(id: string): Promise<boolean> {
        switch (this.tabKey) {
            case 'CompanyTypes':
                return this.router.navigate(['/admin/company-types/' + id]);
            case 'CompanyRoles':
                return this.router.navigate(['/admin/company-roles/' + id]);
            default:
                break;
        }
    }
}

import { Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory, EntityOp } from '@ngrx/data';
import { Company } from '@data/company.model';
import { PromokioEntityCollectionServiceBase } from 'src/app/stores/services/promokio-entity-collection-service-base';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CompanyEntityCollection } from 'src/app/stores/collections/company/company-entity-collection';
import { CompanyEntityOperations } from 'src/app/stores/operations/company-entity-operations.enum';
import { Update } from '@ngrx/entity';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends PromokioEntityCollectionServiceBase<Company> {
    public selectedCompanyId$: Observable<string>;
    public selectedCompany$: Observable<Company>;

    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('Company', serviceElementsFactory);
        this.selectedCompanyId$ = this.selectors$.collection$.pipe(
            distinctUntilChanged((prev: CompanyEntityCollection, curr: CompanyEntityCollection) => {
                return prev.selectedCompany === curr.selectedCompany;
            }),
            map((collection: CompanyEntityCollection) => {
                return collection.selectedCompany;
            })
        );
        this.selectedCompany$ = combineLatest([this.entities$, this.selectedCompanyId$]).pipe(
            map(([companies, currentCompanyId]) => {
                return companies.find((x: Company) => x.id === currentCompanyId);
            })
        );
    }

    public updateSelectedCompany(companyId: string): void {
        this.createAndDispatch((CompanyEntityOperations.UPDATE_SELECTED_COMPANY as unknown) as EntityOp, companyId);
    }

    public update(company: Company): Observable<Company> {
        return super.update(company, { isOptimistic: true });
    }
}

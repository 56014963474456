import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of, empty } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { CognitoService } from 'src/app/services/cognito.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UsersService } from 'src/app/services/users.service';

import { TableColumn } from '@data/table-column.model';
import { Roles } from '@data/enums/roles.enum';
import { AvailableRoles } from './available-roles.model';

@Component({
    selector: 'promo-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {
    public user;
    public isCurrentUser: boolean;
    public userId: string;
    public edit = false;
    public availableRoles: AvailableRoles[] = Object.values(Roles).map((role, index) => ({
        role,
        id: index
    }));
    public selectedRoles = [];
    public displayedColumns: TableColumn[] = [
        {
            id: 'role',
            label: 'Role',
            type: 'string'
        }
    ];
    public userForm = new FormGroup({
        name: new FormControl(''),
        email: new FormControl('', [Validators.required, Validators.email]),
        roles: new FormControl('')
    });
    public emptyUser = {
        name: undefined,
        email: undefined,
        roles: []
    };
    public showChangePasswordForm = false;

    constructor(
        private authService: AuthService,
        private cognitoService: CognitoService,
        private spinnerService: SpinnerService,
        private usersService: UsersService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        if (!this.authService.currentUserRoles.some(role => role.startsWith('Promokio'))) {
            this.availableRoles = this.availableRoles.filter(role => !role.role.startsWith('Promokio'));
        }

        this.spinnerService.show();
        this.route.paramMap
            .pipe(
                map(params => {
                    let userId;
                    if (params.get('id')) {
                        userId = params.get('id');
                        this.userId = userId;
                        this.edit = true;
                    }
                    return userId;
                }),
                switchMap((userId: string) => {
                    if (userId) {
                        return this.usersService.getUser(userId);
                    }
                    return of(this.emptyUser);
                }),
                catchError(err => {
                    this.spinnerService.hide();
                    return empty();
                })
            )
            .subscribe(user => {
                this.user = user;
                this.isCurrentUser = this.cognitoService.user.username === this.user.email;

                this.selectedRoles = this.user.roles.map(role => ({
                    role,
                    id: this.availableRoles.filter(availableRole => availableRole.role === role)[0].id
                }));

                this.populateForm();
                this.spinnerService.hide();
            });
    }

    public onSubmit(): void {
        this.spinnerService.show();
        this.user = {
            name: this.userForm.get('name').value,
            email: this.userForm.get('email').value,
            roles: this.userForm.get('roles').value
        };

        if (!this.edit) {
            this.usersService
                .createUser(this.user)
                .pipe(
                    catchError(err => {
                        this.spinnerService.hide();
                        return empty();
                    })
                )
                .subscribe(() => {
                    const navigationPromise = this.router.navigate(['/users']);
                });
        } else {
            this.usersService
                .updateUser(this.userId, this.user)
                .pipe(
                    catchError(err => {
                        this.spinnerService.hide();
                        return empty();
                    })
                )
                .subscribe(() => {
                    this.spinnerService.hide();
                    const navigationPromise = this.router.navigate([`/users/${this.userId}`]);
                });
        }
    }

    public getDisplayedColumns(): string[] {
        return this.displayedColumns.map(x => x.id);
    }

    public roleSelectionChange(event: AvailableRoles[]): void {
        this.userForm.get('roles').setValue(event.map(x => x.role));
    }

    public populateForm(): void {
        this.userForm.get('name').setValue(this.user.name);
        this.userForm.get('email').setValue(this.user.email);
        this.userForm.get('roles').setValue(this.user.roles);
    }

    public onChangedPassword(): void {
        this.showChangePasswordForm = false;
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MediaService } from '@services/media.service';
import { Observable, Subject, EMPTY } from 'rxjs';
import { MediaUrls, Media } from '@data/media.model';
import { switchMap, filter, take, takeUntil, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MediaEvent } from '@data/media-event.model';
import { OverlayLoaderService } from '@services/overlay-loader/overlay-loader.service';
import { MediaViewerComponent } from '../../shared/media/media-viewer/media-viewer.component';
import { SpinnerService } from '@services/spinner.service';
import { MediaUploadDialogComponent } from '@shared/media/media-upload-dialog/media-upload-dialog.component';

@Component({
    selector: 'promo-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit, OnDestroy {
    private mediaIds$: Observable<string[]>;
    public mediaUrls$: Observable<MediaUrls>;
    public loading$: Observable<boolean>;

    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private mediaService: MediaService,
        private router: Router,
        private overlayLoader: OverlayLoaderService<MediaViewerComponent | MediaUploadDialogComponent>,
        private spinnerService: SpinnerService
    ) {
        this.mediaIds$ = this.mediaService.mediaForSelectedCompany$;
        this.loading$ = this.mediaService.loading$;
    }

    public ngOnInit(): void {
        this.mediaService.getByKey('media');
        this.mediaUrls$ = this.mediaIds$.pipe(
            filter(ids => !!(ids && ids.length)),
            switchMap(ids => {
                return this.mediaService.getUrlsForKeys(ids);
            })
        );
        this.loading$.pipe(takeUntil(this.onDestroy$)).subscribe(loading => {
            loading ? this.spinnerService.show() : this.spinnerService.hide();
        });
    }

    public async navigateToPromotion(mediaEvent: MediaEvent): Promise<void> {
        await this.router.navigate(['promotions', 'create'], {
            state: mediaEvent
        });
    }

    public openMediaModal(media: Media): void {
        const mediaUrl = media.url;
        const mediaViewer = this.overlayLoader.show(MediaViewerComponent) as MediaViewerComponent;
        mediaViewer.mediaUrl = mediaUrl;

        const mediaViewerClosedSub = mediaViewer.modalClosed
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(async mediaEvent => {
                if (mediaEvent) {
                    await this.navigateToPromotion(mediaEvent);
                }
                this.overlayLoader.hide();
            });

        this.overlayLoader.overlayClosedEvents$
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => mediaViewerClosedSub.unsubscribe());
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public openMediaUploadModal(): void {
        const mediaUpload = this.overlayLoader.show(MediaUploadDialogComponent) as MediaUploadDialogComponent;

        const mediaUploaderClosedSub = mediaUpload.modalClosed
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(async fileEvent => {
                if (fileEvent) {
                    this.spinnerService.show();
                    this.mediaService
                        .uploadMedia(fileEvent)
                        .pipe(
                            catchError(() => {
                                this.spinnerService.hide();
                                return EMPTY;
                            })
                        )
                        .subscribe(() => {
                            this.spinnerService.hide();
                            this.mediaService.getByKey('media');
                        });
                }
                this.overlayLoader.hide();
            });

        this.overlayLoader.overlayClosedEvents$
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => mediaUploaderClosedSub.unsubscribe());
    }
}

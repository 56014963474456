import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfigService } from './app-config.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportingService {
    private endpoint = '/reporting';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {}

    public getReporting(
        startDate: number,
        endDate: number,
        includeInstants: boolean,
        includeEmailDisabled: boolean
        // tslint:disable-next-line:no-any
    ): Observable<any> {
        return this.appConfigService.APIGateway.pipe(
            switchMap(apiGateway => {
                return this.http.get(
                    `${apiGateway}${this.endpoint}?startDate=${startDate || ''}&endDate=${endDate ||
                        ''}&includeInstants=${includeInstants || ''}&includeEmailDisabled=${includeEmailDisabled || ''}`
                );
            })
        );
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { ModalComponent } from '@data/modal/modal.component';
import { MediaEvent } from '@data/media-event.model';
import { PromotionTypes } from '@data/enums/promotion-types.enum';
import { Orientations } from '@data/enums/orientations.enum';
import { Resolution, MediaDetails } from '@data/media-details.model';

@Component({
    selector: 'promo-media-viewer',
    templateUrl: './media-viewer.component.html',
    styleUrls: ['./media-viewer.component.scss']
})
export class MediaViewerComponent extends ModalComponent<MediaEvent | void> {
    @Input()
    public mediaUrl: string;

    private orientation: Orientations;
    private type: PromotionTypes;

    constructor() {
        super();
    }

    public useImageForPromotion(): void {
        this.modalClosed.emit({
            mediaUrl: this.mediaUrl,
            type: this.type,
            orientation: this.orientation
        });
    }

    public onDetailsChange(details: MediaDetails): void {
        if (details.resolution.height > details.resolution.width) {
            this.orientation = Orientations.Portrait;
        } else if (details.resolution.height < details.resolution.width) {
            this.orientation = Orientations.Landscape;
        } else {
            this.orientation = undefined;
        }
        this.type = details.type;
    }
}

import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators"; // fancy pipe-able operators

@Injectable({
  providedIn: "root"
})
export class LoggingInterceptorService implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        tap(
          () => console.log("HTTPRequest: Successful"),
          () => console.log("HTTPRequest: Error")
        )
      );
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { Response } from '@data/response.model';
import { Group } from '@data/group.model';
import { SpinnerService } from '@services/spinner.service';
import { GroupsService } from '@services/groups.service';
import { catchError } from 'rxjs/operators';
import { empty } from 'rxjs';

@Component({
    selector: 'promo-device-groups',
    templateUrl: './device-groups.component.html',
    styleUrls: ['./device-groups.component.css']
})
export class DeviceGroupsComponent implements OnInit {
    public groups: Response<Group>;
    public dataSource: Group[];
    @ViewChild(MatTable)
    private table: MatTable<string>;

    public displayedColumns: string[] = ['name', 'tags', 'edit'];

    public isLoading = true;

    constructor(private spinnerService: SpinnerService, private groupsService: GroupsService, private router: Router) {}

    public async ngOnInit(): Promise<void> {
        this.spinnerService.show();
        this.groupsService
            .getGroups()
            .pipe(
                catchError(err => {
                    this.spinnerService.hide();
                    this.isLoading = false;
                    return empty();
                })
            )
            .subscribe(groups => {
                this.groups = groups;
                this.dataSource = groups.Items;
                this.isLoading = false;
                this.spinnerService.hide();
            });
    }

    public view(group: Group): void {
        const navigationPromise = this.router.navigate([`/groups/${group.id}`]);
    }

    public showMore(): void {
        this.spinnerService.show();
        this.groupsService
            .getGroups(this.groups.LastEvaluatedKey)
            .pipe(
                catchError(err => {
                    this.spinnerService.hide();
                    return empty();
                })
            )
            .subscribe(groups => {
                for (const group of groups.Items) {
                    this.groups.Items.push(group);
                }
                this.dataSource = [...this.groups.Items];
                this.groups.LastEvaluatedKey = groups.LastEvaluatedKey;
                this.spinnerService.hide();
            });
    }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Observable, of } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(public auth: AuthService, public router: Router) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const roles = route.data.roles as Array<string>;
        try {
            if (!this.auth.isAuthenticated()) {
                this.router.navigate(['login']);
                return false;
            }
            if (roles && !this.auth.isRole(roles)) {
                this.router.navigate(['login']);
                return false;
            }
            return true;
        } catch {
            this.router.navigate(['login']);
            return false;
        }
    }
}

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class IconService {
    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    private iconKeys = [
        'promo_admin',
        'promo_auditing',
        'promo_companies',
        'promo_dashboard',
        'promo_demoapp',
        'promo_devices',
        'promo_groupings',
        'promo_instants',
        'promo_integrations',
        'promo_interactions',
        'promo_logout',
        'promo_media',
        'promo_playlists',
        'promo_promotions',
        'promo_users',
        'promo_videos',
        'promo_bet',
        'promo_image',
        'promo_video',
        'promo_order',
        'promo_jira',
        'promo_url',
        'promo_showpassword',
        'promo_hidepassword'
    ];

    public registerIcons(): void {
        this.iconKeys.forEach(key => {
            this.matIconRegistry.addSvgIcon(key, this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${key}.svg`));
        });
    }
}

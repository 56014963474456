import { Component, OnInit, OnDestroy } from '@angular/core';
import { Integration } from '@data/integration.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IntegrationService } from '@services/integration/integration.service';
import { Subject, Observable, combineLatest } from 'rxjs';
import { takeUntil, take, map, filter } from 'rxjs/operators';
import { DialogModalComponent } from '@shared/dialog-modal/dialog-modal.component';
import { OverlayLoaderService } from '@services/overlay-loader/overlay-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { EntityOp, ofEntityOp, DataServiceError, EntityAction } from '@ngrx/data';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '@services/spinner.service';
import { CompanyService } from '@services/company/company.service';
import { Company } from '@data/company.model';
import { CompanyTypes } from '@data/enums/company-types.enum';

@Component({
    selector: 'promo-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit, OnDestroy {
    public integrations: Integration[];
    public integrations$: Observable<Integration[]>;

    public forms: FormGroup[] = [];

    private onDestroy$: Subject<void> = new Subject<void>();
    private selectedCompany$: Observable<Company>;
    private companyType$: Observable<CompanyTypes>;

    constructor(
        private integrationService: IntegrationService,
        private overlayLoader: OverlayLoaderService<DialogModalComponent<never>>,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private spinnerService: SpinnerService,
        private companyService: CompanyService
    ) {
        this.selectedCompany$ = this.companyService.selectedCompany$;
        this.companyType$ = this.selectedCompany$.pipe(
            filter(x => !!x),
            map(company => company.type)
        );
        this.integrations$ = combineLatest([this.integrationService.integrationsForCompany$, this.companyType$]).pipe(
            takeUntil(this.onDestroy$),
            map(([integrations, companyType]: [Integration[], CompanyTypes]) => {
                return integrations.filter(integration => integration.companyTypes.includes(companyType));
            })
        );
    }

    public ngOnInit(): void {
        this.integrationService.loading$.pipe(takeUntil(this.onDestroy$)).subscribe(loading => {
            loading ? this.spinnerService.show() : this.spinnerService.hide();
        });

        this.integrationService.entityActions$
            .pipe(
                ofEntityOp([EntityOp.SAVE_DELETE_ONE_ERROR, EntityOp.SAVE_ADD_ONE_ERROR]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(error => this.handleError(error));

        this.selectedCompany$.subscribe(company => {
            if (!company) {
                this.companyService.getAll();
            }
        });
        this.companyService.getAll();
        this.integrationService.getAll();
        this.integrations$.subscribe((integrations: Integration[]) => {
            this.integrations = integrations;
            this.forms = [];
            this.integrations.forEach(integration => {
                const newFormGroup = new FormGroup({
                    username: new FormControl(undefined, [Validators.required]),
                    password: new FormControl(undefined, [Validators.required])
                });

                newFormGroup
                    .get('username')
                    .valueChanges.pipe(takeUntil(this.onDestroy$))
                    .subscribe(username => {
                        integration.username = username;
                    });
                newFormGroup
                    .get('password')
                    .valueChanges.pipe(takeUntil(this.onDestroy$))
                    .subscribe(password => {
                        integration.password = password;
                    });

                if (integration.isEnabled) {
                    newFormGroup.disable();
                }
                this.forms.push(newFormGroup);
            });
        });
    }

    public submitForm(formIndex: number): void {
        this.integrationService.add(this.integrations[formIndex]);
    }

    public async removeIntegration(formIndex: number): Promise<void> {
        const removeConfirmationModal = this.overlayLoader.show(DialogModalComponent);

        removeConfirmationModal.hasCancelButton = true;

        const [modalTitleText, modalBodyText, modalSubmitText] = await Promise.all([
            this.translateService.get('Integrations.RemoveModal.Title').toPromise(),
            this.translateService.get('Integrations.RemoveModal.Body').toPromise(),
            this.translateService.get('Buttons.Remove').toPromise()
        ]);

        removeConfirmationModal.titleText = modalTitleText;
        removeConfirmationModal.modalBodyText = modalBodyText;
        removeConfirmationModal.submitButtonText = modalSubmitText;

        const modalSubmittedSubscription = removeConfirmationModal.modalSubmitted
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe((submitted: boolean) => {
                if (submitted) {
                    this.integrationService.delete(this.integrations[formIndex]);
                }
                this.overlayLoader.hide();
            });

        this.overlayLoader.overlayClosedEvents$
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => {
                modalSubmittedSubscription.unsubscribe();
            });
    }

    public trackByIntegrationId(index: number, item: Integration): string {
        return item.id;
    }

    private handleError(error: EntityAction<DataServiceError>): void {
        let translationLocation: string;
        if (error.payload.entityOp === EntityOp.SAVE_DELETE_ONE_ERROR) {
            translationLocation = 'Integrations.ErrorMessages.Delete.400';
        } else if (error.payload.entityOp === EntityOp.SAVE_ADD_ONE_ERROR) {
            translationLocation = 'Integrations.ErrorMessages.Add.400';
        }
        this.translateService.get(translationLocation).subscribe(errorMessage => {
            this.toastrService.error(errorMessage);
        });
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

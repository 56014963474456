import { Injectable } from '@angular/core';
import { EntityCollectionServiceElementsFactory, EntityActionOptions } from '@ngrx/data';
import { PromokioEntityCollectionServiceBase } from '@stores/services/promokio-entity-collection-service-base';
import { Tag } from '../data/tag.model';
import { Observable, combineLatest } from 'rxjs';
import { CompanyService } from './company/company.service';
import { map, take, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TagsService extends PromokioEntityCollectionServiceBase<Tag> {
    public tags$: Observable<Tag[]>;

    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory, private companyService: CompanyService) {
        super('Tag', serviceElementsFactory);
        this.tags$ = combineLatest([this.entities$, this.companyService.selectedCompanyId$]).pipe(
            map(([tags, selectedCompanyId]) => {
                return tags.filter(tag => tag.company === selectedCompanyId);
            })
        );
    }

    public getAll(): Observable<Tag[]> {
        const return$ = this.companyService.selectedCompanyId$.pipe(
            take(1),
            switchMap(companyId => {
                return super.getAll({ correlationId: companyId });
            })
        );
        return$.subscribe();
        return return$;
    }
}

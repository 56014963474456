import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy, ComponentRef } from '@angular/core';
import { OverlayScrollStrategies, ScrollStrategies } from '@data/overlay-container/scroll-strategies.enum';
import { TemplatePortalDirective, ComponentType, ComponentPortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'promo-overlay-container',
    templateUrl: './overlay-container.component.html',
    styleUrls: ['./overlay-container.component.scss']
})
export class OverlayContainerComponent<T> implements OnDestroy {
    @Input()
    public reference?: HTMLElement;
    @Input()
    public scrollStrategy: OverlayScrollStrategies = OverlayScrollStrategies.Block;

    @Output()
    public keyDownEvent: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
    @Output()
    public overlayClosed: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(TemplatePortalDirective)
    public contentTemplate: TemplatePortalDirective;

    private overlayRef: OverlayRef;
    private showing = false;

    private onDestroySubject = new Subject<void>();

    constructor(protected overlay: Overlay) {}

    public show(componentType?: ComponentType<T>): T {
        this.overlayRef = this.overlay.create(this.getOverlayConfig());
        const componentPortal = componentType ? new ComponentPortal(componentType) : this.contentTemplate;
        const containerRef: ComponentRef<T> = this.overlayRef.attach(componentPortal);
        this.overlayRef
            .backdropClick()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => this.hide());
        this.overlayRef.keydownEvents().subscribe(event => {
            this.keyDownEvent.emit(event);
        });
        this.showing = true;
        return containerRef.instance;
    }

    public hide(): void {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayClosed.emit();
            this.showing = false;
        }
    }

    private getOverlayConfig(): OverlayConfig {
        let positionStrategy: PositionStrategy;
        if (this.reference) {
            positionStrategy = this.overlay
                .position()
                .flexibleConnectedTo(this.reference)
                .withPush(false)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    }
                ]);
        } else {
            positionStrategy = this.overlay
                .position()
                .global()
                .centerHorizontally()
                .centerVertically();
        }

        const scrollStrategy = this.getScrollStrategy(this.overlay, this.scrollStrategy);

        return new OverlayConfig({
            scrollStrategy: scrollStrategy,
            positionStrategy: positionStrategy,
            hasBackdrop: true,
            backdropClass: this.reference ? 'cdk-overlay-transparent-backdrop' : undefined
        });
    }

    private getScrollStrategy(overlay: Overlay, scrollStrategy: OverlayScrollStrategies): ScrollStrategies {
        switch (scrollStrategy) {
            case OverlayScrollStrategies.Block:
                return this.overlay.scrollStrategies.block();
            case OverlayScrollStrategies.Close:
                return this.overlay.scrollStrategies.close();
            case OverlayScrollStrategies.Noop:
                return this.overlay.scrollStrategies.noop();
            case OverlayScrollStrategies.Reposition:
                return this.overlay.scrollStrategies.reposition();
            default:
                return this.overlay.scrollStrategies.block();
        }
    }

    get isShowing(): boolean {
        return this.showing;
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
    }
}

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, takeUntil, filter, startWith } from 'rxjs/operators';
import { BaseComponent } from '@utils/base.component';
import { PermissionsService } from '@services/APIServices/permissions/permissions.service';
import { AdminTabs } from '@pages/admin/admin-tabs.enum';
import { Permissions } from '@data/permissions/permissions.model';

@Component({
    selector: 'promo-base-add-edit-permissions',
    templateUrl: './base-add-edit-permissions.component.html',
    styleUrls: ['./base-add-edit-permissions.component.scss']
})
export class BaseAddEditPermissionsComponent extends BaseComponent implements OnChanges, OnInit {
    @Input()
    private tabIndex: number;

    @Input()
    private urlKey: string;

    @Input()
    public tabKey: string;

    public selectedPermissions: string[] = [];
    public companyPermissionName = new FormControl('', [Validators.required]);
    public companyPermissonsForm = new FormGroup({
        name: this.companyPermissionName
    });

    public adminTabs = AdminTabs;
    public id$: Observable<string>;
    public permissionsProfile: Permissions = new Permissions();

    public permissions$: Observable<string[]>;
    public isEdit$: Observable<boolean>;
    public loading$: Observable<boolean>;

    private permissionsModified = false;

    constructor(route: ActivatedRoute, public router: Router, public permissionsService: PermissionsService) {
        super();
        this.id$ = route.paramMap.pipe(
            map(params => {
                return params.get('id') || undefined;
            }),
            filter(id => id !== undefined)
        );
        this.isEdit$ = route.paramMap.pipe(
            map(params => {
                return params.has('id');
            })
        );
    }

    public ngOnInit(): void {
        this.id$.pipe(takeUntil(this.onDestroy$)).subscribe(async id => {
            this.permissionsProfile = await this.permissionsService.getPermissionsProfile(id, this.urlKey).toPromise();
            this.companyPermissionName.setValue(this.permissionsProfile.name);
        });
        this.permissions$ = this.permissionsService.getAllAvailablePermissions().pipe(startWith([]));
    }

    public ngOnChanges(): void {
        this.companyPermissionName.setValue(this.permissionsProfile.name);
    }

    public onCancelClick(): Promise<boolean> {
        return this.navigateBack();
    }

    public async onSaveClick(): Promise<boolean> {
        const companyProfile = new Permissions(this.permissionsProfile.id, this.selectedPermissions, this.companyPermissionName.value);
        const typeResult = await this.permissionsService.savePermissionsProfile(companyProfile, this.urlKey).toPromise();
        return this.navigateBack();
    }

    private navigateBack(): Promise<boolean> {
        return this.router.navigate(['/admin'], { queryParams: { tabIndex: this.tabIndex } });
    }

    public setSelectedPermissions(permissions: string[]): void {
        this.selectedPermissions = permissions;
    }

    public onPermissionModifiedEmit(changed: boolean): void {
        this.permissionsModified = changed;
    }

    public canSubmitForm(): boolean {
        return this.companyPermissonsForm.valid && (this.companyPermissionName.dirty || this.permissionsModified);
    }
}

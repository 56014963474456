import { AdminComponent } from './admin.component';
import { AddEditCompanyTypeComponent } from './company-types/add-edit-company-type/add-edit-company-type.component';
import { AddEditCompanyRoleComponent } from './company-roles/add-edit-company-role.component';

export const adminRoutes = [
    {
        path: '',
        component: AdminComponent
    },
    {
        path: 'company-types/create',
        component: AddEditCompanyTypeComponent
    },
    {
        path: 'company-types/:id',
        component: AddEditCompanyTypeComponent
    },
    {
        path: 'company-roles/create',
        component: AddEditCompanyRoleComponent
    },
    {
        path: 'company-roles/:id',
        component: AddEditCompanyRoleComponent
    }
];

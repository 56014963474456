import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap, switchMap, exhaustMap, filter, take, retryWhen, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from './app-config.service';
import { StatusCodes } from '@data/enums/status-codes.enum';
import { CompanyService } from './company/company.service';
import { AuthService } from './auth.service';
import { isNullOrUndefined } from 'util';
import { SpinnerService } from './spinner.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    private isTokenRefreshing = false;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private activeRequests = 0;

    constructor(
        private appConfigService: AppConfigService,
        private toastrService: ToastrService,
        private companyService: CompanyService,
        private authService: AuthService,
        private spinnerService: SpinnerService
    ) {}

    // tslint:disable-next-line:no-any
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        // handle your auth error or rethrow
        if (err.status === StatusCodes.Unauthorized || err.status === StatusCodes.Forbidden) {
            this.toastrService.error('You do not have permission to access this resource.', 'Unauthorised');
        }
        if (err.status === StatusCodes.BadGateway || err.status === StatusCodes.Offline || err.status === StatusCodes.BadRequest) {
            const errorMessage =
                err.error && err.error.errorMessage && err.error.errorMessage.message
                    ? err.error.errorMessage.message
                    : 'Something went wrong.';
            this.toastrService.error(errorMessage, 'Oops!');
        }
        throw err;
    }

    public intercept(
        // tslint:disable-next-line:no-any
        req: HttpRequest<any>,
        next: HttpHandler
        // tslint:disable-next-line:no-any
    ): Observable<HttpEvent<any>> {
        if (this.activeRequests === 0) {
            this.spinnerService.show();
        }
        this.activeRequests++;
        const request = req;
        return this.companyService.selectedCompanyId$.pipe(
            take(1),
            switchMap(companyId => {
                // allow the initial request for the variables
                if (request.url.startsWith('../../assets/variables.json')) {
                    return next.handle(request);
                }

                let headers = new HttpHeaders({
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                });

                if (companyId) {
                    headers = headers.set('Company-Access', companyId);
                }

                // check if the request is to Promokio's api
                if (this.appConfigService) {
                    const result = this.appConfigService.APIGateway.pipe(
                        switchMap(apiGateway => {
                            if (request.url.startsWith(apiGateway)) {
                                // clone the request to add the new header
                                let clonedRequest = request.clone({
                                    headers
                                });
                                clonedRequest = this.addTokenToRequest(clonedRequest);
                                // pass the cloned request instead of the original request to the next handle
                                return next.handle(clonedRequest).pipe(
                                    tap(evt => {
                                        if (evt instanceof HttpResponse) {
                                            if (evt.body && evt.status === StatusCodes.PartialContent) {
                                                this.toastrService.warning(evt.body);
                                            }
                                        }
                                    }),
                                    catchError(err => this.handleErrors(err, clonedRequest, next)),
                                    catchError(err => this.handleAuthError(err))
                                );
                            } else {
                                return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
                            }
                        })
                    );
                    return result;
                }
                return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
            }),
            finalize(() => {
                this.activeRequests--;
                if (this.activeRequests === 0) {
                    this.spinnerService.hide();
                }
            })
        );
    }

    // tslint:disable-next-line:no-any
    private addTokenToRequest(requestInput: HttpRequest<any>): HttpRequest<any> {
        let request = requestInput;
        const token = this.authService.getToken();
        if (token) {
            // This attaches authorization header to request
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return request;
    }

    // tslint:disable-next-line:no-any
    private handleErrors(error: any, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (error instanceof HttpErrorResponse) {
            return this.handleHttpError(error, request, next);
        }
        throw error;
    }

    // tslint:disable-next-line:no-any
    private handleHttpError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        switch (error.status) {
            case StatusCodes.Unauthorized:
                return this.handleHttpErrorUnauthorized(error, request, next);
            default:
                throw error;
        }
    }

    private handleHttpErrorUnauthorized(
        error: HttpErrorResponse,
        // tslint:disable-next-line:no-any
        request: HttpRequest<any>,
        next: HttpHandler
        // tslint:disable-next-line:no-any
    ): Observable<HttpEvent<any>> {
        // If the token is being refreshed as a result of a previous request then wait for it to return and use that to retry.
        if (this.isTokenRefreshing) {
            return this.tokenSubject.pipe(
                filter(token => token === undefined),
                take(1),
                switchMap(token => {
                    return next.handle(this.addTokenToRequest(request));
                })
            );
        } else {
            // ... else this is the first request to fail with 401. Attempt to refresh the token and resend.
            this.isTokenRefreshing = true;
            this.tokenSubject.next(undefined);
            // Refresh the token and retry the request.
            return this.refreshAndRetryRequest(request, next);
        }
    }

    // tslint:disable-next-line:no-any
    private refreshAndRetryRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.refreshToken().pipe(
            catchError(err => {
                return of(undefined);
            }),
            exhaustMap((newToken: string) => {
                if (newToken !== undefined) {
                    this.tokenSubject.next(newToken);
                    this.isTokenRefreshing = false;
                    return next.handle(this.addTokenToRequest(request));
                } else {
                    this.isTokenRefreshing = false;
                    throw throwError('Token did not refresh');
                }
            })
        );
    }

    private refreshToken(): Observable<string> {
        return this.authService.refreshUser();
    }
}

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PromotionTemplateComponent } from '@pages/promotion-library/promotion-template/promotion-template.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DragDropUploadComponent } from './drag-drop-upload/drag-drop-upload.component';
import { MenuComponent } from './menu/menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OrderPreviewDialogComponent } from './order-preview-dialog/order-preview-dialog.component';
import { OverlayContainerComponent } from './overlay-container/overlay-container.component';
import { PopupFooterComponent } from './popup-footer/popup-footer.component';
import { PreviewPanelComponent } from './preview-panel/preview-panel.component';
import { PromoPreviewDialogComponent } from './promo-preview-dialog/promo-preview-dialog.component';
import { SelectTableComponent } from './select-table/select-table.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { MediaLibraryComponent } from './media/media-library/media-library.component';
import { MediaViewerComponent } from './media/media-viewer/media-viewer.component';
import { MediaDisplayComponent } from './media/media-display/media-display.component';
import { DialogModalComponent } from './dialog-modal/dialog-modal.component';
import { MediaSelectorComponent } from './media/media-selector/media-selector.component';
import { MediaUploadDialogComponent } from './media/media-upload-dialog/media-upload-dialog.component';
import { EditBasePromotionComponent } from './Promotions/edit-base-promotion/edit-base-promotion.component';
import { PasswordFieldWrapperComponent } from './password-field-wrapper/password-field-wrapper.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { PermissionSectionComponent } from './permissions/permission-section/permission-section.component';
import { PipesModule } from '@pipes/pipes.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { AdminPermissionsTableComponent } from './admin-permissions-table/admin-permissions-table.component';
import { BaseAddEditPermissionsComponent } from './base-add-edit-permissions/base-add-edit-permissions.component';

@NgModule({
    declarations: [
        DragDropUploadComponent,
        MenuComponent,
        NavbarComponent,
        OrderPreviewDialogComponent,
        PopupFooterComponent,
        PreviewPanelComponent,
        PromoPreviewDialogComponent,
        SelectTableComponent,
        SpinnerComponent,
        UnauthorisedComponent,
        OverlayContainerComponent,
        PromotionTemplateComponent,
        MediaLibraryComponent,
        MediaViewerComponent,
        MediaDisplayComponent,
        DialogModalComponent,
        MediaSelectorComponent,
        PasswordFieldWrapperComponent,
        MediaUploadDialogComponent,
        EditBasePromotionComponent,
        PermissionsComponent,
        PermissionSectionComponent,
        SearchBarComponent,
        AdminPermissionsTableComponent,
        BaseAddEditPermissionsComponent
    ],
    imports: [
        CommonModule,
        NgxDropzoneModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        CurrencyMaskModule,
        RouterModule,
        MatBadgeModule,
        MatCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatAutocompleteModule,
        ColorPickerModule,
        ScrollingModule,
        PipesModule
    ],
    exports: [
        DragDropUploadComponent,
        MenuComponent,
        NavbarComponent,
        OrderPreviewDialogComponent,
        PopupFooterComponent,
        PreviewPanelComponent,
        PromoPreviewDialogComponent,
        SelectTableComponent,
        SpinnerComponent,
        UnauthorisedComponent,
        MatBadgeModule,
        MatCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        OverlayContainerComponent,
        MatAutocompleteModule,
        PromotionTemplateComponent,
        ScrollingModule,
        MediaDisplayComponent,
        MediaLibraryComponent,
        MediaViewerComponent,
        MediaSelectorComponent,
        PasswordFieldWrapperComponent,
        MediaUploadDialogComponent,
        EditBasePromotionComponent,
        PermissionsComponent,
        SearchBarComponent,
        AdminPermissionsTableComponent,
        BaseAddEditPermissionsComponent
    ],
    entryComponents: [MediaViewerComponent, DialogModalComponent, MediaUploadDialogComponent, MediaSelectorComponent]
})
export class SharedModule {}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Group } from '../data/group.model';
import { AppConfigService } from './app-config.service';
import { Response, LastEvaluatedKey } from '../data/response.model';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {
    private APIGateway = '';
    private endpoint = '/groups';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
    }

    public getGroups(lastEvaluatedKey?: LastEvaluatedKey): Observable<Response<Group>> {
        let filters = '';
        if (lastEvaluatedKey) {
            filters = `?date=${lastEvaluatedKey.date}&id=${lastEvaluatedKey.id}&company=${lastEvaluatedKey.company}`;
        }
        return this.http.get<Response<Group>>(this.APIGateway + this.endpoint + filters);
    }

    public getGroup(id: string): Observable<Response<Group>> {
        return this.http.get<Response<Group>>(this.APIGateway + this.endpoint + '/' + id);
    }

    public createGroup(group: Group): Observable<Response<Group>> {
        return this.http.post<Response<Group>>(this.APIGateway + this.endpoint, group);
    }

    public deleteGroup(id: string): Observable<void> {
        return this.http.delete<void>(`${this.APIGateway}${this.endpoint}/${id}`);
    }
}

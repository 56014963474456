import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Promotion } from '@data/promotion.model';
import { CompanyService } from '@services/company/company.service';
import { PromotionsService } from '@services/promotions.service';
import { SpinnerService } from '@services/spinner.service';
import { TagsService } from '@services/tags.service';
import { EMPTY, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Orientations } from '@data/enums/orientations.enum';
import { MediaService } from '@services/media.service';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '@services/app-config.service';

export interface PromotionUpdate {
    promotion: Promotion;
    portraitDuration: number;
    landscapeDuration: number;
}

const defaultPromoDuration = 10;

@Component({
    selector: 'promo-edit-promotion',
    templateUrl: './edit-promotion.component.html',
    styleUrls: ['./edit-promotion.component.css']
})
export class EditPromotionComponent implements OnInit, OnDestroy {
    public promotion: Promotion;
    public companyColour: string;
    public companyHasSwipingEnabled: boolean;

    public portraitImage: File;
    public landscapeImage: File;
    private portraitDuration: number;
    private landscapeDuration: number;
    private fileType: string;
    private portraitWidth: number;
    private portraitHeight: number;
    private landscapeHeight: number;
    private landscapeWidth: number;
    private S3BucketImages: string;

    public orientations = Orientations;

    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private promotionsService: PromotionsService,
        private spinnerService: SpinnerService,
        private companyService: CompanyService,
        private router: Router,
        private toastrService: ToastrService,
        private tagsService: TagsService,
        private mediaService: MediaService,
        private appConfigService: AppConfigService
    ) {
        this.appConfigService.S3BucketImages.subscribe(x => (this.S3BucketImages = x));
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.companyService.selectedCompany$.subscribe(company => {
            if (!company) {
                this.companyService.getAll();
            } else {
                const { swipingEnabled, colour } = company;
                this.companyHasSwipingEnabled = swipingEnabled;
                this.companyColour = colour;
            }
        });

        // TODO: Refactor chain
        this.route.paramMap
            .pipe(
                map(params => {
                    return params.get('id');
                }),
                switchMap(id => {
                    return this.promotionsService.getPromotion(id);
                }),
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(promotion => {
                this.promotion = {
                    ...promotion.Items[0],
                    companyColour: this.companyColour
                };
            });
    }

    public submitPromotion(event: PromotionUpdate): void {
        this.promotion = event.promotion;
        this.portraitDuration = event.portraitDuration;
        this.landscapeDuration = event.landscapeDuration;
        if (
            this.checkFileTypes(this.portraitImage, this.landscapeImage) &&
            this.checkDurations(this.portraitDuration, this.landscapeDuration) &&
            this.checkAspectRatio()
        ) {
            this.spinnerService.show();
            const portraitUpload = this.portraitImage === undefined ? of(undefined) : this.mediaService.uploadMedia(this.portraitImage);
            const landscapeUpload = this.landscapeImage === undefined ? of(undefined) : this.mediaService.uploadMedia(this.landscapeImage);

            portraitUpload
                .pipe(
                    switchMap(portraitId => {
                        if (portraitId) {
                            this.promotion.portraitImage = `https://${this.S3BucketImages}.s3-eu-west-1.amazonaws.com/${portraitId}`;
                        }
                        return landscapeUpload;
                    }),
                    switchMap(landscapeId => {
                        if (landscapeId) {
                            this.promotion.landscapeImage = `https://${this.S3BucketImages}.s3-eu-west-1.amazonaws.com/${landscapeId}`;
                        }
                        return this.promotionsService.createPromotion(this.promotion);
                    }),
                    catchError(() => {
                        this.spinnerService.hide();
                        return EMPTY;
                    })
                )
                .subscribe(() => {
                    this.spinnerService.hide();
                    const navigationPromise = this.router.navigate(['/promotions']);
                });
        }
    }

    public deletePromotion(): void {
        this.spinnerService.show();
        this.promotionsService
            .archivePromotion(this.promotion.id)
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.spinnerService.hide();
                const navigationPromise = this.router.navigate([`/promotions`]);
            });
    }

    public checkFileTypes(portraitFile: File, landscapeFile: File): boolean {
        if (portraitFile && landscapeFile) {
            if (portraitFile.type !== landscapeFile.type) {
                this.toastrService.error('Portrait and landscape media must be the same type', 'Oops!');
                return false;
            } else {
                this.fileType = portraitFile.type;
                this.promotion.fileType = this.fileType;
                return true;
            }
        } else {
            return true;
        }
    }

    public checkDurations(portraitDuration: number, landscapeDuration: number): boolean {
        if (!Number.isNaN(portraitDuration) && !Number.isNaN(landscapeDuration)) {
            if (portraitDuration !== landscapeDuration) {
                this.toastrService.error('Portrait and landscape media durations must match', 'Oops!');
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    public checkAspectRatio(): boolean {
        if (this.portraitWidth > this.portraitHeight || this.landscapeHeight > this.landscapeWidth) {
            this.toastrService.error('Portrait height must be greater than width and landscape width must be greater than height', 'Oops!');
            return false;
        } else {
            return true;
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

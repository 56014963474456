import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MenuItem } from '@data/promotion.model';
import { ColourUtils } from '@utils/colour.utils';

@Component({
    selector: 'promo-order-preview-dialog',
    templateUrl: './order-preview-dialog.component.html',
    styleUrls: ['./order-preview-dialog.component.css']
})
export class OrderPreviewDialogComponent implements OnInit {
    public menuSelection: MenuItem[];
    public companyColour: string;

    constructor(@Inject(MAT_DIALOG_DATA) data: { rows: MenuItem[]; companyColour: string }) {
        this.menuSelection = data.rows;
        this.companyColour = data.companyColour ? ColourUtils.convertFromARGBtoRGBA(data.companyColour) : undefined;
    }

    public ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core';
import { AdminTabs } from './admin-tabs.enum';
import { ButtonConfig } from './button-config.model';
import { CompanyTypeService } from '@services/admin/company-type.service';
import { CompanyRolesService } from '@services/admin/company-roles.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'promo-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
    public selectedTabIndex: AdminTabs = 0;

    public tabKeys = {
        companyRoles: 'CompanyRoles',
        companyTypes: 'CompanyTypes'
    };

    public companyTypeService: CompanyTypeService;
    public companyRolesService: CompanyRolesService;

    constructor(private route: ActivatedRoute, companyTypeService: CompanyTypeService, companyRolesService: CompanyRolesService) {
        this.companyTypeService = companyTypeService;
        this.companyRolesService = companyRolesService;
    }

    public ngOnInit(): void {
        this.route.queryParamMap.subscribe(queryParams => {
            const tabIndex = queryParams.get('tabIndex');
            if (tabIndex) {
                this.selectedTabIndex = parseInt(tabIndex, 10);
            }
        });
    }

    public onTabChange(index: number): void {
        this.selectedTabIndex = index;
    }

    public getButtonConfig(selectedIndex: number): ButtonConfig {
        switch (selectedIndex) {
            case AdminTabs.CompanyType:
                return new ButtonConfig('/admin/company-types/create', 'Admin.CompanyTypes.Create');
            case AdminTabs.CompanyRoles:
                return new ButtonConfig('/admin/company-roles/create', 'Admin.CompanyRoles.Create');
            default:
                return undefined;
        }
    }
}

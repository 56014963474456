import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ChangeDetectorRef, OnDestroy, AfterViewChecked } from '@angular/core';
import { Playlist } from '@data/playlist.model';
import { Orientations } from '@data/enums/orientations.enum';
import { MatTabGroup } from '@angular/material';
import { ModalComponent } from '@data/modal/modal.component';

@Component({
    selector: 'promo-playlist-preview',
    templateUrl: './playlist-preview.component.html',
    styleUrls: ['./playlist-preview.component.scss']
})
export class PlaylistPreviewComponent extends ModalComponent<void> implements OnInit {
    @Input()
    public playlist: Playlist;

    public orientations = Orientations;

    @ViewChild(MatTabGroup)
    public tabGroup: MatTabGroup;

    constructor() {
        super();
    }

    public ngOnInit(): void {}
}

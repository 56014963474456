import { Component } from '@angular/core';
import { BaseComponent } from '@utils/base.component';
import { AdminTabs } from '@pages/admin/admin-tabs.enum';

@Component({
    selector: 'promo-add-edit-company-type',
    templateUrl: './add-edit-company-type.component.html',
    styleUrls: ['./add-edit-company-type.component.scss']
})
export class AddEditCompanyTypeComponent extends BaseComponent {
    public adminTabs = AdminTabs;
    public tabKey = 'CompanyTypes';
    public urlKey = 'companyTypes';

    constructor() {
        super();
    }
}

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Playlist } from '@data/playlist.model';
import { Response } from '@data/response.model';
import { PlaylistsService } from '@services/playlists.service';
import { SpinnerService } from '@services/spinner.service';
import { EMPTY, Subject } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { PlaylistPreviewComponent } from './playlist-preview/playlist-preview.component';
import { OverlayLoaderService } from '@services/overlay-loader/overlay-loader.service';

@Component({
    selector: 'promo-promotion-playlists',
    templateUrl: './promotion-playlists.component.html',
    styleUrls: ['./promotion-playlists.component.css']
})
export class PromotionPlaylistsComponent implements OnInit, OnDestroy {
    public displayedColumns: string[] = ['name', 'tag', 'loops', 'view'];
    public playlists: Response<Playlist>;
    public dataSource: MatTableDataSource<Playlist>;
    @ViewChild(MatTable) public table: MatTable<string>;
    @ViewChild(MatSort) public sort: MatSort;

    public isLoading = true;

    public onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private spinnerService: SpinnerService,
        private playlistService: PlaylistsService,
        private overlayLoader: OverlayLoaderService<PlaylistPreviewComponent>
    ) {}

    public async ngOnInit(): Promise<void> {
        this.spinnerService.show();
        this.playlistService
            .getPlaylists()
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(playlists => {
                this.playlists = playlists;
                this.dataSource = new MatTableDataSource(playlists.Items);
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = (data, header): string => String(data[header]).toLocaleLowerCase();
                this.isLoading = false;
                this.spinnerService.hide();
            });
    }

    public showMore(): void {
        this.spinnerService.show();
        this.playlistService
            .getPlaylists(this.playlists.LastEvaluatedKey)
            .pipe(
                catchError(err => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(playlists => {
                for (const playlist of playlists.Items) {
                    this.playlists.Items.push(playlist);
                }
                this.dataSource = new MatTableDataSource([...this.playlists.Items]);
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = (data, header): string => String(data[header]).toLocaleLowerCase();
                this.playlists.LastEvaluatedKey = playlists.LastEvaluatedKey;
                this.spinnerService.hide();
            });
    }

    public previewPlaylist(playlistIndex: number): void {
        const playlistPreview = this.overlayLoader.show(PlaylistPreviewComponent);
        playlistPreview.playlist = this.playlists.Items[playlistIndex];

        const previewClosedSub = playlistPreview.modalClosed
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.overlayLoader.hide());

        this.overlayLoader.overlayClosedEvents$
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => previewClosedSub.unsubscribe());
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

import { Component, EventEmitter, Output, Input } from '@angular/core';
import { DialogButtons } from '@data/dialog-modal/dialog-modal.model';

@Component({
    selector: 'promo-dialog-modal',
    templateUrl: './dialog-modal.component.html',
    styleUrls: ['./dialog-modal.component.scss']
})
export class DialogModalComponent<ReturnType> {
    @Input()
    public titleText: string;
    @Input()
    public modalBodyText: string;
    @Input()
    public cancelButtonText: string;
    @Input()
    public submitButtonText: string;
    @Input()
    public hasCancelButton: boolean;
    @Input()
    public additionalButtons: DialogButtons<ReturnType>[] = [];

    @Output()
    public modalSubmitted: EventEmitter<boolean> = new EventEmitter();
    @Output()
    // tslint:disable-next-line:no-any
    public additionalButtonSubmitted: EventEmitter<any> = new EventEmitter();

    public modalSubmitAction(): void {
        this.modalSubmitted.emit(true);
    }

    public modalCancelAction(): void {
        this.modalSubmitted.emit(false);
    }

    // tslint:disable-next-line:no-any
    public modalAdditionalSubmit(value: ReturnType): void {
        this.additionalButtonSubmitted.emit(value);
    }

    get splitBodyText(): string[] {
        if (this.modalBodyText) {
            return this.modalBodyText.split('\n');
        }
    }
}

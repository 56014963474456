import { PromotionPlaylistsComponent } from './promotion-playlists.component';
import { CreatePlaylistComponent } from './create-playlist/create-playlist.component';
import { ViewPlaylistComponent } from './view-playlist/view-playlist.component';

export const playlistsRoutes = [
    {
        path: '',
        component: PromotionPlaylistsComponent
    },
    {
        path: 'create',
        component: CreatePlaylistComponent
    },
    {
        path: ':id',
        component: ViewPlaylistComponent
    },
    {
        path: ':id/edit',
        component: CreatePlaylistComponent
    }
];

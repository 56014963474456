import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';

import { fadeAnimation } from '../../animations';

import { AuthService } from '../../services/auth.service';
import { CognitoService } from '../../services/cognito.service';
import { InteractionsService } from '../../services/interactions.service';
import { WebsocketsService } from '../../services/websockets.service';

import { Company } from '../../data/company.model';

import * as RolesEnum from '../../data/enums/roles.enum';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { CompanyService } from '@services/company/company.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NavbarService } from '@services/navbar/navbar.service';

@Component({
    selector: 'promo-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    animations: [fadeAnimation] // register the animation
})
export class NavbarComponent implements OnInit, OnDestroy {
    @ViewChild('sidenavLeft')
    private sidenavLeft: MatSidenav;

    public emailFormControl = new FormControl('', [Validators.required, Validators.email]);

    public companyForm = new FormGroup({
        currentCompany: new FormControl('')
    });
    public numberOfNewInteractions = 0;
    public companies: Company[];
    public currentUserRoles: string[];
    public roles = RolesEnum.Roles;
    public currentRoute: NavigationEnd;
    private currentUserRolesSubscription: Subscription;
    private webSocketsSubscription: Subscription;
    private interactionsSubscription: Subscription;

    public companies$: Observable<Company[]>;
    public currentCompanyId$: Observable<string>;
    private onDestroy$: Subject<void> = new Subject<void>();

    public versionNumber = environment.versionNumber;

    constructor(
        private cognitoService: CognitoService,
        private router: Router,
        private websocketsService: WebsocketsService,
        private authService: AuthService,
        private interactionsService: InteractionsService,
        private companyService: CompanyService,
        private changeDetector: ChangeDetectorRef,
        private navbarService: NavbarService
    ) {
        this.companies$ = this.companyService.entities$.pipe(
            map(companies => companies.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })))
        );
        this.currentCompanyId$ = this.companyService.selectedCompanyId$;
    }

    public ngOnInit(): void {
        this.navbarService.showingNavbar$.pipe(takeUntil(this.onDestroy$)).subscribe(showNavbar => {
            showNavbar ? this.sidenavLeft.open() : this.sidenavLeft.close();
        });

        this.currentUserRolesSubscription = this.authService.currentUserRoles$.subscribe(currentUserRoles => {
            this.currentUserRoles = currentUserRoles;
        });

        combineLatest([this.companyService.loaded$, this.cognitoService.isLoggedIn$])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(([isLoaded, isLoggedIn]) => {
                if (!isLoaded && isLoggedIn) {
                    this.companyService.getAll();
                }
            });

        combineLatest([this.companies$, this.currentCompanyId$])
            .pipe(
                filter(([companies, currentCompanyId]) => {
                    return companies.length !== 0;
                }),
                takeUntil(this.onDestroy$)
            )
            .subscribe(([companies, currentCompanyId]) => {
                this.loadCurrentCompany(companies, currentCompanyId);
            });

        this.webSocketsSubscription = this.websocketsService.alert$.subscribe(() => {
            if (this.router.url !== '/interactions') {
                this.numberOfNewInteractions++;
            }
        });

        this.interactionsSubscription = this.interactionsService.sinceUserCheckedInteractions$.subscribe(() => {
            this.numberOfNewInteractions = 0;
        });

        this.currentCompanyId$.subscribe(() => {
            this.numberOfNewInteractions = 0;
        });
    }

    public ngOnDestroy(): void {
        this.currentUserRolesSubscription.unsubscribe();
        this.webSocketsSubscription.unsubscribe();
        this.interactionsSubscription.unsubscribe();
        this.onDestroy$.next();
    }

    public switchCompany(value: { value: string }): void {
        combineLatest([this.companies$, this.currentCompanyId$])
            .pipe(take(1))
            .subscribe(([companies, currentCompanyId]) => {
                const companyName = value.value;
                const company = companies.find(x => x.name === companyName);
                this.companyService.updateSelectedCompany(company.id);
                if (company.id !== currentCompanyId) {
                    const navigationPromise = this.router.navigate(['home']);
                }
            });
    }

    public loadCurrentCompany(companies: Company[], currentCompanyId: string): void {
        const currentCompany = companies.find(company => company.id === currentCompanyId);

        if (currentCompany === undefined) {
            const firstCompany = companies[0];
            this.companyForm.get('currentCompany').setValue(firstCompany.name);
            this.companyService.updateSelectedCompany(firstCompany.id);
        } else {
            this.companyForm.get('currentCompany').setValue(currentCompany.name);
        }

        this.changeDetector.markForCheck();
    }

    public logout(): void {
        this.cognitoService.signOut();
    }

    public resetBadge(): void {
        this.numberOfNewInteractions = 0;
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '@data/company.model';
import { CompanyTypes } from '@data/enums/company-types.enum';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@services/spinner.service';
import { ColourUtils } from '@utils/colour.utils';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { CompanyService } from '@services/company/company.service';
import { DataServiceError, EntityAction, EntityOp, ofEntityOp } from '@ngrx/data';
import { FileTypes, FontMimeType } from '@data/enums/file-types.enum';
import { AppConfigService } from '@services/app-config.service';
import { MediaService } from '@services/media.service';
import { Media } from '@data/media.model';

@Component({
    selector: 'promo-create-company',
    templateUrl: './create-company.component.html',
    styleUrls: ['./create-company.component.css']
})
export class CreateCompanyComponent implements OnInit, OnDestroy {
    private onDestroy$: Subject<void> = new Subject<void>();
    private companies$: Observable<Company[]>;
    private companyId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
    private companyId: string;
    public loading$: Observable<boolean>;

    public edit = false;
    public sameAddress = true;

    public companyForm = new FormGroup({
        code: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
        primaryContact: new FormControl('', Validators.required),
        primaryContactEmailAddress: new FormControl('', [Validators.required, Validators.email]),
        interactionsEmail: new FormControl('', [Validators.email]),
        invoiceAddressPostCode: new FormControl('', Validators.required),
        invoiceAddress: new FormControl('', Validators.required),
        addressPostCode: new FormControl('', Validators.required),
        address: new FormControl('', Validators.required),
        companyType: new FormControl('', Validators.required),
        accountManager: new FormControl('example1'),
        numberOfDevices: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
        numberOfGroups: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
        numberOfUsers: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
        companyColour: new FormControl('', []),
        companyFont: new FormControl('', []),
        swipingEnabled: new FormControl()
    });

    private code = this.companyForm.get('code');
    private name = this.companyForm.get('name');
    private primaryContact = this.companyForm.get('primaryContact');
    private primaryContactEmailAddress = this.companyForm.get('primaryContactEmailAddress');
    private interactionsEmail = this.companyForm.get('interactionsEmail');
    private invoiceAddressPostCode = this.companyForm.get('invoiceAddressPostCode');
    private invoiceAddress = this.companyForm.get('invoiceAddress');
    private address = this.companyForm.get('address');
    private addressPostCode = this.companyForm.get('addressPostCode');
    private companyType = this.companyForm.get('companyType');
    private accountManager = this.companyForm.get('accountManager');
    private numberOfDevices = this.companyForm.get('numberOfDevices');
    private numberOfGroups = this.companyForm.get('numberOfGroups');
    private numberOfUsers = this.companyForm.get('numberOfUsers');
    private companyColour = this.companyForm.get('companyColour');
    public companyFont = this.companyForm.get('companyFont');
    private swipingEnabled = this.companyForm.get('swipingEnabled');

    public companyFontChanged = false;
    public acceptedFileTypes: string[] = [];
    private S3BucketFonts: string;

    public company: Company;

    private accountManagers = [
        {
            id: 'example1',
            name: 'Example 1'
        },
        {
            id: 'example2',
            name: 'Example 2'
        }
    ];

    public companyTypes = CompanyTypes;

    constructor(
        private spinnerService: SpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService,
        private translateService: TranslateService,
        private companyService: CompanyService,
        private appConfigService: AppConfigService,
        private mediaService: MediaService
    ) {
        this.appConfigService.S3BucketImages.subscribe(x => (this.S3BucketFonts = x));
        this.companies$ = this.companyService.entities$.pipe(takeUntil(this.onDestroy$));
        this.loading$ = this.companyService.loading$.pipe(takeUntil(this.onDestroy$));
    }

    public ngOnInit(): void {
        this.companyService.entityActions$
            .pipe(
                ofEntityOp([EntityOp.SAVE_ADD_ONE_SUCCESS, EntityOp.SAVE_UPDATE_ONE_SUCCESS]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.navigateBack());
        this.companyService.entityActions$
            .pipe(
                ofEntityOp([EntityOp.SAVE_ADD_ONE_ERROR]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(error => this.handleError(error));

        this.companyService.entities$.subscribe(companies => {
            const company = companies.find(x => x.id === this.companyId);
            this.company = company || undefined;
            if (company) {
                this.populateForm();
            }
        });

        this.loading$.subscribe(isLoading => {
            isLoading ? this.spinnerService.show() : this.spinnerService.hide();
        });

        this.route.paramMap.subscribe(params => {
            this.acceptedFileTypes = [FileTypes.FONT];
            this.companyId = params.get('id');
            this.companyId$.next(params.get('id'));
            if (this.companyId) {
                this.edit = true;
                this.sameAddress = false;
                this.companyService.getByKey(this.companyId);
            }
        });
    }

    public toggleSameAddress(): void {
        this.sameAddress = !this.sameAddress;
        if (this.sameAddress) {
            this.address.setValue(this.invoiceAddress.value);
            this.addressPostCode.setValue(this.invoiceAddressPostCode.value);
        } else {
            this.address.setValue('');
            this.addressPostCode.setValue('');
        }
    }

    public invoiceAddressChange({ target: { value } }: { target: HTMLTextAreaElement }): void {
        if (this.sameAddress) {
            this.companyForm.get('address').setValue(value);
        }
    }

    public invoicePostCodeChange({ target: { value } }: { target: HTMLInputElement }): void {
        if (this.sameAddress) {
            this.companyForm.get('addressPostCode').setValue(value);
        }
    }

    public removeFont(): void {
        this.companyFont.setValue(undefined);
    }

    public fontUploadComplete(selectedFile: File): void {
        if (selectedFile) {
            this.companyFont.setValue(selectedFile);
            this.companyFontChanged = true;
        } else {
            this.removeFont();
        }
    }

    set companyColourValue(colour: string) {
        const colourValue = colour ? ColourUtils.convertFromRGBAtoARGB(colour) : undefined;
        this.companyColour.setValue(colourValue);
    }

    get companyColourValue(): string {
        return this.companyColour.value ? ColourUtils.convertFromARGBtoRGBA(this.companyColour.value) : undefined;
    }

    public onSubmit(): void {
        this.company = {
            id: this.companyId || undefined,
            code: this.code.value,
            name: this.name.value,
            primaryContact: this.primaryContact.value,
            primaryContactEmail: this.primaryContactEmailAddress.value,
            interactionsEmail: this.interactionsEmail.value,
            invoiceAddress: {
                line1: this.invoiceAddress.value,
                line2: ' ',
                county: ' ',
                country: ' ',
                postcode: this.invoiceAddressPostCode.value
            },
            address: {
                line1: this.address.value,
                line2: ' ',
                county: ' ',
                country: ' ',
                postcode: this.addressPostCode.value
            },
            type: this.companyType.value,
            accountManager: this.accountManager.value,
            maxDevices: this.numberOfDevices.value,
            maxGroups: this.numberOfGroups.value,
            maxUsers: this.numberOfUsers.value,
            colour: this.companyColour.value,
            swipingEnabled: this.swipingEnabled.value
        };

        this.spinnerService.show();
        const fontUpload =
            this.companyFont.value === undefined ? of(undefined) : this.mediaService.uploadMedia(this.companyFont.value, FontMimeType);

        fontUpload
            .pipe(
                switchMap((font: Media) => {
                    if (font) {
                        this.company.fontUrl = `https://${this.S3BucketFonts}.s3-eu-west-1.amazonaws.com/${font.name}`;
                    }
                    if (this.edit) {
                        return this.companyService.update(this.company);
                    } else {
                        return this.companyService.add(this.company);
                    }
                }),
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.spinnerService.hide();
            });
    }

    private navigateBack(): void {
        const navigationPromise = this.router.navigate(['/companies']);
    }

    private handleError(error: EntityAction<DataServiceError>): void {
        const errorMessage = error.payload.data.error.error.error.errorMessage;
        const translationLocations = ['Companies.ErrorMessages.AccountAlreadyExists', 'Companies.ErrorMessages.CompanyNameAlreadyExists'];
        this.translateService.get(translationLocations).subscribe(errorMessages => {
            if (errorMessage === errorMessages[translationLocations[0]] || errorMessage === errorMessages[translationLocations[1]]) {
                this.toastrService.error(errorMessage);
            }
        });
    }

    private populateForm(): void {
        this.code.setValue(this.company.code);
        this.name.setValue(this.company.name);
        this.primaryContact.setValue(this.company.primaryContact);
        this.primaryContactEmailAddress.setValue(this.company.primaryContactEmail);
        this.interactionsEmail.setValue(this.company.interactionsEmail);
        this.invoiceAddressPostCode.setValue(this.company.invoiceAddress.postcode);
        this.invoiceAddress.setValue(`${this.company.invoiceAddress.line1}\n${this.company.invoiceAddress.line2}`);
        this.addressPostCode.setValue(this.company.address.postcode);
        this.address.setValue(`${this.company.address.line1}\n${this.company.address.line2}`);
        this.companyType.setValue(this.company.type);
        this.numberOfDevices.setValue(this.company.maxDevices);
        this.numberOfGroups.setValue(this.company.maxGroups);
        this.numberOfUsers.setValue(this.company.maxUsers);
        this.swipingEnabled.setValue(this.company.swipingEnabled);
        this.companyColour.setValue(this.company.colour);
        this.companyFont.setValue(this.company.fontUrl);
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { CognitoService } from '@services/cognito.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NavbarService {
    private showingNavbarSubject$ = new BehaviorSubject<boolean>(true);
    public showingNavbar$: Observable<boolean>;

    constructor(private cognitoService: CognitoService) {
        this.showingNavbar$ = combineLatest([this.showingNavbarSubject$, this.cognitoService.isLoggedIn$]).pipe(
            map(([showNavbar, isLoggedIn]) => {
                if (isLoggedIn && showNavbar) {
                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    public showNavbar(): void {
        this.showingNavbarSubject$.next(true);
    }

    public hideNavbar(): void {
        this.showingNavbarSubject$.next(false);
    }
}

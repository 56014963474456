import {
    EntityCollectionReducerMethods,
    EntityChangeTracker,
    EntityDefinition,
    EntityDefinitionService,
    EntityCollectionReducerMethodMap,
    EntityAction
} from '@ngrx/data';
import { Injectable } from '@angular/core';

export class PromokioEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
    constructor(entityName: string, definition: EntityDefinition<T>, entityChangeTracker?: EntityChangeTracker<T>) {
        super(entityName, definition, entityChangeTracker);
    }

    protected throwError(action: EntityAction, msg: string): never {
        throw new Error(`${this.entityName} EntityAction guard for "${action.type}": payload ${msg}`);
    }
}

@Injectable()
export class PromotionEntityCollectionReducerMethodsFactory {
    constructor(private entityDefinitionService: EntityDefinitionService) {}

    /** Create the  {EntityCollectionReducerMethods} for the named entity type */
    public create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
        const definition = this.entityDefinitionService.getDefinition<T>(entityName);
        const methodsClass = new PromokioEntityCollectionReducerMethods(entityName, definition);

        return methodsClass.methods;
    }
}

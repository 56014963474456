import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AudioService {
    private audio: HTMLAudioElement;
    private audioContext: AudioContext;

    private sound: AudioBufferSourceNode;

    constructor() {
        this.audio = new Audio();
        this.audioContext = new AudioContext();
    }

    public playSound(fileNameInput?: string, loop: boolean = true): void {
        if (this.sound) {
            return;
        }
        const source = this.audioContext.createBufferSource();
        const xhr = new XMLHttpRequest();
        const fileName = fileNameInput || 'notification.ogg';
        xhr.open('GET', `../../assets/sounds/${fileName}`);
        xhr.responseType = 'arraybuffer';
        xhr.addEventListener('load', sound => {
            const decodePromise = this.audioContext.decodeAudioData(xhr.response, buffer => {
                source.buffer = buffer;
                source.connect(this.audioContext.destination);
                source.loop = loop;
            });
            source.start(0);
            const audioResumePromise = this.audioContext.resume();
            this.sound = source;
        });
        xhr.send();
    }

    public stopSound(): void {
        if (this.sound) {
            this.sound.stop();
            this.sound = undefined;
        }
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable, Subject } from 'rxjs';
import { Roles } from '@data/enums/roles.enum';
import { Company } from '@data/company.model';
import { AuthService } from '@services/auth.service';
import { SpinnerService } from '@services/spinner.service';
import { take, takeUntil, map } from 'rxjs/operators';
import { CompanyService } from '@services/company/company.service';

@Component({
    selector: 'promo-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit, OnDestroy {
    public companies: Company[];
    public displayedColumns: string[] = ['code', 'name', 'primaryContact', 'primaryContactEmail', 'companyType'];
    public currentUserRoles: string[];
    public roles = Roles;
    private currentUserRolesSubscription: Subscription;

    public loading$: Observable<boolean>;
    public companies$: Observable<Company[]>;
    public isLoading = true;

    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private spinnerService: SpinnerService,
        private authService: AuthService,
        private router: Router,
        private companyService: CompanyService
    ) {
        this.loading$ = this.companyService.loading$.pipe(takeUntil(this.onDestroy$));
        this.companies$ = this.companyService.entities$.pipe(
            takeUntil(this.onDestroy$),
            map(companies => {
                return companies.sort((a, b) => {
                    if (a.date > b.date) {
                        return -1;
                    }
                    return 1;
                });
            })
        );
    }

    public ngOnInit(): void {
        this.loading$.subscribe(loading => {
            if (loading) {
                this.spinnerService.show();
            } else {
                this.spinnerService.hide();
            }
        });
        this.companyService.loaded$.pipe(take(1)).subscribe(isLoaded => {
            // Uncomment code when refreshing data is enabled
            // if (!isLoaded) {
            this.companyService.getAll();
            // }
        });

        this.currentUserRolesSubscription = this.authService.currentUserRoles$.subscribe(currentUserRoles => {
            this.currentUserRoles = currentUserRoles;
            this.filterDisplayedColumns();
        });
    }

    public ngOnDestroy(): void {
        this.currentUserRolesSubscription.unsubscribe();
        this.onDestroy$.next();
    }

    public edit(company: Company): void {
        const navigationPromise = this.router.navigate([`/companies/${company.id}/edit`]);
    }

    public filterDisplayedColumns(): void {
        if (
            this.currentUserRoles.includes(Roles.PromokioSuperUser) ||
            this.currentUserRoles.includes(Roles.PromokioCompanyCreator) ||
            this.currentUserRoles.includes(Roles.CompanyAdmin)
        ) {
            this.displayedColumns.push('edit');
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, catchError } from 'rxjs/operators';

import { SpinnerService } from 'src/app/services/spinner.service';
import { UsersService } from 'src/app/services/users.service';
import { User } from '@data/user.model';
import { empty } from 'rxjs';

@Component({
    selector: 'promo-view-users',
    templateUrl: './view-users.component.html',
    styleUrls: ['./view-users.component.css']
})
export class ViewUsersComponent implements OnInit {
    public userId: string;
    public user: User;

    constructor(private usersService: UsersService, private router: ActivatedRoute, private spinnerService: SpinnerService) {}

    public ngOnInit(): void {
        this.spinnerService.show();
        this.router.paramMap
            .pipe(
                map(params => {
                    return params.get('id');
                }),
                switchMap(userId => {
                    return this.usersService.getUser(userId);
                }),
                catchError(err => {
                    this.spinnerService.hide();
                    return empty();
                })
            )
            .subscribe(user => {
                this.user = user as User;
                this.spinnerService.hide();
            });
    }
}

import { CompaniesComponent } from './companies.component';
import { AuthGuard } from '@guards/auth-guard/auth.guard';
import { Roles } from '@data/enums/roles.enum';
import { CreateCompanyComponent } from './create-company/create-company.component';

export const companiesRoutes = [
    {
        path: '',
        component: CompaniesComponent,
        data: {
            roles: [
                Roles.PromokioSuperUser,
                Roles.PromokioCompanyCreator,
                Roles.PromokioDataProcessor,
                Roles.PromokioSalesPerson,
                Roles.CompanyAdmin
            ]
        }
    },
    {
        path: 'create',
        component: CreateCompanyComponent,
        data: {
            roles: [Roles.PromokioSuperUser, Roles.PromokioCompanyCreator, Roles.PromokioSalesPerson, Roles.CompanyAdmin]
        }
    },
    {
        path: ':id/edit',
        component: CreateCompanyComponent,
        data: {
            roles: [Roles.PromokioSuperUser, Roles.PromokioCompanyCreator, Roles.CompanyAdmin]
        }
    }
];

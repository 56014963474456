export enum PromotionTypes {
    Instant = 'Instant',
    Order = 'Order',
    Bet = 'Bet',
    Image = 'Image',
    Promotion = 'Promotion',
    URL = 'URL',
    JIRA = 'JIRA',
    Video = 'Video'
}

export enum PromotionDescriptions {
    Instant = 'Displayed instantly to devices in selected groups for a set duration.',
    Order = 'Add menu items which users can select from the device.',
    Bet = 'Add odds for users to select from the device.',
    Image = 'An image promotion.',
    URL = 'Display a URL as a promotion',
    JIRA = 'Display a JIRA dashboard as a promotion',
    Video = 'A video promotion'
}

export enum PromotionIcons {
    Instant = 'promo_instant',
    Order = 'promo_order',
    Bet = 'promo_bet',
    Image = 'promo_image',
    URL = 'promo_url',
    JIRA = 'promo_jira',
    Video = 'promo_video'
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import * as RolesEnum from '../../data/enums/roles.enum';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
    roles = RolesEnum.Roles;
    homePage = '/dashboard';
    currentUserRoleSubscription: Subscription;

    constructor(private authService: AuthService, private router: Router) {}

    ngOnInit() {
        this.currentUserRoleSubscription = this.authService.currentUserRoles$.subscribe(currentUserRoles => {
            if (currentUserRoles.includes(this.roles.CompanyHost)) {
                this.homePage = '/interactions';
            } else if (currentUserRoles.includes(this.roles.CompanyPromoter)) {
                this.homePage = '/promotions';
            } else if (currentUserRoles.includes(this.roles.CompanyProvisioner)) {
                this.homePage = '/groups';
            } else if (currentUserRoles.includes(this.roles.PromokioDataProcessor)) {
                this.homePage = '/dashboard';
            } else if ([this.roles.PromokioCompanyCreator, this.roles.PromokioSalesPerson].some(role => currentUserRoles.includes(role))) {
                this.homePage = '/companies';
            }

            this.router.navigate([this.homePage]);
        });
    }

    ngOnDestroy() {
        this.currentUserRoleSubscription.unsubscribe();
    }
}

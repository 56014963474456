import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatDialogConfig, MatDialog } from '@angular/material';

import { AuditDetailsDialogComponent } from './../audit-details-dialog/audit-details-dialog.component';

import { AuditingService } from '../../services/auditing.service';
import { SpinnerService } from '../../services/spinner.service';

import { AuditRecord } from '../../data/audit.model';
import { catchError } from 'rxjs/operators';
import { of, Observable, empty } from 'rxjs';

@Component({
    selector: 'promo-auditing',
    templateUrl: './auditing.component.html',
    styleUrls: ['./auditing.component.css']
})
export class AuditingComponent implements OnInit {
    public auditLog: AuditRecord[];
    public dataSource: MatTableDataSource<AuditRecord>;
    public displayedColumns = ['date', 'username', 'action', 'resource', 'name', 'result', 'details'];
    public isLoading = true;

    @ViewChild(MatSort)
    private sort: MatSort;

    constructor(private dialog: MatDialog, private auditingService: AuditingService, private spinnerService: SpinnerService) {}

    public ngOnInit(): void {
        this.spinnerService.show();
        this.auditingService
            .getAuditing()
            .pipe(
                catchError(err => {
                    this.spinnerService.hide();
                    return empty();
                })
            )
            .subscribe(({ Items }) => {
                this.auditLog = Items.map(record => {
                    record.name = record.resourceDetails ? record.resourceDetails.name : '';
                    return record;
                });
                this.dataSource = new MatTableDataSource(this.auditLog);
                this.dataSource.sort = this.sort;
                this.isLoading = false;
                this.spinnerService.hide();
            });
    }

    public applyFilter(filterValue: string): void {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    public showDetails(auditRecord: AuditRecord): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = auditRecord;
        this.dialog.open(AuditDetailsDialogComponent, dialogConfig);
    }
}

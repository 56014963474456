import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Group } from '@data/group.model';
import { Subject, Observable, EMPTY } from 'rxjs';
import { GroupsService } from '@services/groups.service';
import { SpinnerService } from '@services/spinner.service';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { DesktopAppService } from '@services/APIServices/desktop-app/desktop-app.service';
import { DesktopAppConfig } from '@data/desktop-app-config.model';
import { validateMultipleEmails } from 'src/app/validators/multiple-emails.validator';

@Component({
    selector: 'promo-create-desktop-app',
    templateUrl: './create-desktop-app.component.html',
    styleUrls: ['./create-desktop-app.component.scss']
})
export class CreateDesktopAppComponent implements OnInit, OnDestroy {
    public desktopAppForm = new FormGroup({
        group: new FormControl('', Validators.required),
        emailAddresses: new FormControl('', [Validators.required, validateMultipleEmails])
    });
    public groups: Group[];

    private onDestroy$: Subject<void> = new Subject<void>();

    private deviceId$: Observable<string | undefined>;
    public groups$: Observable<Group[]>;

    constructor(
        private groupService: GroupsService,
        private spinnerService: SpinnerService,
        private desktopAppsService: DesktopAppService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.spinnerService.show();

        this.groups$ = this.groupService.getGroups().pipe(map(x => x.Items));

        this.groups$
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.spinnerService.hide();
            });
    }

    public onSubmit(): void {
        this.spinnerService.show();
        const emailAddresses = this.desktopAppForm.get('emailAddresses').value;
        const desktopAppConfig: DesktopAppConfig = {
            emailAddresses: this.splitEmailAddressValue(emailAddresses),
            groupId: this.desktopAppForm.get('group').value
        };

        this.desktopAppsService
            .createDesktopApp(desktopAppConfig)
            .pipe(
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.spinnerService.hide();
                const navigationPromise = this.router.navigate(['/desktop-apps']);
            });
    }

    public splitEmailAddressValue(value: string): string[] {
        return value
            .split(',')
            .map(x => x.trim())
            .filter(x => x !== '');
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

import { Injectable, EventEmitter } from '@angular/core';
import { Subject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WebsocketsService {
    private APIGateway = '';
    private webSocket: string;
    private endpoint = '/connections';
    // tslint:disable-next-line:no-any
    public newMessage$ = new Observable<any>(undefined);
    // tslint:disable-next-line:no-any
    public alert$ = new EventEmitter<any>(undefined);

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
        this.appConfigService.WebSocket.subscribe(x => (this.webSocket = x));
    }

    private subject: Subject<MessageEvent>;
    private ws: WebSocket;

    public openConnection(): void {
        this.newMessage$ = this.getConnectionTicket().pipe(
            switchMap(connectionTicket => this.connect(this.webSocket, connectionTicket)),
            // tslint:disable-next-line:no-any
            map((response: MessageEvent): any => {
                const data = JSON.parse(response.data);
                this.alert$.emit(data);
                return data;
            })
        );
        this.newMessage$.subscribe();
    }

    private connect(url: string, connectionTicket: string): Subject<MessageEvent> {
        if (this.subject) {
            this.disconnect();
        }
        this.subject = this.create(url + '?auth=' + encodeURIComponent(connectionTicket));
        console.log('Successfully Connected:' + url);
        return this.subject;
    }

    public disconnect(): void {
        this.ws.close();
        // tslint:disable-next-line:no-null-keyword
        this.subject = null;
    }

    // tslint:disable-next-line:no-any
    public getConnectionTicket(): Observable<any> {
        // tslint:disable-next-line:no-any
        return this.http.get<any>(this.APIGateway + this.endpoint);
    }

    private create(url: string): Subject<MessageEvent> {
        this.ws = new WebSocket(url);
        const observable = Observable.create((obs: Observer<MessageEvent>) => {
            this.ws.onmessage = obs.next.bind(obs);
            this.ws.onerror = obs.error.bind(obs);
            this.ws.onclose = obs.complete.bind(obs);
            return this.ws.close.bind(this.ws);
        });

        const observer = {
            next: (data: object): void => {
                if (this.ws.readyState === WebSocket.OPEN) {
                    this.ws.send(JSON.stringify(data));
                }
            }
        };

        return Subject.create(observer, observable);
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { InteractionTableComponent } from './interaction-table/interaction-table.component';
import { PromotionInteractionsComponent } from './promotion-interactions.component';
import { PipesModule } from '@pipes/pipes.module';

@NgModule({
    declarations: [PromotionInteractionsComponent, InteractionTableComponent],
    imports: [TranslateModule, SharedModule, CommonModule, RouterModule, PipesModule]
})
export class InteractionsModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { switchMap, tap, takeUntil, filter, map } from 'rxjs/operators';
import { PromotionTypes } from '../data/enums/promotion-types.enum';
import * as RolesEnum from '../data/enums/roles.enum';
import { Interaction } from '../data/interaction.model';
import { LastEvaluatedKey, Response } from '../data/response.model';
import { AppConfigService } from './app-config.service';
import { AudioService } from './audio.service';
import { AuthService } from './auth.service';
import { WebsocketsService } from './websockets.service';
import { CompanyService } from './company/company.service';

@Injectable({
    providedIn: 'root'
})
export class InteractionsService {
    private APIGateway = '';
    private endpoint = '/interactions';

    private emptyResponse: Response<Interaction> = {
        Count: 0,
        Items: [],
        ScannedCount: 0
    };

    public ordersResponse$ = new BehaviorSubject<Response<Interaction>>(this.emptyResponse);
    public promotionsResponse$ = new BehaviorSubject<Response<Interaction>>(this.emptyResponse);
    public betsResponse$ = new BehaviorSubject<Response<Interaction>>(this.emptyResponse);
    public instantsResponse$ = new BehaviorSubject<Response<Interaction>>(this.emptyResponse);

    public currentTab = undefined;
    public unseenPromotions = 0;
    public unseenOrders = 0;
    public unseenBets = 0;
    public unseenInstants = 0;

    public sinceSystemCheckedInteractions$ = new BehaviorSubject<Date>(undefined);
    public sinceUserCheckedInteractions$ = new BehaviorSubject<Date>(undefined);
    private roles = RolesEnum.Roles;

    private startDate = Math.ceil(new Date().getTime());

    constructor(
        private http: HttpClient,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private websocketsService: WebsocketsService,
        private audioService: AudioService,
        private companyService: CompanyService
    ) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
        // initially get first set of interactions when a company is selected
        this.companyService.selectedCompanyId$
            .pipe(
                filter(selectedId => !!selectedId),
                switchMap(() => this.getInteractions(undefined, PromotionTypes.Promotion)),
                switchMap(promotions => {
                    this.promotionsResponse$.next(promotions);
                    return this.getInteractions(undefined, PromotionTypes.Order);
                }),
                switchMap(orders => {
                    this.ordersResponse$.next(orders);
                    return this.getInteractions(undefined, PromotionTypes.Bet);
                }),
                switchMap(bets => {
                    this.betsResponse$.next(bets);
                    return this.getInteractions(undefined, PromotionTypes.Instant);
                }),
                tap(instants => {
                    this.instantsResponse$.next(instants);
                    this.sinceSystemCheckedInteractions$.next(new Date());
                    if (this.canSeeInteractions()) {
                        this.websocketsService.openConnection();
                        // reconnect to WebSocket every 9 minutes
                        const nineMinutesInMs = 540_000;
                        setInterval(() => {
                            this.websocketsService.openConnection();
                        }, nineMinutesInMs);
                    }
                })
            )
            .subscribe();
        // add new interaction to the correct sub-section, ++ to the unseen count.
        this.websocketsService.alert$.subscribe((newInteraction: Interaction) => {
            newInteraction.unseen = true;
            if (newInteraction) {
                const defaultPromotionTypes = [PromotionTypes.Promotion, PromotionTypes.Image, PromotionTypes.Video];
                if (defaultPromotionTypes.includes(newInteraction.type)) {
                    if (this.currentTab !== PromotionTypes.Promotion) {
                        this.unseenPromotions++;
                    }
                    this.sendNewInteraction(newInteraction, this.promotionsResponse$);
                }
                if (newInteraction.type === PromotionTypes.Order) {
                    if (this.currentTab !== PromotionTypes.Order) {
                        this.unseenOrders++;
                    }
                    this.sendNewInteraction(newInteraction, this.ordersResponse$);
                }
                if (newInteraction.type === PromotionTypes.Bet) {
                    if (this.currentTab !== PromotionTypes.Bet) {
                        this.unseenBets++;
                    }
                    this.sendNewInteraction(newInteraction, this.betsResponse$);
                }
                if (newInteraction.type === PromotionTypes.Instant) {
                    if (this.currentTab !== PromotionTypes.Instant) {
                        this.unseenInstants++;
                    }
                    this.sendNewInteraction(newInteraction, this.instantsResponse$);
                }
                this.audioService.playSound();
            }
        });

        combineLatest([this.ordersResponse$, this.instantsResponse$, this.promotionsResponse$, this.betsResponse$]).subscribe(
            ([orders, instants, promotions, bets]) => {
                const interactions = [...orders.Items, ...instants.Items, ...promotions.Items, ...bets.Items];
                const playSound = interactions.some(interaction => {
                    return !interaction.resolved && Number(interaction.date) > this.startDate;
                });
                if (!playSound) {
                    this.audioService.stopSound();
                }
            }
        );
    }

    public getInteractions(lastEvaluatedKey?: LastEvaluatedKey, type?: string): Observable<Response<Interaction>> {
        if (this.canSeeInteractions()) {
            let filters = '?limit=50';
            if (type) {
                filters = filters + `&type=${type}`;
            }
            if (lastEvaluatedKey) {
                filters = filters + `&date=${lastEvaluatedKey.date}&id=${lastEvaluatedKey.id}&company=${lastEvaluatedKey.company}`;
            }
            return this.http.get<Response<Interaction>>(this.APIGateway + this.endpoint + filters);
        } else {
            return of(this.emptyResponse);
        }
    }

    public getEmailExportUrl(startDate: number, endDate: number): Observable<string> {
        return this.http.get<string>(`${this.APIGateway}/interactions/export?startDate=${startDate || ''}&endDate=${endDate || ''}`);
    }

    public resolveInteraction(interaction: Interaction): Observable<void> {
        return this.http.post<void>(`${this.APIGateway}${this.endpoint}/${interaction.id}/resolve`, {}).pipe(
            map(() => {
                switch (interaction.type as PromotionTypes) {
                    case PromotionTypes.Bet:
                        this.replaceInteraction(interaction, this.betsResponse$);
                        return;
                    case PromotionTypes.Instant:
                        this.replaceInteraction(interaction, this.instantsResponse$);
                        return;
                    case PromotionTypes.Order:
                        this.replaceInteraction(interaction, this.ordersResponse$);
                        return;
                    case PromotionTypes.Promotion:
                    case PromotionTypes.Image:
                    case PromotionTypes.Video:
                        this.replaceInteraction(interaction, this.promotionsResponse$);
                        return;
                }
            })
        );
    }

    private replaceInteraction(interaction: Interaction, responseSubject: BehaviorSubject<Response<Interaction>>): void {
        const tempItems = [...responseSubject.value.Items];
        const index = tempItems.findIndex(x => x.id === interaction.id);
        tempItems[index] = interaction;
        const newResponse: Response<Interaction> = {
            Count: responseSubject.value.Count,
            Items: tempItems,
            ScannedCount: responseSubject.value.ScannedCount,
            LastEvaluatedKey: responseSubject.value.LastEvaluatedKey
        };
        responseSubject.next(newResponse);
    }

    public sendNewInteraction(newInteraction: Interaction, responseSubject: BehaviorSubject<Response<Interaction>>): void {
        const tempItems = [...responseSubject.value.Items];
        tempItems.unshift(newInteraction);
        const newResponse: Response<Interaction> = {
            Count: responseSubject.value.Count,
            Items: tempItems,
            ScannedCount: responseSubject.value.ScannedCount,
            LastEvaluatedKey: responseSubject.value.LastEvaluatedKey
        };
        responseSubject.next(newResponse);
    }

    public canSeeInteractions(): boolean {
        return this.authService.currentUserRoles.some(role =>
            [this.roles.PromokioSuperUser, this.roles.CompanyAccountHolder, this.roles.CompanyAdmin, this.roles.CompanyHost].includes(role)
        );
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CognitoRefreshToken, CognitoUserPool, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
    public currentUserRoles = [];
    public currentUserRoles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(['']);

    constructor(public jwtHelper: JwtHelperService, private router: Router, private appConfig: AppConfigService) {}
    public isAuthenticated(): boolean {
        const token = localStorage.getItem('idToken');
        return !this.jwtHelper.isTokenExpired(token);
    }

    public isRole(roles: string[]): boolean {
        const token = localStorage.getItem('idToken');
        const userRoleArray = this.jwtHelper.decodeToken(token)['cognito:groups'];
        let userRole = '';
        if (userRoleArray) {
            userRole = userRoleArray[0];
        }
        return roles.includes(userRole);
    }

    public setCurrentLoggedInUser(): void {
        const token = localStorage.getItem('idToken');
        this.currentUserRoles = this.jwtHelper.decodeToken(token)['cognito:groups'];
        this.currentUserRoles$.next(this.jwtHelper.decodeToken(token)['cognito:groups'] || ['']);
    }

    private refreshCurrentUser(currentRefreshToken: CognitoRefreshToken, poolData: ICognitoUserPoolData): Observable<string> {
        return from(
            new Promise((resolve, reject): void => {
                const userPool = new CognitoUserPool(poolData);
                const cognitoUser = userPool.getCurrentUser();
                cognitoUser.refreshSession(currentRefreshToken, (err, result) => {
                    // log user out if session fails to refresh
                    if (err) {
                        localStorage.removeItem('refreshToken');
                        const navigationPromise = this.router.navigate(['/login']);
                        reject();
                    }
                    const accessToken = result.getAccessToken().getJwtToken();
                    const idToken = result.getIdToken().getJwtToken();
                    const refreshToken = result.getRefreshToken().getToken();
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('idToken', idToken);
                    localStorage.setItem('refreshToken', refreshToken);
                    resolve(idToken);
                });
            })
        );
    }

    public refreshUser(): Observable<string> {
        const refreshToken = new CognitoRefreshToken({
            RefreshToken: localStorage.getItem('refreshToken')
        });
        return this.appConfig.poolData.pipe(
            switchMap(x => {
                return this.refreshCurrentUser(refreshToken, x);
            })
        );
    }

    public getToken(): string {
        return localStorage.getItem('idToken');
    }
}

import { Directive, ElementRef, OnInit, Input, HostListener, AfterViewChecked, OnChanges } from '@angular/core';

@Directive({
    selector: '[promoMaintainAspectRatio]'
})
export class MaintainAspectRatioDirective implements AfterViewChecked, OnChanges {
    @Input()
    private aspectRatioHeight = 3;
    @Input()
    private aspectRatioWidth = 4;
    @Input()
    private parentHeight: number;
    @Input()
    private parentWidth: number;

    constructor(private hostElement: ElementRef<HTMLElement>) {}

    public ngOnChanges(): void {
        this.calculateAspectRatio();
    }

    public ngAfterViewChecked(): void {
        this.calculateAspectRatio();
    }

    private calculateAspectRatio(): void {
        const newHeight = (this.parentWidth / this.aspectRatioWidth) * this.aspectRatioHeight;
        const newWidth = (this.parentHeight / this.aspectRatioHeight) * this.aspectRatioWidth;

        if (newHeight < this.parentHeight) {
            this.hostElement.nativeElement.style['min-height'] = `${newHeight}px`;
            this.hostElement.nativeElement.style['max-height'] = `${newHeight}px`;
        } else if (newWidth < this.parentWidth) {
            this.hostElement.nativeElement.style['min-width'] = `${newWidth}px`;
            this.hostElement.nativeElement.style['max-width'] = `${newWidth}px`;
        }
    }
}

import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

enum PasswordVisibility {
    ShowPassword = 'text',
    HidePassword = 'password'
}

@Component({
    selector: 'promo-password-field-wrapper',
    templateUrl: './password-field-wrapper.component.html',
    styleUrls: ['./password-field-wrapper.component.scss']
})
export class PasswordFieldWrapperComponent implements OnInit, OnDestroy {
    public showPassword$ = new BehaviorSubject(false);
    private onDestroy$: Subject<void> = new Subject<void>();
    private passwordVisibilitySubscription: Subscription;

    public input: HTMLInputElement;

    constructor(private elem: ElementRef<Element>) {}

    public ngOnInit(): void {
        this.input = this.elem.nativeElement.querySelector<HTMLInputElement>('input');
        if (!this.input) {
            throw new Error(`No input element found.`);
        }
        this.passwordVisibilitySubscription = this.showPassword$.pipe(takeUntil(this.onDestroy$)).subscribe(show => {
            this.input.type = show ? PasswordVisibility.ShowPassword : PasswordVisibility.HidePassword;
        });
    }

    public toggle(): void {
        this.showPassword$.next(!this.showPassword$.getValue());
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Promotion } from '@data/promotion.model';
import { Response } from '@data/response.model';
import { CompanyService } from '@services/company/company.service';
import { PromotionsService } from '@services/promotions.service';
import { SpinnerService } from '@services/spinner.service';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'promo-promotion-library',
    templateUrl: './promotion-library.component.html',
    styleUrls: ['./promotion-library.component.css']
})
export class PromotionLibraryComponent implements OnInit, OnDestroy {
    public items: Promotion[];
    public displayedColumns: string[] = ['name', 'type', 'date', 'userId', 'advertText', 'edit'];
    @ViewChild(MatTable)
    private table: MatTable<string>;
    @ViewChild(MatSort)
    private sort: MatSort;
    public promotions: Response<Promotion>;
    public dataSource: MatTableDataSource<Promotion>;

    private onDestroy$: Subject<void> = new Subject<void>();
    private currentCompanyId$: Observable<string>;

    constructor(
        private spinnerService: SpinnerService,
        private promotionsService: PromotionsService,
        private router: Router,
        private companyService: CompanyService
    ) {
        this.currentCompanyId$ = this.companyService.selectedCompanyId$.pipe(takeUntil(this.onDestroy$));
    }

    public async ngOnInit(): Promise<void> {
        this.currentCompanyId$.subscribe(currentCompanyId => {
            if (currentCompanyId) {
                this.spinnerService.show();
                this.promotionsService
                    .getPromotions()
                    .pipe(
                        catchError(err => {
                            this.spinnerService.hide();
                            return EMPTY;
                        })
                    )
                    .subscribe(promotions => {
                        this.promotions = promotions;
                        this.dataSource = new MatTableDataSource(promotions.Items);
                        this.dataSource.sort = this.sort;
                        this.dataSource.sortingDataAccessor = (data, header): string => String(data[header]).toLocaleLowerCase();
                        this.spinnerService.hide();
                    });
            }
        });
    }

    public edit(promotion: Promotion): void {
        const navigationPromise = this.router.navigate([`/promotions/${promotion.id}/edit`]);
    }

    public showMore(): void {
        this.spinnerService.show();
        this.promotionsService
            .getPromotions(this.promotions.LastEvaluatedKey)
            .pipe(
                catchError(err => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(({ Items, LastEvaluatedKey }) => {
                for (const promotion of Items) {
                    this.promotions.Items.push(promotion);
                }
                this.dataSource = new MatTableDataSource([...this.promotions.Items]);
                this.dataSource.sort = this.sort;
                this.dataSource.sortingDataAccessor = (data, header): string => String(data[header]).toLocaleLowerCase();
                this.promotions.LastEvaluatedKey = LastEvaluatedKey;
                this.spinnerService.hide();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

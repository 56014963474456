import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { SelectedPermissionsService } from '../selected-permissions.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdAndName } from '@data/id-and-name.model';

@Component({
    selector: 'promo-permission-section',
    templateUrl: './permission-section.component.html',
    styleUrls: ['./permission-section.component.scss']
})
export class PermissionSectionComponent implements OnChanges {
    @Input()
    public permissions: IdAndName[];
    @Input()
    public depth = 0;

    public sections: {
        [key: string]: IdAndName[];
    };

    public depthPadding = 24;

    constructor(private selectedPermissionsService: SelectedPermissionsService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.permissions) {
            this.sections = this.getPermissionSections(this.permissions);
        }
    }

    public getPermissionSections(permissions: IdAndName[]): { [key: string]: IdAndName[] } {
        if (!permissions) {
            return {};
        }
        const names = permissions.map(permission => permission.name).sort();
        const permissionSections: Set<string> = new Set();
        names.forEach(name => {
            permissionSections.add(name.split('/', 1)[0]);
        });

        return Array.from(permissionSections).reduce((sections, section) => {
            sections[section] = this.getChildren(section);
            return sections;
        }, {});
    }

    private getChildren(section: string): IdAndName[] {
        const sectionLengthWithSlash = section.length + 1;
        return this.permissions
            .filter(x => x.name.startsWith(`${section}/`))
            .map(x => {
                return {
                    ...x,
                    name: x.name.slice(sectionLengthWithSlash)
                };
            });
    }

    public onCheckboxChanged(section: string, checked: boolean): void {
        if (this.doesSectionHaveChildren(section)) {
            const childrenIds = this.sections[section].map(x => x.id);
            this.selectedPermissionsService.setMultiplePermissions(childrenIds, checked);
        } else {
            const permission = this.permissions.find(x => x.name === section);
            this.selectedPermissionsService.setPermission(permission.id, checked);
        }
    }

    public isSectionChecked(section: string): Observable<boolean> {
        return this.selectedPermissionsService.selectedPermissions$.pipe(
            map(selectedPermissions => {
                if (this.doesSectionHaveChildren(section)) {
                    return this.sections[section].every(permission => {
                        return selectedPermissions[permission.id] === true;
                    });
                } else {
                    const permission = this.permissions.find(x => x.name === section);
                    return selectedPermissions[permission.id] === true;
                }
            })
        );
    }

    public isSectionIndeterminate(section: string): Observable<boolean> {
        return this.selectedPermissionsService.selectedPermissions$.pipe(
            map(selectedPermissions => {
                if (this.doesSectionHaveChildren(section)) {
                    const filteredResults = this.sections[section].filter(permission => {
                        return selectedPermissions[permission.id] === true;
                    });
                    return filteredResults.length > 0 && filteredResults.length !== this.sections[section].length;
                } else {
                    return false;
                }
            })
        );
    }

    private doesSectionHaveChildren(section: string): boolean {
        return this.sections[section].length > 0;
    }
}

import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { Resolution, MediaDetails } from '@data/media-details.model';
import { PromotionTypes } from '@data/enums/promotion-types.enum';

@Component({
    selector: 'promo-media-display',
    templateUrl: './media-display.component.html',
    styleUrls: ['./media-display.component.scss']
})
export class MediaDisplayComponent implements OnInit, OnChanges {
    @Input()
    public mediaUrl: string;
    @Input()
    public height: string;
    @Input()
    public showVideoControls = false;
    @Input()
    public selected = false;
    @Input()
    public showVideos = true;
    @Input()
    public showImages = true;

    @Output()
    public mediaDetails: EventEmitter<MediaDetails> = new EventEmitter<MediaDetails>();

    public showImage = false;
    public showVideo = false;

    public mediaHeight: number;
    public mediaWidth: number;
    public mediaDuration: number | undefined;

    public currentMediaUrl: string;

    constructor() {}

    public ngOnInit(): void {}

    public ngOnChanges(changes: SimpleChanges): void {
        if ('mediaUrl' in changes) {
            const mediaUrlWithoutQuery = this.mediaUrl.split('?', 1)[0];
            const currentMediaUrlWithoutQuery = this.currentMediaUrl ? this.currentMediaUrl.split('?', 1)[0] : undefined;
            if (mediaUrlWithoutQuery !== currentMediaUrlWithoutQuery) {
                this.currentMediaUrl = this.mediaUrl;
            }
        }
    }

    public imageLoaded(event: Event, type: 'image' | 'video'): void {
        if (type === 'image') {
            this.mediaHeight = (event.target as HTMLImageElement).naturalHeight;
            this.mediaWidth = (event.target as HTMLImageElement).naturalWidth;
            this.mediaDuration = undefined;
            this.showImage = true;
        }
        if (type === 'video') {
            this.mediaHeight = (event.target as HTMLVideoElement).videoHeight;
            this.mediaWidth = (event.target as HTMLVideoElement).videoWidth;
            this.mediaDuration = (event.target as HTMLVideoElement).duration;
            this.showVideo = true;
        }

        this.mediaDetails.emit({
            type: type === 'image' ? PromotionTypes.Image : PromotionTypes.Video,
            resolution: {
                height: this.mediaHeight,
                width: this.mediaWidth
            },
            duration: this.mediaDuration
        });
    }
}

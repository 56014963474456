// tslint:disable:no-null-keyword
import { AbstractControl, ValidationErrors, Validators, FormControl } from '@angular/forms';

export const validateMultipleEmails = (control: AbstractControl): ValidationErrors => {
    if (!control.value) {
        return null;
    }

    const emails: string[] = control.value.split(',').map((email: string) => email.trim());

    for (const email of emails) {
        const tempControl = new FormControl('');

        tempControl.setValue(email.trim());

        const validationError = Validators.email(tempControl);

        if (validationError) {
            return validationError;
        }
    }

    return null;
};

import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, CommonModule, RouterModule, SharedModule, TranslateModule],
    declarations: [CreatePasswordComponent, ForgotPasswordComponent, LoginComponent, RegisterComponent, VerifyAccountComponent]
})
export class AuthModule {}

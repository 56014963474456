import { PromotionLibraryComponent } from './promotion-library.component';
import { PromotionTemplateComponent } from '@pages/promotion-library/promotion-template/promotion-template.component';
import { EditPromotionComponent } from './edit-promotion/edit-promotion.component';

export const promotionRoutes = [
    {
        path: '',
        component: PromotionLibraryComponent
    },
    {
        path: 'create',
        component: PromotionTemplateComponent
    },
    {
        path: ':id/edit',
        component: EditPromotionComponent
    }
];

import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { SelectedPermissionsService } from './selected-permissions.service';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseComponent } from '@utils/base.component';
import { IdAndName } from '@data/id-and-name.model';

@Component({
    selector: 'promo-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss'],
    providers: [SelectedPermissionsService]
})
export class PermissionsComponent extends BaseComponent implements OnInit, OnChanges {
    @Input()
    public title: string;
    @Input()
    public permissions: string[] = [];
    @Input()
    public selectedPermissionIds: string[] = [];

    @Output()
    public selectedPermissionIdsChanged = new EventEmitter<string[]>();
    @Output()
    public permissionsModified = new EventEmitter<boolean>();

    public permissionsChanged$: Observable<boolean>;

    public formattedPermissions: IdAndName[];

    constructor(private selectedPermissionsService: SelectedPermissionsService) {
        super();
        this.permissionsChanged$ = this.selectedPermissionsService.permissionsHasChanged$;
    }

    public ngOnInit(): void {
        this.selectedPermissionsService.setAllPermissions(this.permissions);
        this.selectedPermissionsService.resetPermissions(this.selectedPermissionIds);
        this.selectedPermissionsService.selectedPermissionIds$.pipe(takeUntil(this.onDestroy$)).subscribe(ids => {
            this.selectedPermissionIdsChanged.emit(ids);
        });
        this.selectedPermissionsService.permissionsHasChanged$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(hasChanged => this.permissionsModified.emit(hasChanged));
        this.formattedPermissions = this.formatPermissions(this.permissions);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.permissions) {
            this.selectedPermissionsService.setAllPermissions(this.permissions);
            this.formattedPermissions = this.formatPermissions(this.permissions);
        }
        if (changes.selectedPermissionIds) {
            this.selectedPermissionsService.resetPermissions(this.selectedPermissionIds);
        }
    }

    public resetPermissions(): void {
        this.selectedPermissionsService.resetPermissions(this.selectedPermissionIds);
    }

    private formatPermissions(permissions: string[]): IdAndName[] {
        if (permissions) {
            return permissions.map(permission => {
                return {
                    id: permission,
                    name: permission
                };
            });
        }
        return [];
    }
}

import { Tag } from '@data/tag.model';
import {
    EntityChangeTracker,
    EntityAction,
    EntityDefinition,
    EntityCollectionReducerMethodMap,
    EntityDefinitionService,
    EntityCollection,
    EntityCollectionReducer
} from '@ngrx/data';
import { PromokioEntityCollection } from '@stores/collections/promokio-entity-collection';
import { PromokioEntityCollectionReducerMethods } from './promokio-entity-collection-reducer-methods';
import { Injectable } from '@angular/core';

export class TagReducers extends PromokioEntityCollectionReducerMethods<Tag> {
    constructor(public entityName: string, public definition: EntityDefinition<Tag>, entityChangeTracker?: EntityChangeTracker<Tag>) {
        super(entityName, definition, entityChangeTracker);
    }

    protected queryAllSuccess(collection: PromokioEntityCollection<Tag>, action: EntityAction<Tag[]>): PromokioEntityCollection<Tag> {
        const newCollection = super.queryAllSuccess(collection, action);

        const data = this.extractData(action);
        const companyId = action.payload.correlationId;

        const tagIds = data.map(x => x.id);
        const missingKeys = Object.keys(newCollection.entities).filter(tagId => {
            const tag = newCollection.entities[tagId];
            if (tag.company !== companyId || (tag.company === companyId && tagIds.includes(tagId))) {
                return false;
            }
            return true;
        });

        const newEntities = newCollection.entities;
        missingKeys.forEach(key => {
            delete newEntities[key];
        });

        return {
            ...newCollection,
            entities: newEntities,
            ids: Object.keys(newEntities)
        };
    }
}

@Injectable()
export class TagCollectionReducerMethodsFactory {
    constructor(private entityDefinitionService: EntityDefinitionService) {}

    public create(): EntityCollectionReducerMethodMap<Tag> {
        const definition = this.entityDefinitionService.getDefinition<Tag>('Tag');
        const methodsClass = new TagReducers('Tag', definition);

        return methodsClass.methods;
    }
}

@Injectable()
export class TagCollectionReducerFactory {
    constructor(private methodsFactory: TagCollectionReducerMethodsFactory) {}

    public create(): EntityCollectionReducer<Tag> {
        const methods = this.methodsFactory.create();

        const tagCollectionReducer = (collection: EntityCollection<Tag>, action: EntityAction): EntityCollection<Tag> => {
            const reducerMethod = methods[action.payload.entityOp];
            return reducerMethod ? reducerMethod(collection, action) : collection;
        };

        return tagCollectionReducer;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Promotion } from '../data/promotion.model';
import { AppConfigService } from './app-config.service';
import { Response, LastEvaluatedKey } from '../data/response.model';

@Injectable({
    providedIn: 'root'
})
export class PromotionsService {
    private APIGateway = '';
    private S3BucketImages = '';
    private endpoint = '/promotions';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
        this.appConfigService.S3BucketImages.subscribe(x => (this.S3BucketImages = x));
    }

    public getPromotions(lastEvaluatedKey?: LastEvaluatedKey): Observable<Response<Promotion>> {
        let filters = '';
        if (lastEvaluatedKey) {
            filters = `?date=${lastEvaluatedKey.date}&id=${lastEvaluatedKey.id}&company=${lastEvaluatedKey.company}`;
        }
        return this.http.get<Response<Promotion>>(this.APIGateway + this.endpoint + filters);
    }

    public getPromotion(id: string): Observable<Response<Promotion>> {
        return this.http.get<Response<Promotion>>(this.APIGateway + this.endpoint + '/' + id);
    }

    public createPromotion(promotion: Promotion): Observable<Response<Promotion>> {
        return this.http.post<Response<Promotion>>(this.APIGateway + this.endpoint, promotion);
    }

    public updatePromotion(promotion: Promotion): Observable<Response<Promotion>> {
        return this.http.post<Response<Promotion>>(this.APIGateway + this.endpoint, promotion);
    }

    // tslint:disable-next-line:no-any
    public archivePromotion(id: string): Observable<any> {
        return this.http.delete(this.APIGateway + this.endpoint + '/' + id);
    }

    // tslint:disable-next-line:no-any
    public uploadImage(key: string, body: Blob, contentType: string): Observable<any> {
        const bucketData = {
            Bucket: this.S3BucketImages,
            Key: key,
            Body: body,
            ContentEncoding: 'base64',
            ContentType: `image/${contentType}`
        };
        return this.http.post(this.APIGateway + this.endpoint + '/images', bucketData);
    }
}

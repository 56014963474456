import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy, HostListener, EventEmitter, OnChanges } from '@angular/core';
import { Playlist } from '@data/playlist.model';
import { Orientations } from '@data/enums/orientations.enum';
import { Promotion } from '@data/promotion.model';
import { MatTabGroup } from '@angular/material';
import { ColourUtils } from '@utils/colour.utils';
import { PromotionTypes } from '@data/enums/promotion-types.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'promo-preview-panel',
    templateUrl: './preview-panel.component.html',
    styleUrls: ['./preview-panel.component.scss']
})
export class PreviewPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    public playlist: Playlist;
    @Input()
    public orientation: Orientations;
    @Input()
    public tabChange: EventEmitter<number>;

    public promotions: Promotion[];

    public orientations = Orientations;

    public promotionTypes = PromotionTypes;

    public originalHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
    public originalWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

    public aspectRatio: [number, number];

    @ViewChild(MatTabGroup)
    public tabGroup: MatTabGroup;

    private timeoutId: number;

    constructor() {}

    public ngOnInit(): void {
        this.promotions = this.playlist.promotions.filter(x => {
            if (x.type === PromotionTypes.Promotion) {
                if (this.orientation === Orientations.Landscape) {
                    return !!x.landscapeImage;
                } else {
                    return !!x.portraitImage;
                }
            } else {
                return true;
            }
        });

        const aspectRatioLandscapeWidth = 16;
        const aspectRatioLandscapeHeight = 9;
        if (this.orientation === Orientations.Landscape) {
            this.aspectRatio = [aspectRatioLandscapeWidth, aspectRatioLandscapeHeight];
        } else {
            this.aspectRatio = [aspectRatioLandscapeHeight, aspectRatioLandscapeWidth];
        }
    }

    public ngAfterViewInit(): void {
        this.setTimeoutForPromotion();
        this.setOriginalHeightAndWidth();

        this.tabGroup.selectedIndexChange.subscribe(() => {
            this.setTimeoutForPromotion();
        });
        this.tabChange.subscribe(() => {
            this.setOriginalHeightAndWidth();
        });
    }

    public setNextTab(increment: number = 1): void {
        this.tabGroup.selectedIndex = (this.tabGroup.selectedIndex + increment + this.promotions.length) % this.promotions.length;
    }

    public setTimeoutForPromotion(): void {
        const ms = 1000;
        if (this.timeoutId) {
            this.clearTimeout();
        }
        if (this.promotions[this.tabGroup.selectedIndex].duration) {
            this.timeoutId = window.setTimeout(() => {
                this.setNextTab();
            }, this.promotions[this.tabGroup.selectedIndex].duration * ms);
        }
    }

    public ngOnDestroy(): void {
        this.clearTimeout();
    }

    public getPromotionUrl(promotion: Promotion): string {
        if (this.orientation === Orientations.Landscape) {
            return promotion.landscapeImage as string;
        } else {
            return promotion.portraitImage as string;
        }
    }

    public isCurrentPromotionSwipeable(): boolean {
        return this.playlist.swipingEnabled && this.promotions[this.tabGroup.selectedIndex].swipingEnabled;
    }

    @HostListener('document:keydown.arrowleft', ['$event'])
    public previousTab(): void {
        if (this.isCurrentPromotionSwipeable()) {
            this.clearTimeout();
            this.setNextTab(-1);
            this.setTimeoutForPromotion();
        }
    }

    @HostListener('document:keydown.arrowright', ['$event'])
    public nextTab(): void {
        if (this.isCurrentPromotionSwipeable()) {
            this.clearTimeout();
            this.setNextTab();
            this.setTimeoutForPromotion();
        }
    }

    @HostListener('window:resize')
    public onResize(): void {
        this.setOriginalHeightAndWidth();
    }

    public clearTimeout(): void {
        if (this.timeoutId) {
            window.clearTimeout(this.timeoutId);
        }
    }

    public getColour(colour: string): string {
        if (!colour) {
            return '#ffffffff';
        }
        return ColourUtils.convertFromARGBtoRGBA(colour);
    }

    public promotionHasCTA(promotion: Promotion): boolean {
        return !!(promotion.ctaButtons && promotion.ctaButtons.length && promotion.ctaButtons[0].id);
    }

    private setOriginalHeightAndWidth(): void {
        const boundingRect = this.tabGroup._elementRef.nativeElement.getBoundingClientRect();

        requestAnimationFrame(() => {
            this.originalHeight$.next(boundingRect.height);
            this.originalWidth$.next(boundingRect.width);
        });
    }
}

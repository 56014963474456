import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Interaction, InteractionTableItem } from '@data/interaction.model';
import { OrderOptions } from '@data/order.model';
import { TableColumn } from '@data/table-column.model';
import { TranslateService } from '@ngx-translate/core';
import { InteractionsService } from '@services/interactions.service';
import { SpinnerService } from '@services/spinner.service';
import { EMPTY, interval, Subscription, Observable } from 'rxjs';
import { catchError, map, filter, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { OnDestroy, OnInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { AppConfigService } from '@services/app-config.service';
import { CompanyService } from '@services/company/company.service';
import moment from 'moment';
import 'moment-duration-format';

@Component({
    selector: 'promo-interaction-table',
    templateUrl: './interaction-table.component.html',
    styleUrls: ['./interaction-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class InteractionTableComponent implements OnInit, OnDestroy {
    private _data: InteractionTableItem[];
    public currentTimeStamp = new Date().getTime();
    private timestampSub: Subscription;
    private elapsedTimeRefreshRate: number;

    public defaultInteractionWaitTime$: Observable<number>;
    public companyWaitTime$: Observable<number>;

    get data(): InteractionTableItem[] {
        return this._data;
    }

    @Input('data')
    set data(value: InteractionTableItem[]) {
        if (value) {
            value.forEach((interaction, index) => {
                interaction.emailSkipped = interaction.emailSubmissionEnabled ? (!interaction.email ? 'Yes' : 'No') : '-';
                interaction.index = index;
                return interaction;
            });
            this._data = value;
        } else {
            this._data = [];
        }
    }

    private _displayType: boolean;
    get displayType(): boolean {
        return this._displayType;
    }

    @Input('displayType')
    set displayType(value: boolean) {
        setTimeout(() => {
            this._displayType = value;
            if (value) {
                this.displayedColumns.splice(1, 0, 'type');
                this.columns.splice(1, 0, {
                    id: 'type',
                    label: 'Type',
                    type: 'string'
                });
                this.columnsToDisplay = this.columns.map(column => column.id);
            }
        });
    }

    public displayedColumns: string[] = ['name', 'tag', 'group', 'device', 'dateTime', 'emailSkipped', 'waitTime', 'actions'];

    public columns: TableColumn[] = [
        {
            id: 'name',
            label: 'Name',
            type: 'string'
        },
        {
            id: 'promoTag',
            label: 'Promotion Tag',
            type: 'string'
        },
        {
            id: 'groupName',
            label: 'Group Name',
            type: 'string'
        },
        {
            id: 'device',
            label: 'Device Name',
            type: 'string'
        },
        {
            id: 'date',
            label: 'Date',
            type: 'date'
        },
        {
            id: 'emailSkipped',
            label: 'Email Skipped?',
            type: 'string'
        },
        {
            id: 'waitTime',
            label: 'Wait Time',
            type: 'timer'
        },
        {
            id: 'actions',
            label: '',
            type: 'button'
        }
    ];
    public columnsToDisplay = this.columns.map(column => column.id);
    public expandedElement: Interaction;

    constructor(
        private spinnerService: SpinnerService,
        private interactionsService: InteractionsService,
        private translateService: TranslateService,
        private appConfigService: AppConfigService,
        private companyService: CompanyService
    ) {
        this.appConfigService.InteractionWaitTimerRefreshRate.subscribe(x => (this.elapsedTimeRefreshRate = x));
    }

    public ngOnInit(): void {
        this.defaultInteractionWaitTime$ = this.appConfigService.DefaultInteractionWaitExpireTime;
        this.companyWaitTime$ = this.companyService.selectedCompany$.pipe(
            startWith(undefined),
            filter(x => x !== undefined),
            map(x => x.waitTime)
        );
        this.timestampSub = interval(this.elapsedTimeRefreshRate).subscribe(() => {
            this.currentTimeStamp = new Date().getTime();
        });
    }

    public showMedia(interaction: Interaction): void {
        let mediaUrl = interaction.portraitImage;
        let html = '';
        if (interaction.fileType.split('/')[0] === 'video') {
            html = `<video src='${mediaUrl}' width='100%' height='100%' loop autoplay></video>`;
        } else {
            html = `<img src='${mediaUrl}' width='100%' height='100%'></img>`;
        }
        if (interaction.deviceOrientation) {
            if (interaction.deviceOrientation === 'portrait') {
                mediaUrl = interaction.portraitImage;
            } else {
                mediaUrl = interaction.landscapeImage;
            }
        }
        const swalPromise = Swal.fire({
            text: this.hasMedia(mediaUrl as string),
            html,
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: '<div id=interactions-preview-button-close>Close</div>'
        });
    }

    public archive(item: InteractionTableItem): void {
        const swalPromise = Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to undo this change',
            showCancelButton: true,
            confirmButtonText: `<div id=interactions-archive-button-confirm>${this.translateService.instant(
                'Interactions.ConfirmResolve'
            )}</div>`,
            cancelButtonText: '<div id=interactions-archive-button-cancel>No, cancel</div>',
            imageUrl: '../../assets/swal2-warn.PNG',
            imageWidth: 100,
            imageHeight: 100
        }).then(result => {
            if (result.value) {
                this.spinnerService.show();
                item.resolved = true;
                this.interactionsService
                    .resolveInteraction(item)
                    .pipe(
                        catchError(() => {
                            this.spinnerService.hide();
                            return EMPTY;
                        })
                    )
                    .subscribe(x => {
                        // if (!this.showResolved) {
                        //   this.toggleResolved({ checked: false });
                        // }
                        this.spinnerService.hide();
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log('cancelled archive');
            }
        });
    }

    public hasMedia(imageURL: string): string {
        if (imageURL) {
            return undefined;
        }
        return 'No Media';
    }

    public getTotalQuantity(order: OrderOptions[]): number {
        return order.map(option => option.quantity).reduce((acc, value) => acc + value, 0);
    }

    public getElapsedTime(timestamp: Date): string {
        return moment.duration(moment(this.currentTimeStamp).diff(timestamp)).format('mm:ss');
    }

    public ngOnDestroy(): void {
        this.timestampSub.unsubscribe();
    }
}

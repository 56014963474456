import { Component, OnInit } from '@angular/core';
import { Group } from '@data/group.model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupsService } from 'src/app/services/groups.service';
import { map, switchMap, catchError } from 'rxjs/operators';
import { empty } from 'rxjs';

@Component({
    selector: 'promo-view-group',
    templateUrl: './view-group.component.html',
    styleUrls: ['./view-group.component.css']
})
export class ViewGroupComponent implements OnInit {
    public group: Group;
    private groupId: string;
    public displayedColumns: string[] = ['name', 'orientation'];

    constructor(
        private groupsService: GroupsService,
        private spinnerService: SpinnerService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.spinnerService.show();
        this.route.paramMap
            .pipe(
                map(params => {
                    return params.get('id');
                }),
                switchMap(groupId => {
                    this.groupId = groupId;
                    return this.groupsService.getGroup(groupId);
                }),
                catchError(err => {
                    this.spinnerService.hide();
                    return empty();
                })
            )
            .subscribe(response => {
                this.group = response.Items[0];
                this.spinnerService.hide();
            });
    }

    public deleteGroup(): void {
        this.groupsService
            .deleteGroup(this.groupId)
            .pipe(
                catchError(err => {
                    return empty();
                })
            )
            .subscribe(() => {
                const navigationPromise = this.router.navigate([`/groups`]);
            });
    }
}

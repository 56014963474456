import { Component, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { MediaUrls, Media } from '@data/media.model';
import { MediaDetails } from '@data/media-details.model';
import { FileTypes } from '@data/enums/file-types.enum';

@Component({
    selector: 'promo-media-library',
    templateUrl: './media-library.component.html',
    styleUrls: ['./media-library.component.scss']
})
export class MediaLibraryComponent implements OnChanges {
    @Input()
    public mediaUrls: MediaUrls;
    @Input()
    public selectedId: string;
    @Input()
    public acceptedFileTypes: FileTypes[];

    @Output()
    public mediaClicked: EventEmitter<MediaDetails> = new EventEmitter<MediaDetails>();

    public mediaIds: string[];

    public mediaDetails: {
        [id: string]: MediaDetails;
    } = {};

    public showImages = true;
    public showVideos = true;

    constructor() {}

    public ngOnChanges(): void {
        if (this.mediaUrls) {
            this.mediaIds = Object.keys(this.mediaUrls);
        } else {
            this.mediaIds = [];
        }
        if (this.acceptedFileTypes) {
            if (this.acceptedFileTypes.includes(FileTypes.MP4)) {
                this.showVideos = true;
            } else {
                this.showVideos = false;
            }

            if (this.acceptedFileTypes.includes(FileTypes.PNG) || this.acceptedFileTypes.includes(FileTypes.JPEG)) {
                this.showImages = true;
            } else {
                this.showImages = false;
            }
        }
    }

    public onMediaClicked(mediaId: string, mediaUrl: string): void {
        this.mediaClicked.emit({
            ...this.mediaDetails[mediaId],
            name: mediaId,
            url: mediaUrl
        });
    }

    public updateMediaDetails(mediaId: string, mediaDetails: MediaDetails): void {
        this.mediaDetails = {
            ...this.mediaDetails,
            [mediaId]: mediaDetails
        };
    }

    public trackByMediaId(index: number, mediaId: string): string {
        return mediaId;
    }
}

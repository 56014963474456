import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { Response } from '../data/response.model';
import { User } from '@data/user.model';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private APIGateway = '';
    private endpoint = '/users';

    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
        this.appConfigService.APIGateway.subscribe(x => (this.APIGateway = x));
    }

    // tslint:disable-next-line:no-any
    public getUsers(): Observable<Response<any>> {
        console.log(this.APIGateway);
        // tslint:disable-next-line:no-any
        return this.http.get<Response<any>>(this.APIGateway + this.endpoint);
    }

    // tslint:disable-next-line:no-any
    public getUser(id: string): Observable<any> {
        return this.http.get(this.APIGateway + this.endpoint + '/' + id);
    }

    // tslint:disable-next-line:no-any
    public createUser(user: User): Observable<Response<any>> {
        // tslint:disable-next-line:no-any
        return this.http.post<Response<any>>(this.APIGateway + this.endpoint, user);
    }

    // tslint:disable-next-line:no-any
    public updateUser(userId: string, updatedUser: User): Observable<Response<any>> {
        // tslint:disable-next-line:no-any
        return this.http.put<Response<any>>(this.APIGateway + this.endpoint + '/' + userId, updatedUser);
    }
}

export enum Roles {
    CompanyAdmin = 'CompanyAdmin',
    CompanyAccountHolder = 'CompanyAccountHolder',
    CompanyAnalyst = 'CompanyAnalyst',
    CompanyHost = 'CompanyHost',
    CompanyPromoter = 'CompanyPromoter',
    CompanyProvisioner = 'CompanyProvisioner',
    PromokioCompanyCreator = 'PromokioCompanyCreator',
    PromokioDataProcessor = 'PromokioDataProcessor',
    PromokioSalesPerson = 'PromokioSalesPerson',
    PromokioSuperUser = 'PromokioSuperUser'
}

import { Component, OnInit, OnChanges, Input, SimpleChanges, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PromotionTypes } from '@data/enums/promotion-types.enum';
import { MenuItem, Promotion } from '@data/promotion.model';
import { TableColumn } from '@data/table-column.model';
import { Tag } from '@data/tag.model';
import { CompanyService } from '@services/company/company.service';
import { SpinnerService } from '@services/spinner.service';
import { TagsService } from '@services/tags.service';
import { ColourUtils } from '@utils/colour.utils';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, take, takeUntil, filter, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { InstantTemplate } from '@data/instant-template.model';
import { PromotionUpdate } from '@pages/promotion-library/edit-promotion/edit-promotion.component';
import { Group } from '@data/group.model';
import { GroupsService } from '@services/groups.service';
import { Orientations } from '@data/enums/orientations.enum';
import { MediaSelectorComponent } from '@shared/media/media-selector/media-selector.component';
import { MediaDetails } from '@data/media-details.model';
import { Dimensions } from '@data/enums/dimensions.enum';
import { OverlayLoaderService } from '@services/overlay-loader/overlay-loader.service';
import { FileTypes } from '@data/enums/file-types.enum';
import { MediaUrls } from '@data/media.model';
import { MediaService } from '@services/media.service';

const urlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
const withHttp = (url: string): string => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
const defaultPromoDuration = 10;

@Component({
    selector: 'promo-edit-base-promotion',
    templateUrl: './edit-base-promotion.component.html',
    styleUrls: ['./edit-base-promotion.component.css']
})
export class EditBasePromotionComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public promotion: Promotion;
    @Input()
    public companyColour: string;

    @Output()
    public savePromotionChangesClicked: EventEmitter<PromotionUpdate> = new EventEmitter<PromotionUpdate>();
    @Output()
    public saveInstantTemplateChangesClicked: EventEmitter<InstantTemplate> = new EventEmitter<InstantTemplate>();
    @Output()
    public deletePromotionClicked: EventEmitter<void> = new EventEmitter<void>();

    public companyHasSwipingEnabled = false;
    public promotionalHeadlineTextColor = '#FFFFFFFF';
    public promotionalHeadlineBackgroundColor = '#55555500';
    public callToActionTextColor = '#555555FF';
    public callToActionBackgroundColor = '#FFFFFFFF';
    private maxLengthForCTA = 30;
    public instantGroups: Group[];
    public selectedInstantGroups: string[];
    public promotionForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        url: new FormControl(''),
        portraitImage: new FormControl(''),
        landscapeImage: new FormControl(''),
        tag: new FormControl('', [Validators.required]),
        promotionHeadline: new FormControl(''),
        callToAction: new FormControl('', Validators.maxLength(this.maxLengthForCTA)),
        lengthToStay: new FormControl('', Validators.min(1)),
        emailSubmissionEnabled: new FormControl(false),
        swipingEnabled: new FormControl(false),
        soundEnabled: new FormControl(false),
        companyFontEnabled: new FormControl(false),
        sendImmediately: new FormControl(false),
        instantGroup: new FormControl('')
    });
    public portraitUrl: string;
    public landscapeUrl: string;
    public portraitImageName: string;
    public landscapeImageName: string;
    public uploadedPortraitImage: string;
    public uploadedLandscapeImage: string;
    public portraitBase64Data: string;
    public landscapeBase64Data: string;
    public portraitUploadType: string;
    public landscapeUploadType: string;
    public portraitImageChanged = false;
    public landscapeImageChanged = false;

    public acceptedFileTypes: FileTypes[] = [];

    public portraitImage: File | string;
    public landscapeImage: File | string;
    private portraitDuration: number;
    private landscapeDuration: number;
    public lengthToStay: number;

    public orderOptions: MenuItem[] = [];
    public menuAdded: boolean;

    public displayedColumns: TableColumn[] = [
        { id: 'name', label: 'Menu Item', type: 'string' },
        { id: 'price', label: 'Price (£)', type: 'number' },
        { id: 'description', label: 'Description', type: 'string' }
    ];
    public selectedMenu: { menuSelection: MenuItem[]; isValidMenu: boolean };

    public emailSubmissionEnabled: boolean;
    public swipingEnabled = false;
    public isInfinite = false;
    public soundEnabled = false;
    public companyFontEnabled = false;
    public sendImmediately = false;
    private portraitWidth: number;
    private portraitHeight: number;
    private landscapeHeight: number;
    private landscapeWidth: number;

    public tags$: Observable<Tag[]>;

    private mediaIds$: Observable<string[]>;
    public mediaUrls$: Observable<MediaUrls>;

    private onDestroy$: Subject<void> = new Subject<void>();

    public orientations = Orientations;

    constructor(
        private spinnerService: SpinnerService,
        private router: Router,
        private tagsService: TagsService,
        private groupService: GroupsService,
        private overlayLoader: OverlayLoaderService<MediaSelectorComponent>,
        private mediaService: MediaService,
        private companyService: CompanyService
    ) {
        this.tags$ = this.tagsService.tags$;
        this.mediaIds$ = this.mediaService.mediaForSelectedCompany$;
    }

    public ngOnInit(): void {
        this.spinnerService.show();
        this.groupService
            .getGroups()
            .pipe(
                map(({ Items }) => {
                    this.instantGroups = Items;
                }),
                catchError(() => {
                    this.spinnerService.hide();
                    return EMPTY;
                })
            )
            .subscribe(() => {
                if (this.promotion.type === PromotionTypes.Instant) {
                    this.promotionForm.get('instantGroup').setValue(
                        this.instantGroups.filter(ins => {
                            if (!this.promotion.groups) {
                                return undefined;
                            }
                            return this.promotion.groups.indexOf(ins.id) > -1;
                        })
                    );
                }
                this.spinnerService.hide();
            });
        this.mediaService.getByKey('media');
        this.mediaUrls$ = this.mediaIds$.pipe(
            filter(ids => !!(ids && ids.length)),
            switchMap(ids => {
                return this.mediaService.getUrlsForKeys(ids);
            })
        );
        this.companyService.selectedCompany$.subscribe(company => {
            if (!company) {
                this.companyService.getAll();
            } else {
                this.companyHasSwipingEnabled = company.swipingEnabled;
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.promotion !== undefined && changes['promotion'].isFirstChange) {
            this.spinnerService.hide();
            this.acceptedFileTypes = this.promotion.fileType === FileTypes.MP4 ? [FileTypes.MP4] : [FileTypes.PNG, FileTypes.JPEG];
            this.orderOptions = this.promotion.orderOptions;
            this.menuAdded = this.orderOptions && this.orderOptions.length ? true : false;
            this.emailSubmissionEnabled = this.promotion.emailSubmissionEnabled;
            this.swipingEnabled = this.promotion.swipingEnabled;
            this.isInfinite = !this.promotion.duration;
            this.isInfinite ? this.promotionForm.get('lengthToStay').disable() : this.promotionForm.get('lengthToStay').enable();
            if (this.promotion.type === PromotionTypes.URL) {
                this.promotionForm.get('url').setValidators([Validators.required, Validators.pattern(urlRegex)]);
            }
            if (this.promotion.type === PromotionTypes.Instant) {
                this.promotionForm.get('instantGroup').setValidators([Validators.required]);
            }
            this.populateForm();
            this.spinnerService.hide();
            this.soundEnabled = this.promotion.soundEnabled;
            this.companyFontEnabled = this.promotion.companyFontEnabled;
            this.selectedInstantGroups = this.promotion.groups;
        }
    }

    public onSaveClicked(): void {
        this.setPromotionValues();
        if (this.promotion.type === PromotionTypes.Instant) {
            this.saveInstantTemplateChangesClicked.emit((this.promotion as unknown) as InstantTemplate);
        } else {
            this.savePromotionChangesClicked.emit({
                promotion: this.promotion,
                portraitDuration: this.portraitDuration,
                landscapeDuration: this.landscapeDuration
            });
        }
    }

    public archive(): void {
        const swalPromise = Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to undo this change',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'No, cancel',
            imageUrl: '../../assets/swal2-warn.PNG',
            imageWidth: 100,
            imageHeight: 100
        }).then(result => {
            if (result.value) {
                this.deletePromotionClicked.emit();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                console.log('cancelled deletion');
            }
        });
    }

    public populateForm(): void {
        this.promotionForm.get('name').setValue(this.promotion.name);
        this.promotionForm.get('url').setValue(this.promotion.url);
        this.promotionForm.get('tag').setValue(this.promotion.tag);
        this.promotionForm.get('promotionHeadline').setValue(this.promotion.advertText);
        this.promotionForm
            .get('callToAction')
            .setValue(this.promotion.ctaButtons && this.promotion.ctaButtons.length ? this.promotion.ctaButtons[0].id : undefined);
        this.promotionalHeadlineTextColor = ColourUtils.convertFromARGBtoRGBA(this.promotion.textColor);
        this.promotionalHeadlineBackgroundColor = ColourUtils.convertFromARGBtoRGBA(this.promotion.backgroundColor);
        this.callToActionTextColor = ColourUtils.convertFromARGBtoRGBA(this.promotion.ctaTextColor);
        this.callToActionBackgroundColor = ColourUtils.convertFromARGBtoRGBA(this.promotion.ctaButtonColor);
        this.promotionForm.get('lengthToStay').setValue(this.promotion.duration);
        this.promotionForm.get('emailSubmissionEnabled').setValue(this.promotion.emailSubmissionEnabled);
        this.promotionForm.get('swipingEnabled').setValue(this.promotion.swipingEnabled);
        this.promotionForm.get('soundEnabled').setValue(this.promotion.soundEnabled);
        this.promotionForm.get('companyFontEnabled').setValue(this.promotion.companyFontEnabled);
    }

    public cancel(): void {
        if (this.promotion.type === PromotionTypes.Instant) {
            const navigationPromise = this.router.navigate(['/instants']);
        } else {
            const navigationPromise = this.router.navigate(['/promotions']);
        }
    }

    public setPromotionValues(): void {
        const defaultDuration = 10;
        this.promotion.name = this.promotionForm.get('name').value;
        this.promotion.url = withHttp(this.promotionForm.get('url').value);
        this.promotion.tag = this.promotionForm.get('tag').value;
        this.promotion.advertText = this.promotionForm.get('promotionHeadline').value;
        const callToAction = this.promotionForm.get('callToAction');
        (this.promotion.ctaButtons = callToAction.value
            ? [
                  {
                      label: 'cta_order_now',
                      id: callToAction.value
                  }
              ]
            : []),
            (this.promotion.swipingEnabled = this.promotionForm.get('swipingEnabled').value);
        this.promotion.textColor = ColourUtils.convertFromRGBAtoARGB(this.promotionalHeadlineTextColor);
        this.promotion.backgroundColor = ColourUtils.convertFromRGBAtoARGB(this.promotionalHeadlineBackgroundColor);
        this.promotion.ctaButtonColor = ColourUtils.convertFromRGBAtoARGB(this.callToActionBackgroundColor);
        this.promotion.ctaTextColor = ColourUtils.convertFromRGBAtoARGB(this.callToActionTextColor);
        this.promotion.duration = this.isInfinite ? undefined : this.promotionForm.get('lengthToStay').value || defaultDuration;
        this.promotion.emailSubmissionEnabled = this.selectedMenu ? false : this.promotionForm.get('emailSubmissionEnabled').value;
        this.promotion.orderOptions = this.selectedMenu && !this.emailSubmissionEnabled ? this.selectedMenu.menuSelection : undefined;
        this.promotion.soundEnabled = this.promotionForm.get('soundEnabled').value;
        this.promotion.companyFontEnabled = this.promotionForm.get('companyFontEnabled').value;
        this.promotion.groups = this.selectedInstantGroups;
    }

    public filePrompt(orientation: string): void {
        document.getElementById(orientation + '-input-file').click();
    }

    public removeMedia(orientation: string): void {
        if (orientation === 'portrait') {
            this.promotion.portraitImage = undefined;
        } else {
            this.promotion.landscapeImage = undefined;
        }
    }

    public portraitUploadComplete(selectedFile: File): void {
        if (selectedFile) {
            this.portraitImage = selectedFile;
            this.portraitImageChanged = true;
        } else {
            this.portraitImage = undefined;
        }
    }

    public landscapeUploadComplete(selectedFile: File): void {
        if (selectedFile) {
            this.landscapeImage = selectedFile;
            this.landscapeImageChanged = true;
        } else {
            this.landscapeImage = undefined;
        }
    }

    public setAspectRatio(size: number, orientation: Orientations, dimensions: Dimensions): void {
        if (orientation === Orientations.Portrait) {
            if (dimensions === Dimensions.Height) {
                this.portraitHeight = size;
            } else {
                this.portraitWidth = size;
            }
        } else {
            if (dimensions === Dimensions.Height) {
                this.landscapeHeight = size;
            } else {
                this.landscapeWidth = size;
            }
        }
    }

    public setDuration(duration: number, orientation: Orientations): void {
        const roundedDuration = Math.round(duration);
        this.lengthToStay = roundedDuration;
        if (orientation === Orientations.Portrait) {
            this.portraitDuration = roundedDuration;
        } else {
            this.landscapeDuration = roundedDuration;
        }
        if (Number.isNaN(this.landscapeDuration) && Number.isNaN(this.portraitDuration)) {
            this.lengthToStay = undefined;
        }
        if (Number.isNaN(this.landscapeDuration) && !Number.isNaN(this.portraitDuration)) {
            this.lengthToStay = this.portraitDuration;
        }
        if (Number.isNaN(this.portraitDuration) && !Number.isNaN(this.landscapeDuration)) {
            this.lengthToStay = this.landscapeDuration;
        }
    }

    // tslint:disable-next-line: no-any
    public changeGroup(event: any): void {
        this.selectedInstantGroups = event.value.map((group: Group) => group.id);
    }

    public toggleMenu(): void {
        this.menuAdded = !this.menuAdded;
    }

    public toggleInfiniteDuration(): void {
        this.isInfinite = !this.isInfinite;
        this.promotionForm.get('lengthToStay').disabled
            ? this.promotionForm.get('lengthToStay').enable()
            : this.promotionForm.get('lengthToStay').disable();
        this.setLengthToStayToMatchIsInfinite();
    }

    private setLengthToStayToMatchIsInfinite(): void {
        if (this.isInfinite) {
            this.lengthToStay = undefined;
        }
    }

    public toggleEmailSubmission(): void {
        this.selectedMenu = undefined;
        this.emailSubmissionEnabled = !this.emailSubmissionEnabled;
    }

    public getDisplayedColumns(): string[] {
        return this.displayedColumns.map(x => x.id);
    }

    public menuSelectionChange(event: { menuSelection: MenuItem[]; isValidMenu: boolean }): void {
        this.emailSubmissionEnabled = false;
        this.selectedMenu = event;
    }

    public toggleSoundEnabled(): void {
        this.soundEnabled = !this.soundEnabled;
    }

    public toggleCompanyFontEnabled(): void {
        this.companyFontEnabled = !this.companyFontEnabled;
    }

    public toggleSendImmediately(): void {
        this.sendImmediately = !this.sendImmediately;
    }

    public openMediaSelectorModal(orientation: Orientations): void {
        const mediaSelector = this.overlayLoader.show(MediaSelectorComponent);
        mediaSelector.mediaUrls$ = this.mediaUrls$;
        mediaSelector.acceptedFileTypes = this.acceptedFileTypes;

        const mediaSelectorClosedSub = mediaSelector.modalClosed
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe((event: MediaDetails | undefined) => {
                if (event) {
                    if (orientation === Orientations.Portrait) {
                        this.promotion.portraitImage = event.url;
                    } else {
                        this.promotion.landscapeImage = event.url;
                    }
                    this.setDuration(event.duration, orientation);
                    if (!event.duration) {
                        this.promotionForm.get('lengthToStay').setValue(defaultPromoDuration);
                    }
                    this.setAspectRatio(event.resolution.height, orientation, Dimensions.Height);
                    this.setAspectRatio(event.resolution.width, orientation, Dimensions.Width);
                }
                this.overlayLoader.hide();
            });

        this.overlayLoader.overlayClosedEvents$
            .pipe(
                take(1),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => mediaSelectorClosedSub.unsubscribe());
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}

import { NgModule } from '@angular/core';
import { CreateGroupComponent } from './create-group/create-group.component';
import { DeviceGroupsComponent } from './device-groups.component';
import { ViewGroupComponent } from './view-group/view-group.component';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [DeviceGroupsComponent, ViewGroupComponent, CreateGroupComponent],
    imports: [SharedModule, CommonModule, RouterModule, ReactiveFormsModule]
})
export class GroupsModule {}

import { Component } from '@angular/core';
import { BaseComponent } from '@utils/base.component';
import { AdminTabs } from '@pages/admin/admin-tabs.enum';

@Component({
    selector: 'promo-add-edit-company-role',
    templateUrl: './add-edit-company-role.component.html',
    styleUrls: ['./add-edit-company-role.component.scss']
})
export class AddEditCompanyRoleComponent extends BaseComponent {
    public adminTabs = AdminTabs;
    public tabKey = 'CompanyRoles';
    public urlKey = 'companyRoles';

    constructor() {
        super();
    }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { User } from '../data/user.model';
import { CognitoUtils } from '../utils/cognitoUtils';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { EntityCacheDispatcher } from '@ngrx/data';
import { take, map, shareReplay, switchMap } from 'rxjs/operators';

// https://docs.aws.amazon.com/cognito/latest/developerguide/using-amazon-cognito-user-identity-pools-javascript-examples.html

@Injectable({
    providedIn: 'root'
})
export class CognitoService {
    public user: User;
    public verifyMe: boolean;
    public userPool$: Observable<AmazonCognitoIdentity.CognitoUserPool>;
    public session: AmazonCognitoIdentity.CognitoUserSession;
    private isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoggedIn$ = this.isLoggedIn.asObservable();

    constructor(
        private toastrService: ToastrService,
        private router: Router,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private entityCacheDispatcher: EntityCacheDispatcher
    ) {
        this.userPool$ = this.appConfigService.poolData.pipe(
            map(x => {
                return new AmazonCognitoIdentity.CognitoUserPool(x);
            }),
            take(1),
            shareReplay(1)
        );
    }

    public login(loginInput: string, password: string): Observable<boolean> {
        const login = loginInput.toLowerCase();
        return this.getUserData(login).pipe(
            switchMap(user => {
                const cognitoUser = new AmazonCognitoIdentity.CognitoUser(user);
                cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
                const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(CognitoUtils.getAuthDetails(login, password));
                return new Observable<boolean>((obs): void => {
                    cognitoUser.authenticateUser(authenticationDetails, {
                        onSuccess: (result): void => {
                            this.session = result;
                            const email = this.session.getIdToken().payload.email;
                            this.user = {
                                username: this.session.getAccessToken().payload.username,
                                email
                            };
                            const token = result.getIdToken().getJwtToken();
                            const accessToken = result.getAccessToken().getJwtToken();
                            const refreshToken = result.getRefreshToken().getToken();
                            localStorage.setItem('accessToken', accessToken);
                            localStorage.setItem('idToken', token);
                            localStorage.setItem('refreshToken', refreshToken);
                            localStorage.setItem('userEmail', email);
                            const navigationPromise = this.router.navigate(['home']);
                            obs.next(true);
                            obs.complete();
                            this.isLoggedIn.next(true);
                        },
                        onFailure: (err): void => {
                            if (err.code === 'UserNotConfirmedException') {
                                this.verifyMe = true;
                                const navigationPromise = this.router.navigate(['/verifyaccount']);
                                this.resendCode(login);
                            } else {
                                this.toastrService.error('Invalid Credentials', 'Oops!');
                            }
                            obs.next(false);
                            obs.complete();
                            this.isLoggedIn.next(false);
                        },
                        newPasswordRequired: (): void => {
                            this.verifyMe = true;
                            const navigationPromise = this.router.navigate(['/createpassword'], {
                                state: { login, password }
                            });
                            obs.next(false);
                            obs.complete();
                            this.isLoggedIn.next(false);
                        }
                    });
                });
            })
        );
    }

    public signUp(usernameInput: string, password: string): void {
        const username = usernameInput.toLowerCase();
        this.userPool$.subscribe(userPool => {
            userPool.signUp(username, password, undefined, undefined, (err, _) => {
                if (err) {
                    this.toastrService.error('Something went wrong, please try again', 'Oops!');
                    return;
                }
                this.toastrService.warning('Please check your email for your verification code', 'Please verify');
                const navigationPromise = this.router.navigate(['/verifyaccount']);
            });
        });
    }

    public confirmNewPassword(newPassword: string, tempPassword: string, usernameInput: string): void {
        const username = usernameInput.toLowerCase();
        const userData$ = this.getUserData(username);
        userData$.subscribe(userData => {
            if (!userData) {
                this.toastrService.error("That user doesn't exist.", 'Oh dear...');
            }

            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

            cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
                CognitoUtils.getAuthDetails(username, tempPassword)
            );

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (): void => {
                    this.toastrService.info('That user already exists. Your password has not been changed.', 'Hang on...');
                    const navigationPromise = this.router.navigate(['/login']);
                },
                onFailure: (err): void => {
                    this.toastrService.error(err, 'Oops!');
                    const navigationPromise = this.router.navigate(['/login']);
                },
                newPasswordRequired: (): void => {
                    cognitoUser.completeNewPasswordChallenge(
                        newPassword,
                        {},
                        {
                            onSuccess: (result): void => {
                                this.session = result;
                                this.user = {
                                    username: this.session.getAccessToken().payload.username
                                };
                                const token = result.getIdToken().getJwtToken();
                                const accessToken = result.getAccessToken().getJwtToken();
                                localStorage.setItem('accessToken', accessToken);
                                localStorage.setItem('idToken', token);
                                this.authService.setCurrentLoggedInUser();
                                this.isLoggedIn.next(true);
                                const navigationPromise = this.router.navigate(['home']);
                            },
                            onFailure: (err): void => {
                                console.log('err: ', err);
                            }
                        }
                    );
                }
            });
        });
    }

    public confirmAccount(code: string, usernameInput: string): void {
        const username = usernameInput.toLowerCase();
        const userData$ = this.getUserData(username);
        userData$.subscribe(userData => {
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            cognitoUser.confirmRegistration(code, true, (err, _) => {
                if (err) {
                    this.toastrService.error(err.message, 'Oops!');
                    return;
                }
                this.toastrService.success('Your account has been confirmed!', 'Success!');
                this.verifyMe = false;
                this.isLoggedIn.next(true);
                const navigationPromise = this.router.navigate(['/home']);
            });
        });
    }

    public checkSessionValidity(): void {
        this.userPool$.subscribe(userPool => {
            if (!userPool) {
                this.isLoggedIn.next(false);
                return;
            }
            const cognitoUser = userPool.getCurrentUser();

            if (cognitoUser === null) {
                this.isLoggedIn.next(false);
            }

            if (cognitoUser !== null) {
                cognitoUser.getSession((err, session) => {
                    if (err) {
                        this.isLoggedIn.next(false);
                        return;
                    }
                    if (session.isValid()) {
                        this.isLoggedIn.next(true);
                        const username = cognitoUser.getUsername();
                        this.user = {
                            username
                        };
                        this.authService.setCurrentLoggedInUser();
                    } else {
                        this.isLoggedIn.next(false);
                    }
                });
            }
        });
    }

    public signOut(): void {
        this.userPool$.subscribe(userPool => {
            if (!userPool) {
                return;
            }
            const cognitoUser = userPool.getCurrentUser();
            if (cognitoUser !== null) {
                cognitoUser.signOut();
            }
            localStorage.clear();
            this.isLoggedIn.next(false);
            const navigationPromise = this.router.navigate(['/login']);
            requestAnimationFrame(() => {
                this.entityCacheDispatcher.clearCollections(undefined);
            });
        });
    }

    public resendCode(usernameInput: string): void {
        const username = usernameInput.toLowerCase();
        const userData$ = this.getUserData(username);
        userData$.subscribe(userData => {
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

            cognitoUser.resendConfirmationCode((err, _) => {
                if (err) {
                    this.toastrService.error(err.message, 'Error!');
                    return;
                }
                this.toastrService.warning('Please check your emails', 'Please Verify');
            });
        });
    }

    public forgotPassword(usernameInput: string): void {
        const username = usernameInput.toLowerCase();
        const userData$ = this.getUserData(username);
        userData$.subscribe(userData => {
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            cognitoUser.forgotPassword({
                onSuccess: (): void => {
                    this.toastrService.success('Successfully changed password', 'Success!');
                    const navigationPromise = this.router.navigate(['/login']);
                },
                onFailure: (err): void => {
                    this.toastrService.error(err.message, 'Oops!');
                },
                // tslint:disable-next-line: typedef
                async inputVerificationCode() {
                    const { value: verificationCode } = await Swal.fire({
                        title: 'You have been sent a new verification code.',
                        text: 'Please check your emails, and remember to check your junk folder!',
                        input: 'text',
                        inputAttributes: {
                            maxlength: '6'
                        },
                        showCancelButton: true,
                        // tslint:disable-next-line: typedef
                        inputValidator: value => {
                            if (!value) {
                                return 'Please enter the verification code';
                            }
                        }
                    });

                    if (verificationCode) {
                        const { value: newPassword } = await Swal.fire({
                            title: 'Enter new password',
                            input: 'password',
                            inputPlaceholder: 'Enter new password',
                            inputAttributes: {
                                autocapitalize: 'off',
                                autocorrect: 'off'
                            }
                        });
                        if (newPassword) {
                            cognitoUser.confirmPassword(verificationCode, newPassword, this);
                        }
                    }
                }
            });
        });
    }

    public changePassword(oldPassword: string, newPassword: string): void {
        this.userPool$.subscribe(userPool => {
            const cognitoUser = userPool.getCurrentUser();

            cognitoUser.getSession((err: Error) => {
                if (err) {
                    return;
                }
                cognitoUser.changePassword(oldPassword, newPassword, changePasswordErr => {
                    if (changePasswordErr) {
                        this.toastrService.error('Something went wrong, please try again', 'Oops!');
                        return;
                    }
                    this.toastrService.success('Password changed', 'Success!');
                });
            });
        });
    }

    private getUserData(emailInput: string): Observable<AmazonCognitoIdentity.ICognitoUserData> {
        const email = emailInput.toLowerCase();
        return this.userPool$.pipe(
            map(userPool => {
                return {
                    Username: email,
                    Pool: userPool
                };
            })
        );
    }
}
